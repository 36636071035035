import { Button } from "@mui/material";
import supabaseClient from "../../common/supabaseClient";
import { Sentence } from "../../types/supabase";
import { useMemo, useState } from "react";
import HStack from "../Unknown/HStack";
import getSentenceWords from "../../common/getSentenceWords";
import useMountedWaitAsyncEffect from "../../hooks/useMountedWaitAsyncEffect";
import checkNewWords from "../../common/checkNewWords";
import CopyNewWordsForGemini from "../Admin/CopyNewWordsForGemini";
import LoadingButton from "@mui/lab/LoadingButton";

const FindNotExistingWords = () => {
  const [sentences, setSentences] = useState<Sentence[]>([]);
  const [loading, setLoading] = useState(false);
  const [newWords, setNewWords] = useState<string[]>([]);

  const wordsText = useMemo(() => {
    return sentences.map((sentence) => {
      let text: string[] = [sentence.en];
      if (sentence.is_question && sentence.answers) {
        sentence.answers.forEach((a) => text.push(a.en));
      }
      return text.join(", ");
      // return getSentenceWords(text.join(', '))
    });
  }, [sentences]);

  useMountedWaitAsyncEffect(async () => {
    setLoading(true);
    const { words: returnWords, newWords } = await checkNewWords(wordsText);
    console.log("🚀 ~ newWords:", newWords);

    setNewWords(newWords);
    setLoading(false);
  }, [wordsText]);

  return (
    <HStack width="100%">
      <LoadingButton
        loading={loading}
        color="error"
        variant="outlined"
        onClick={async () => {
          setLoading(true);
          const { data } = await supabaseClient
            .from("sentences")
            .select()
            .is("verified_words", null)
            .limit(50);

          if (data && data.length === 0) {
            alert("No sentences found. Congratulations!");
          }
          if (data) setSentences(data);
          setLoading(false);
        }}
      >
        load not existing words from sentences
      </LoadingButton>
      {Boolean(sentences.length) && (
        <Button
          color="error"
          onClick={async () => {
            await Promise.all(
              sentences.map((s) => {
                return supabaseClient
                  .from("sentences")
                  .update({
                    verified_words: true,
                  })
                  .eq("id", s.id);
              }),
            );
            setSentences([]);
          }}
        >
          Mark as read
        </Button>
      )}
      {/* {Boolean(sentences.length) && } */}
      {!!newWords.length && <CopyNewWordsForGemini words={newWords} />}
    </HStack>
  );
};

export default FindNotExistingWords;

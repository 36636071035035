import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { wordsToSkip } from "../../common/constants";
import removePunctuationFromString from "../../common/removePunctuationFromString";
import supabaseClient from "../../common/supabaseClient";
import wait from "../../common/wait";
import useMountedWaitAsyncEffect from "../../hooks/useMountedWaitAsyncEffect";
import MenuLayout from "../Layout/MenuLayout";
import HStack from "../Unknown/HStack";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useFirebaseApp } from "reactfire";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import VStack from "../Unknown/VStack";
import getSentenceWords from "../../common/getSentenceWords";
import initialPoems from "./poems";
import CopyStoryIdButton from "../Unknown/CopyStoryIdButton";
import ItalicTitle from "../Title/ItalicTitle";
import WordAudio from "../Audio/WordAudio";
import HoverDetails from "../Word/HoverDetails";
import { Word } from "../../types/supabase";
import { useLessonsStore } from "../../store/lessonsStore";
import CopyNewWordsForGemini from "../Admin/CopyNewWordsForGemini";
import Poem, { PoemType } from "./Poem";
import isAdmin from "../../common/isAdmin";

const courseWords = {
  1: "I, speak, English, you, we, they, he, speaks, she, know, knows, do, does, don't, doesn't, study, in, school, studies, and, work, works, every, day, it, learn, learns, very, well, go, to, today",
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AdminLearnPoems: React.FC = () => {
  const [text, setText] = useState("");
  const [author, setAuthor] = useState("");
  const [link, setLink] = useState("");
  const [storageLink, setStorageLink] = useState("");
  const [words, setWords] = useState<string[]>([]);
  // const [type, setType] = useState("song");
  const [type, setType] = useState("poem");
  // const [type, setType] = useState("story");
  const [newWords, setNewWords] = useState<string[]>([]);
  const [upperCaseVerseWords, setUpperCaseVerseWords] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newUpperCaseWords, setNewUpperCaseWords] = useState<string[]>([]);
  const [upperCaseWords, setUpperCaseWords] = useState<string[]>([]);
  const [storiesWords, setStoriesWords] = useState<string[]>([]);
  console.log("🚀 ~ upperCaseWords:", upperCaseWords);
  const [tabValue, setTabValue] = React.useState(0);
  const myPoems = useLessonsStore((state) => state.myPoems);
  console.log("🚀 ~ myPoems:", myPoems);
  const setMyPoems = useLessonsStore((state) => state.setMyPoems);
  const authUser = useLessonsStore((state) => state.authUser);

  useEffect(() => {
    let channel: any = null;
    if (isAdmin(authUser?.id) && myPoems?.length) {
      supabaseClient
        .channel("room12")
        .on(
          "postgres_changes",
          { event: "*", schema: "public", table: "user_poems" },
          async (payload: any) => {
            if (!payload.new) return;
            console.log("🚀 ~ payload:", payload);
            const { data: updatedUserPoem } = await supabaseClient
              .from("poems")
              .select()
              .eq("id", payload.new?.id)
              .maybeSingle();
            if (!updatedUserPoem) return;
            const newPoems = myPoems.map((p) => {
              if (p.id === updatedUserPoem.id) return updatedUserPoem;
              return p;
            });
            const existingPoem = myPoems.find((p) => p.id === updatedUserPoem.id);
            if (!existingPoem) newPoems.push(updatedUserPoem);
            setMyPoems(newPoems);
          },
        )
        .subscribe();
    }
    return () => {
      if (channel) channel.unsubscribe();
    };
  }, [authUser, myPoems]);

  const [poemsPage, setPoemsPage] = useState(0);
  console.log("🚀 ~ poemsPage:", poemsPage);

  const poems = useMemo(() => {
    const myPoemsIds = myPoems.map((p) => p.id);
    return initialPoems
      .filter((p) => !myPoemsIds.includes(p.id))
      .slice(poemsPage * 10, poemsPage * 10 + 10) as PoemType[];
  }, [poemsPage, myPoems]);
  console.log("🚀 ~ poems:", poems);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <MenuLayout>
      <Box sx={{ backgroundColor: "white" }} p={5} pt={40}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="All poems" {...a11yProps(0)} />
            <Tab label="My poems" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={0}>
          {/* <Button color="primary" variant="contained" onClick={async () => {}}>
            Load poems
          </Button> */}
          <Grid container>
            <Grid item xs={12}>
              {/* <HStack flexWrap="nowrap">
                <VStack width={70} overflow="hidden"></VStack>
                <TextField
                  fullWidth
                  color="primary"
                  value={author}
                  placeholder="Author"
                  multiline={true}
                  onChange={(event) => {
                    setAuthor(event.target.value);
                  }}
                  type="text"
                  variant="outlined"
                />
              </HStack> */}
              <Box py={10}>
                {poems.map((poem) => {
                  return <Poem key={poem.id} poem={poem} />;
                })}
                <Button
                  onClick={() => {
                    setPoemsPage((prev) => prev + 1);
                  }}
                >
                  Load next
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          {authUser && (
            <Button
              onClick={async () => {
                const { data } = await supabaseClient
                  .from("user_poems")
                  .select("*, poem(*)")
                  .eq("user", authUser.id);
                console.log("🚀 ~ data:", data);
                if (data) setMyPoems(data.map((p) => p.poem));
              }}
            >
              Load poems
            </Button>
          )}
          <Box my={10}>
            {myPoems?.map((poem) => {
              return <Poem key={poem.id} poem={poem} />;
            })}
          </Box>
        </CustomTabPanel>
      </Box>
    </MenuLayout>
  );
};

export default AdminLearnPoems;

import { useParams } from "react-router-dom";
import { Word } from "../../../types/supabase";
import { useEffect, useMemo, useState } from "react";
import { Box, Button, Drawer, Typography } from "@mui/material";
import ModalCloseButton from "../../Unknown/ModalCloseButton";
import LinearProgress from "../../User/LinearProgress";
import WordLetterPazleItem from "../../Word/WordLetterPazleItem";
import VStack from "../../Unknown/VStack";
import HoverDetails from "../../Word/HoverDetails";
import parsed from "../../../common/parsed";
import { format } from "date-fns";
import { useLessonsStore } from "../../../store/lessonsStore";
import ShowCurrentHomeWorkNumbersInDrawer from "../../Unknown/ShowCurrentHomeWorkNumbersInDrawer";
import { drawerContentStyle, userHomeWorkCurrentDay } from "../../../common/constants";
import useIsHomeWork from "../../../hooks/useIsHomeWork";
import Stories, { Story } from "../../Grammar/Stories";
import getSentenceWords from "../../../common/getSentenceWords";
import HStack from "../../Unknown/HStack";
import WordAudio from "../../Audio/WordAudio";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import useGlobalWordsMap from "../../hooks/useGlobalWordsMap";
import isAdmin from "../../../common/isAdmin";
import RepetitionButtons from "../../Sentence/RepetitionButtons";
import supabaseClient from "../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { usePrevious } from "react-use";
import ItalicTitle from "../../Title/ItalicTitle";

const Poems: React.FC<{}> = ({}) => {
  const { lessonId } = useParams();
  const [currentIndex, setCurrentIndex] = useState(0);
  const isTeacherLesson = useLessonsStore((state) => state.isTeacherLesson);
  const authUser = useLessonsStore((state) => state.authUser);
  const adminControlReadingSentenceIndex = useLessonsStore(
    (state) => state.adminControlReadingSentenceIndex,
  );

  const [isOpen, setIsOpen] = useState(false);

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  //   useMountedWaitAsyncEffect(async () => {
  // const user
  //   }, [])
  const [poems, setPoems] = useState<any[]>([]);
  // console.log("🚀 ~ poems:", poems);

  useMountedWaitAsyncEffect(
    async () => {
      if (!poems.length && authUser) {
        const { data: poems } = await supabaseClient
          .from("user_poems_old")
          .select("*, poem(*)")
          .eq("user", authUser.id)
          .order("created_at", { ascending: false });
        console.log("🚀 ~ poems:", poems);
        if (poems && poems.length) setPoems(poems);
      }
    },
    [poems, authUser],
    0,
  );

  useEffect(() => {
    const channel = supabaseClient
      .channel("room10")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "user_poems_old" },
        async (payload) => {
          if (isTeacherLesson) {
            window.location.reload();
          }
        },
      )
      .subscribe();
    return () => {
      channel.unsubscribe();
    };
  }, [authUser, isTeacherLesson]);

  if (!poems.length) return null;

  return (
    <>
      <Button variant="outlined" onClick={() => setIsOpen(true)}>
        Вірші / пісні / відео
      </Button>
      <Drawer
        open={isOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 999,
          // opacity: 0,
          // backgroundColor: "rgba(0, 0, 0, 1) !important",
        }}
      >
        <Box sx={drawerContentStyle}>
          {/* <LinearProgress elementsCount={sentences.length - 1} currentValue={currentIndex} /> */}
          <ModalCloseButton onClose={handleDrawerClose} />
          <VStack py={10}>
            <ItalicTitle size={30} text="Poems / songs / відео" />

            {poems?.map((poem) => {
              return (
                <Box mb={3} key={poem.poem.id} maxWidth={600} width="100%">
                  <Story withoutLink story={poem.poem} />
                </Box>
              );
            })}
          </VStack>
        </Box>
      </Drawer>
    </>
  );
};

export default Poems;

import Box from "@mui/material/Box";

const lesson13: any = {
  letters: [],
  number: 13,
  storyIds: [
    1868, 1751,
    // 1698, // cat sat
    // 1589, // daddy finger
    // 1725, // jingle bells
    // 1699, // dad had a fan
    // 1753,
  ],
  poemIds: [1751, 1868, 1826, 1828, 1589],
  newLettersCount: 0,
  newSounsCount: 0,
  title: <Box>Jan has a pet.</Box>,
  sounds: [
    "Oo(ɒ)",
    "Nn",
    "Tt",
    "Ee(e)",
    "Bb",
    "Dd",
    "Ll",
    "Ii(i)",
    "Pp",
    "Ss(s)",
    "Ss(z)",
    "Zz",
    "Aa(æ)",
    "Hh",
    "Vv",
    "Kk",
    "Aa(æ)",
    "Hh",
    "Vv",
    "Kk",
    "Mm",
    "Ff",
    "Gg",
    "Mm",
    "Ff",
    "Gg",
    "Cc(k)",
    "Rr",
    "oo(uː)",
    "ee(i:)",
    "th(ð)",
    "oo(ʊ)",
    "ea(i:)",
    "sh(ʃ)",
    "Ww",
    "Oo(əʊ)",
    "Ee(ɪ)",
    "j(dʒ)",
    "Uu(ʌ)",
    "Yy(j)",
    "Yy(i)",
    "oy(ɔɪ)",
    "ay(eɪ)",
  ],
  newSounds: [],
  words: [
    // "he",
    // "she",
    // "want",
    // "climb",
    // "can",
    // "do",
    // "can't",
    // "had",
    // // "lamb",
    // "its",
    // "fleece",
    // "was",
    // "white",
    // // "as",
    // // "snow",
    // // "and",
    // // "everywhere",
    // "that",
    // "went",
    // "sure",
    // // "go",
    // "follow",
    // "followed",
    // "her",
    // // "school",
    // "one",
    // "day",
    // "against",
    // "rule",
    // "made",
    // "child",
    // "children",
    // "laugh",
    // "play",
    // "see",
    // "in",
    // "at",
    //
    // "fast",
    // "slow",
    // "runs",
    // "very",
    // "jump",
    // "high",
    // "sun",
    // "low",
    // "plane",
    // "ring",
    // "here",
    // "there",
    // "glass",
    // "need",
    // "dishes",
    // "want",
    // "animal",
    // "run",
    // "every",
    // "sleep",
    // "store",
    // "zoo",
    // "today",
    // "hare",
    // "with",
    // "boy",
    // "plays",
    // "walk",
    // "understand",
    // "next",
    // "do",
    // "hot",
    // "climb",
    // "tree",
    // "bag",
    // "eat",
    // "sheep",
    // "geese",
    // "man",
    // "box",
    // "boxes",
    // "fox",
    // "foxes",
    // "mix",
    // "mixer",
    // "six",
    // "text",
    // "taxi",
    // "read",
    // "problem",
    // "they",
    // "animals",
    // "cup",
    // "understands",
    // "me",
    // "wash",
    // "water",
    // "wants",
    // "swan",
    // "cold",
    // "red",
    // "van",
    // "jeep",
    // "jam",
    // "trash",
    // "he",
    // "has",
    // "she",
    // "shop",
    // "we",
    // "likes",
    // "park",
    // "fish",
    // "climbs",
    // "needs",
    // "am",
    // "hamster",
    // "are",
    // "lambs",
    // "be",
    // "rat",
    // "mat",
    // "look",
    // "sat",
    // "too",
    // "fat",
    // "coffee",
    // "tea",
    // "cat",
    // "this",
    // "root",
    // "roots",
    // "seven",
    // "ran",
    // "room",
    // "pink",
    // "banana",
    // "cats",
    // "on",
    // "green",
    // "goose",
    // "plan",
    // "bedroom",
    // "bananas",
    // "pan",
    // "pans",
    // "fan",
    // "bike",
    // "five",
    // "kite",
    // "bikes",
    // "lime",
    // "life",
    // "time",
    // "map",
    // "maps",
    // "big",
    // "dog",
    // "dogs",
    // "pig",
    // "milk",
    // "is",
    // "hi",
    // "test",
    // "bed",
    // "ten",
    // "sport",
    // "leg",
    // "sister",
    // "continent",
    // "egg",
    // "car",
    // "star",
    // "end",
    // "no",
    // "note",
    // "stone",
    // "poet",
    // "arm",
    // "art",
    // "card",
    // "apple",
    // "hand",
    // "hat",
    // "yes",
    // "yard",
    // "hobby",
    // "Mary",
    // "hello",
    // "letter",
    // "dad",
    // "daddy",
    // "fact",
    // "family",
    // "frog",
    // "nose",
    // "rose",
    // "roses",
    // "mom",
    // "mommy",
    // "finger",
    // "song",
    // "I",
    // "tiger",
    // "spider",
    // "winter",
    // "spring",
    // "hop",
    // "can",
    // "England",
    // "summer",
    // "butter",
    // "up",
    // "the",
    // "bank",
    // "book",
    // "notebook",
    // "spoon",
    // "wood",
    // "brother",
    // "group",
    // "soup",
    // "you",
    // "where",
    // "baby",
    // "lady",
    // "name",
    // "cow",
    // "how",
    // "down",
    // "two",
    // `your`,
    // "daddy",
    // "mommy",
    // "where",
    // "baby",
    // "how",
    // "song",
    // "finger",
    // "fingers",
    // "pens",
    // "with",
    // "many",
    // "here",
    // "there",
    // "had",
    // "its",
    // "was",
    // "as",
    // "sure",
    // "rule",
    // "went",
    // "that",
    // "her",
    // "laugh",
    // "climb",
    "birth",
    "birthday",
    "was",
    "this",
    "follow",
    "home",
    "want",
    "do",
    "laugh",
    "yard",
    "it",
    "pet",
    "big",
    "need",
    "very",
    "fast",
    "her",
    "ten",
    "child",
    "man",
    "pan",
    "pen",
    "can",
    "has",
    "fish",
    "blue",
    "wet",
    "net",
    "get",
    "let",
    "jet",
    "vet",
    "you",
    "does",
    "went",
    "don't",
    "worry",
    "happy",
    "fly",
    "plane",
    "run",
    "runs",
    "look",
  ],
  speakingWords: [
    "I have a pet. It is a fish.",
    "Do you have a pet?",
    "She is a vet.",
    "Does she have a pet?",
    "Does he have a cat?",
    "A fish needs water.",
    "He went to the vet.",
    "I see a fish in the water.",
    "Her dog is wet.",
    "I need to get my pet.",
    "Don't worry, let it go.",
    "Don't worry, be happy.",
    "She let the fish go.",
    "I don't have a pet.",
    "I don't have a dog.",
    "She doesn't have a cat.",
    "Do you see a cat? - No, I don't see.",
    "Do you have a pet? - Yes, I have a dog.",
    "I want to fly on a jet plane.",
  ],
};

export default lesson13;

import { format, subDays } from "date-fns";
import { useEffect } from "react";
import { userHomeWorkCurrentDay } from "../common/constants";
import { RPCUser } from "../common/fetchCurrentUser";
import parsed from "../common/parsed";
import supabaseClient from "../common/supabaseClient";
import { lessonRules } from "../components/LearnToRead/SimpleLessonTasks";
import Box from "../components/Unknown/Box";
import { useLessonsStore } from "../store/lessonsStore";
import useGrammarLastLesson from "./useGrammarLastLesson";
import useLearnToReadLastLesson from "./useLearnToReadLastLesson";
import useMountedWaitAsyncEffect from "./useMountedWaitAsyncEffect";

export enum HOME_WORK_TASKS {
  Повтори_вивчи_слова_з_попереднього_уроку = "Повтори/вивчи слова з попереднього уроку",
  Склади_слова_з_попереднього_уроку = "Склади слова з попереднього уроку",
  Повтори_правила = "Повтори правила",
  Переглянь_декілька_відео = "Переглянь декілька відео",
  Також_ти_можеш_переглянути_пояснення_з_попереднього_уроку = "Також за бажанням ти можеш переглянути пояснення з попереднього уроку",
}

const updateDbAndStorageHomeWorkProgress = async (
  days: number,
  authUser: RPCUser,
  isNotDone: boolean,
) => {
  console.log("updateDbAndStorageHomeWorkProgress");
  const currentDate = format(new Date(), "yyyy-MM-dd");
  const lshw = parsed(localStorage.getItem(userHomeWorkCurrentDay), {});

  const day = format(subDays(new Date(currentDate), days), "yyyy-MM-dd");

  if (lshw[day] && isNotDone && lshw[day].homeWorkProgress && !lshw[day].isDeployed) {
    try {
      await supabaseClient
        .from("homework_progress")
        .insert({
          user: authUser.uuid,
          id: `${authUser.id}-${day}`,
          progress: lshw[day].homeWorkProgress,
        })
        .throwOnError();

      localStorage.setItem(
        userHomeWorkCurrentDay,
        JSON.stringify(
          {
            ...lshw,
            [day]:
              days > 1
                ? undefined
                : {
                    ...lshw[day],
                    isDeployed: true,
                    greetings: {
                      "build-words": true,
                      "learn-words": true,
                      videos: true,
                      rules: true,
                    },
                  },
          },
          null,
          2,
        ),
      );
    } catch (err) {
      console.error(err);
    }
  }

  if (lshw[day] !== undefined && ((lshw[day]?.isDeployed && days >= 1) || days > 5)) {
    localStorage.setItem(
      userHomeWorkCurrentDay,
      JSON.stringify(
        Object.entries({
          ...lshw,
          [day]: undefined,
        }).reduce((acm, [key, value]) => {
          if (!value) return acm;

          return {
            ...acm,
            [key]: value,
          };
        }, {}),
        null,
        2,
      ),
    );
  }
};

const useHomeWorkDetection = () => {
  const authUser = useLessonsStore((state) => state.authUser);
  const updateHomeWork = useLessonsStore((state) => state.updateHomeWork);
  const homeWork = useLessonsStore((state) => state.homeWork);
  const setPoemsCount = useLessonsStore((state) => state.setPoemsCount);
  // const { width: windowWidth } = useWindowSize();
  console.log("useHomeWorkDetection");

  useEffect(() => {
    let homeWorkProgress = 0;
    let allTasksLength = 0;
    let doneTasksLength = 0;
    if (homeWork.tasks[HOME_WORK_TASKS.Переглянь_декілька_відео]) {
      allTasksLength += homeWork.initialVideosToRepeat.length;
      doneTasksLength += homeWork.repeatedVideos.length;
    }
    if (homeWork.tasks[HOME_WORK_TASKS.Повтори_вивчи_слова_з_попереднього_уроку]) {
      allTasksLength += homeWork.initialWordsToRepeat.length;
      doneTasksLength += homeWork.repeatedWords.length;
    }
    if (homeWork.tasks[HOME_WORK_TASKS.Склади_слова_з_попереднього_уроку]) {
      allTasksLength += homeWork.initialWordsToBuild.length;
      doneTasksLength += homeWork.builtWords.length;
    }
    if (homeWork.tasks[HOME_WORK_TASKS.Повтори_правила]) {
      allTasksLength += homeWork.initialRulesToRepeat.length;
      doneTasksLength += homeWork.repeatedRules.length;
    }

    homeWorkProgress = Math.floor(doneTasksLength * (100 / allTasksLength));

    const currentDate = format(new Date(), "yyyy-MM-dd");
    const lshw = parsed(localStorage.getItem(userHomeWorkCurrentDay), {});
    if (doneTasksLength && homeWork.progress !== homeWorkProgress) {
      localStorage.setItem(
        userHomeWorkCurrentDay,
        JSON.stringify(
          {
            ...lshw,
            [currentDate]: {
              ...lshw[currentDate],
              isHomeWorkDone: homeWorkProgress >= 100,
              homeWorkProgress: homeWorkProgress,
            },
          },
          null,
          2,
        ),
      );

      updateHomeWork({
        isDone: homeWorkProgress >= 100,
        progress: homeWorkProgress,
      });
    }
  }, [homeWork, updateHomeWork]);

  useMountedWaitAsyncEffect(
    async () => {
      if (homeWork.isDone && authUser) {
        const currentDate = format(new Date(), "yyyy-MM-dd");
        const lshw = parsed(localStorage.getItem(userHomeWorkCurrentDay), {});

        await updateDbAndStorageHomeWorkProgress(
          0,
          authUser,
          lshw[currentDate].homeWorkProgress >= 100,
        );
      }
      if (authUser) {
        await updateDbAndStorageHomeWorkProgress(1, authUser, true);
        await updateDbAndStorageHomeWorkProgress(2, authUser, true);
        await updateDbAndStorageHomeWorkProgress(3, authUser, true);
        await updateDbAndStorageHomeWorkProgress(4, authUser, true);
        await updateDbAndStorageHomeWorkProgress(5, authUser, true);
        await updateDbAndStorageHomeWorkProgress(6, authUser, true);
        await updateDbAndStorageHomeWorkProgress(7, authUser, true);
      }
    },
    [homeWork, authUser],
    0,
  );

  const learnToReadLesson = useLearnToReadLastLesson();
  const { lastLessonNumber, randomIds: grammarLessonRandomVideoIds } = useGrammarLastLesson();

  useEffect(() => {
    const localStorageHomeWork = parsed(localStorage.getItem(userHomeWorkCurrentDay), {});
    const currentDate = format(new Date(), "yyyy-MM-dd");

    if (
      lastLessonNumber &&
      homeWork.isGrammarLesson &&
      !localStorageHomeWork[currentDate]?.grammarLessonNumber
    ) {
      const currentDate = format(new Date(), "yyyy-MM-dd");
      const localStorageHomeWork = parsed(localStorage.getItem(userHomeWorkCurrentDay), {});
      const newLSObject = JSON.stringify(
        {
          ...localStorageHomeWork,
          [currentDate]: {
            ...homeWork,
            tasks: {},
            videosToRepeat: grammarLessonRandomVideoIds,
            grammarLessonNumber: lastLessonNumber,
          },
        },
        null,
        2,
      );

      localStorage.setItem(userHomeWorkCurrentDay, newLSObject);
      updateHomeWork({
        ...homeWork,
        videosToRepeat: grammarLessonRandomVideoIds,
        grammarLessonNumber: lastLessonNumber,
      });
    }
  }, [homeWork, updateHomeWork, grammarLessonRandomVideoIds, lastLessonNumber]);

  useMountedWaitAsyncEffect(async () => {
    if (homeWork.poemsCount === null && authUser) {
      const { count: poemsCount } = await supabaseClient
        .from("user_poems_old")
        .select("*", { count: "exact", head: true })
        .eq("user", authUser.id);

      if (poemsCount !== null) setPoemsCount(poemsCount);
    }
  }, [authUser, homeWork]);

  // useMountedWaitAsyncEffect(async () => {
  //   const localStorageHomeWork = parsed(localStorage.getItem(userHomeWorkCurrentDay), {});
  //   const currentDate = format(new Date(), "yyyy-MM-dd");

  // }, [lastLessonNumber, homeWork, authUser])

  useEffect(() => {
    if (authUser && learnToReadLesson) {
      const localStorageHomeWork = parsed(localStorage.getItem(userHomeWorkCurrentDay), {});
      const currentDate = format(new Date(), "yyyy-MM-dd");
      const homeWork: Record<string, any> = {
        currentDate,
        tasks: {},
        // learn to read lesson number
        lessonNumber: learnToReadLesson.number,
        // grammar lesson number
        isGrammarLesson: authUser.grammar_lesson && authUser.grammar_lesson > 10,
        // grammarLessonNumber: lastLessonNumber,
        greetings: {
          "build-words": false,
          "learn-words": false,
          rules: false,
          videos: false,
        },
      };

      // if (
      //   lastLessonNumber > 10 &&
      //   (!localStorageHomeWork[currentDate] ||
      //     !localStorageHomeWork[currentDate]?.videosToRepeat?.length)
      // ) { }

      // if (grammarLessonRandomVideoIds.length) {
      //   homeWork.videosToRepeat = JSON.stringify(grammarLessonRandomVideoIds);
      // }
      homeWork.isDeployed = localStorageHomeWork[currentDate]?.isDeployed;
      homeWork.progress = localStorageHomeWork[currentDate]?.progress;
      homeWork.isDone = localStorageHomeWork[currentDate]?.isDone;

      if (authUser.read_lesson) {
        const rules = lessonRules[learnToReadLesson.number] || [];

        homeWork.wordsToRepeat = learnToReadLesson.words;
        // for progress
        homeWork.initialWordsToRepeat = learnToReadLesson.words;
        homeWork.repeatedWords = [];

        homeWork.wordsToBuild = learnToReadLesson.words;
        // for progress
        homeWork.initialWordsToBuild = learnToReadLesson.words;
        homeWork.builtWords = [];

        homeWork.rulesToRepeat = rules.map((rule) => rule.id);

        // for progress
        homeWork.initialRulesToRepeat = rules.map((rule) => rule.id);
        homeWork.repeatedRules = [];

        // if (localStorageHomeWork) {
        homeWork.repeatedWords = localStorageHomeWork[currentDate]?.repeatedWords || [];
        homeWork.builtWords = localStorageHomeWork[currentDate]?.builtWords || [];
        homeWork.repeatedRules = localStorageHomeWork[currentDate]?.repeatedRules || [];
        // homeWork.isGrammarLesson = localStorageHomeWork[currentDate]?.isGrammarLesson || false;

        homeWork.rulesToRepeat = rules
          .map((rule) => rule.id)
          .filter((r) => !homeWork.repeatedRules.includes(r));

        homeWork.greetings["build-words"] = Boolean(
          localStorageHomeWork[currentDate]?.greetings?.["build-words"],
        );
        homeWork.greetings["learn-words"] = Boolean(
          localStorageHomeWork[currentDate]?.greetings?.["learn-words"],
        );
        homeWork.greetings["rules"] = Boolean(
          localStorageHomeWork[currentDate]?.greetings?.["rules"],
        );

        homeWork.wordsToRepeat = learnToReadLesson.words.filter(
          (w) => !homeWork.repeatedWords.includes(w),
        );
        homeWork.wordsToBuild = learnToReadLesson.words.filter(
          (w) => !homeWork.builtWords.includes(w),
        );

        homeWork.tasks[HOME_WORK_TASKS.Повтори_вивчи_слова_з_попереднього_уроку] = <Box />;
        homeWork.tasks[HOME_WORK_TASKS.Склади_слова_з_попереднього_уроку] = <Box />;
        // temporary: Kashuba
        // if (authUser.uuid !== "74aeedab-4b23-4b3f-b398-63c987775dc7") {
        homeWork.tasks[HOME_WORK_TASKS.Повтори_правила] = <Box />;

        homeWork.videosToRepeat = [];
        homeWork.repeatedVideos = [];

        // }

        const prevExplanationLessons = learnToReadLesson.number.toString().split(".")[1];
        if (prevExplanationLessons) {
          homeWork.readToLearnExplanationLessons = Array(Number(prevExplanationLessons) - 1)
            .fill("")
            .map((_, index) => {
              return Number(`${Math.floor(learnToReadLesson.number)}.${index + 1}`);
            });
          homeWork.tasks[
            HOME_WORK_TASKS.Також_ти_можеш_переглянути_пояснення_з_попереднього_уроку
          ] = <Box />;
        }
      }

      if (authUser.grammar_lesson) {
        homeWork.greetings["videos"] = Boolean(
          localStorageHomeWork[currentDate]?.greetings?.["videos"],
        );
        homeWork.tasks[HOME_WORK_TASKS.Переглянь_декілька_відео] = <Box />;
        // if (localStorageHomeWork[currentDate]?.videosToRepeat || [])
        homeWork.videosToRepeat = localStorageHomeWork[currentDate]?.videosToRepeat || [];
        homeWork.initialVideosToRepeat = localStorageHomeWork[currentDate]?.videosToRepeat || [];
        homeWork.repeatedVideos = localStorageHomeWork[currentDate]?.repeatedVideos || [];
        homeWork.grammarLessonNumber = localStorageHomeWork[currentDate]?.grammarLessonNumber;
      }

      localStorage.setItem(
        userHomeWorkCurrentDay,
        JSON.stringify(
          {
            ...localStorageHomeWork,
            [currentDate]: { ...homeWork, tasks: {} },
          },
          null,
          2,
        ),
      );

      updateHomeWork(homeWork);
    }
  }, [authUser, updateHomeWork, learnToReadLesson]);
};

export default useHomeWorkDetection;

import { useParams } from "react-router-dom";
import { Word } from "../../../types/supabase";
import { useMemo, useState } from "react";
import { Box, Button, Drawer, Typography } from "@mui/material";
import ModalCloseButton from "../../Unknown/ModalCloseButton";
import LinearProgress from "../../User/LinearProgress";
import WordLetterPazleItem from "../../Word/WordLetterPazleItem";
import VStack from "../../Unknown/VStack";
import HoverDetails from "../../Word/HoverDetails";
import parsed from "../../../common/parsed";
import { format } from "date-fns";
import { useLessonsStore } from "../../../store/lessonsStore";
import ShowCurrentHomeWorkNumbersInDrawer from "../../Unknown/ShowCurrentHomeWorkNumbersInDrawer";
import { userHomeWorkCurrentDay } from "../../../common/constants";
import useIsHomeWork from "../../../hooks/useIsHomeWork";

const drawerContentStyle = {
  height: "100vh",
  width: "100vw",
  overflowX: "hidden",
};

const BuildWords: React.FC<{
  curentModuleWordsMap: Record<string, Word>;
  // areThereQuotes: boolean;
  wordsGreeting: string;
  moduleWords: string[];
  wordsLength: number;
  lessonType?: string;
  withoutWordNumber?: boolean;
}> = ({
  curentModuleWordsMap,
  lessonType,
  withoutWordNumber,
  wordsGreeting,
  moduleWords,
  wordsLength,
}) => {
  console.log("🚀 ~ curentModuleWordsMap:", curentModuleWordsMap);
  const { lessonId } = useParams();
  const lessonModalOpen = useLessonsStore((state) => state.lessonModalOpen === "build-words");
  const setLessonModalOpen = useLessonsStore((state) => state.setLessonModalOpen);
  const [wordIndex, setWordIndex] = useState(0);
  const wordsToBuild = useLessonsStore((state) => state.homeWork.wordsToBuild);

  const prevWords = useMemo(() => {
    const w = [...moduleWords];
    w.length = wordIndex;
    return w;
  }, [moduleWords, wordIndex]);

  const isHomeWork = useIsHomeWork({ lessonId: +(lessonId || 0), lessonType: lessonType || "" });
  const currentWord = useMemo(() => {
    if (wordsToBuild.length && isHomeWork) {
      const word = moduleWords.find((w) => w === wordsToBuild[0]);
      if (word) return word;
    }
    const w = moduleWords[wordIndex];
    if (!w) return null;

    return w;
  }, [moduleWords, wordIndex, wordsToBuild, isHomeWork]);

  const handleDrawerClose = () => {
    setLessonModalOpen("");
    // if (onDrawerClose) onDrawerClose();
  };

  const updateHomeWork = useLessonsStore((state) => state.updateHomeWork);
  const allWordsAreBuilt = currentWord === null;

  const buttonText = useMemo(() => {
    if (withoutWordNumber) {
      return "Скласти слова";
    }
    return `Скласти слова (${wordsLength})`;
  }, [wordsLength, withoutWordNumber]);

  return (
    <>
      {/* <LinearProgress height={4} elementsCount={wordsLength} currentValue={wordIndex} /> */}
      <Button variant="outlined" onClick={() => setLessonModalOpen("build-words")}>
        {buttonText}
      </Button>
      <Drawer
        open={lessonModalOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 9,
        }}
      >
        <Box sx={drawerContentStyle}>
          <ModalCloseButton onClose={handleDrawerClose} />
          {isHomeWork && (
            <Box pt={14}>
              <ShowCurrentHomeWorkNumbersInDrawer type="build-words" />
            </Box>
          )}
          <LinearProgress height={7} elementsCount={moduleWords.length} currentValue={wordIndex} />
          <Box sx={{ backgroundColor: "white" }} py={15} px={3} mb={2} width="100%">
            {/* {!lessonSentences.length && <Loader />} */}
            {allWordsAreBuilt && (
              <Box py={4}>
                <Typography color="primary" textAlign="center">
                  {wordsGreeting}
                </Typography>
                <Typography textAlign="center">
                  Тепер прочитай всі складені слова та переклади.
                </Typography>
              </Box>
            )}
            {currentWord !== null && curentModuleWordsMap[currentWord] && (
              <WordLetterPazleItem
                word={curentModuleWordsMap[currentWord]}
                // word={curentModuleWordsMap["ice cream"]}
                handleNext={() => {
                  if (isHomeWork) {
                    const hmWordsTracking = localStorage.getItem(userHomeWorkCurrentDay);
                    let newHmWordsTracking: Record<string, any> = {
                      ...parsed(hmWordsTracking, {}),
                    };
                    const currentDate = format(new Date(), "yyyy-MM-dd");

                    if (!newHmWordsTracking[currentDate]) {
                      newHmWordsTracking = {
                        ...newHmWordsTracking,
                        [currentDate]: {
                          date: currentDate,
                          builtWords: [],
                        },
                      };
                    }
                    if (!newHmWordsTracking[currentDate]?.builtWords) {
                      newHmWordsTracking[currentDate].builtWords = [];
                    }

                    const updatedBuiltWords = Array.from(
                      new Set([...newHmWordsTracking[currentDate].builtWords, currentWord]),
                    );
                    newHmWordsTracking[currentDate].builtWords = updatedBuiltWords;

                    localStorage.setItem(
                      userHomeWorkCurrentDay,
                      JSON.stringify(newHmWordsTracking),
                    );
                    updateHomeWork({
                      builtWords: updatedBuiltWords,
                      wordsToBuild: wordsToBuild.filter((w) => !updatedBuiltWords.includes(w)),
                    });
                  }
                  setWordIndex((prev) => prev + 1);
                  // }
                }}
              />
            )}
          </Box>

          {!!prevWords.length && (
            <VStack sx={{ backgroundColor: "white" }}>
              <Box pt={5} pb={allWordsAreBuilt ? 0 : 5} px={3}>
                {prevWords.map((w, index) => {
                  return (
                    <HoverDetails
                      key={index}
                      // isCenter
                      words={[
                        {
                          ...curentModuleWordsMap[w],
                          id: w,
                          page: 1,
                          word: w,
                          label: w,
                        },
                      ]}
                    />
                  );
                })}
              </Box>
              {allWordsAreBuilt && (
                <VStack pb={5}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setWordIndex(0);
                    }}
                  >
                    Почати з початку
                  </Button>
                </VStack>
              )}
            </VStack>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default BuildWords;

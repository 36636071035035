import { Box, Button, Modal } from "@mui/material";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import ModalCloseButton from "./ModalCloseButton";
import { fullScreenModalStyle } from "../../common/constants";
import useMountedWaitAsyncEffect from "../../hooks/useMountedWaitAsyncEffect";
import { EnglishStory } from "../../types/supabase";
import ImageCarouselModal from "../ImageCarouselModal";

const PdfModal: React.FC<{ story: EnglishStory }> = ({ story }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [scale, setScale] = useState(1);
  const [page, setPage] = useState(1);
  const windowRef = useRef();
  const [pdfDocument, setPdfDocument] = useState<any>(null);
  const [extractedText, setExtractedText] = useState("");

  const handleClose = () => setIsOpen(false);

  const images = useMemo(() => {
    const name = story.pdf_name?.replace("image-", "");

    if (!name) return [];

    console.log("PdfModal story.pdf_name:", name);
    return Array(11)
      .fill("")
      .map((_, index) => {
        return `/image-pdf-books/${name}/image-book-${index}.jpg`;
      });
  }, [story]);

  if (!Boolean(images.length)) return null;

  return (
    <>
      {/* <Button
        variant="outlined"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Читати
      </Button> */}
      <ImageCarouselModal images={images} />
      {/* <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={fullScreenModalStyle}>
          <ModalCloseButton onClose={handleClose} />
        </Box>
      </Modal> */}
    </>
  );
};

export default PdfModal;

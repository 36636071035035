// @ts-ignore
import { useEffect } from "react";
import wait from "../common/wait";

const useMountedWaitAsyncEffect = (
  fn: (mounted?: boolean) => void,
  deps: unknown[],
  time?: number,
) => {
  useEffect(() => {
    let mounted = true;

    (async () => {
      await wait(time || 500);

      if (!mounted) return;

      await fn(mounted);
    })();

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, deps);
};

export default useMountedWaitAsyncEffect;

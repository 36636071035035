import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { EnglishStory } from "../../../types/supabase";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { useEffect, useState } from "react";
import YouTubePlayer from "../YouTubePlayer";
import { useLessonsStore } from "../../../store/lessonsStore";
import ModalCloseButton from "../ModalCloseButton";
import isAdmin from "../../../common/isAdmin";
import AdminTimeCorrection from "../YouTubePlayer/AdminTimeCorrection";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  minWidth: 300,
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

export const TextPartVideoByStartAndEndButton: React.FC<{
  start: number;
  end: number;
  story: EnglishStory;
  withButton?: boolean;
  endless?: boolean;
  showVideoWithoutButton?: boolean;
}> = ({ start, end, story, withButton, endless, showVideoWithoutButton }) => {
  const setTextPartVideoByStartAndEndProps = useLessonsStore(
    (state) => state.setTextPartVideoByStartAndEndProps,
  );
  const authUser = useLessonsStore((state) => state.authUser);
  const handleOpen = () => {
    let id = "";
    let isYouTube = false;

    if (story.link && !story.link.includes("shorts") && story.youtube_id) {
      id = story.youtube_id;
      isYouTube = true;
    }
    setTextPartVideoByStartAndEndProps({ end, start, id, isYouTube, endless });
    setTimeout(() => {
      setTextPartVideoByStartAndEndProps({ isOpen: true });
    }, 150);
  };

  if (showVideoWithoutButton) {
    return story.youtube_id ? (
      <>
        <YouTubePlayer
          // withoutDelay
          id={story.youtube_id}
          start={story.clip_start || 0}
          // modalId="part_video_line_by_line"
          inputPlaying={false}
          duration={story.duration || 0}
          fullAdminWidth
        />
        {isAdmin(authUser?.id) && <AdminTimeCorrection story={story} />}
      </>
    ) : null;
  }

  return (
    <Box position="relative">
      {withButton && (
        <Button onClick={handleOpen} variant="outlined">
          подивитися відео
        </Button>
      )}
      {!withButton && (
        <IconButton onClick={handleOpen} sx={{ position: "absolute", top: -4, left: -35 }}>
          <PlayCircleOutlineIcon />
        </IconButton>
      )}
    </Box>
  );
};

const TextPartVideoByStartAndEnd = () => {
  const setTextPartVideoByStartAndEndProps = useLessonsStore(
    (state) => state.setTextPartVideoByStartAndEndProps,
  );
  const textPartVideoByStartAndEndProps = useLessonsStore(
    (state) => state.textPartVideoByStartAndEndProps,
  );
  const handleClose = () => {
    setTextPartVideoByStartAndEndProps({ isOpen: false });
  };

  return (
    <Modal
      open={textPartVideoByStartAndEndProps.isOpen}
      keepMounted
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <ModalCloseButton onClose={handleClose} />
        {textPartVideoByStartAndEndProps.isYouTube && (
          <YouTubePlayer
            // withoutDelay
            id={textPartVideoByStartAndEndProps.id}
            start={textPartVideoByStartAndEndProps.start}
            // modalId="part_video_line_by_line"
            inputPlaying={textPartVideoByStartAndEndProps.isOpen}
            duration={textPartVideoByStartAndEndProps.end - textPartVideoByStartAndEndProps.start}
          />
        )}
      </Box>
    </Modal>
  );
};

export default TextPartVideoByStartAndEnd;

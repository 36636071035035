import { format } from "date-fns";
import { useState } from "react";
import { userHomeWorkCurrentDay } from "../common/constants";
import parsed from "../common/parsed";
import supabaseClient from "../common/supabaseClient";
import { useLessonsStore } from "../store/lessonsStore";
import useMountedWaitAsyncEffect from "./useMountedWaitAsyncEffect";

// const videosIdsToRepeat = {
//   1: [],
// }

const useGrammarLastLesson = (): { lastLessonNumber: number | null; randomIds: number[] } => {
  const authUser = useLessonsStore((state) => state.authUser);
  const [lessonNumber, setLessonNumber] = useState<number | null>(null);
  const [randomIds, setRandomIds] = useState<number[]>([]);
  const homeWork = useLessonsStore((state) => state.homeWork);

  useMountedWaitAsyncEffect(async () => {
    if (!authUser?.grammar_lesson) return;

    if (!homeWork.isGrammarLesson || homeWork.grammarLessonNumber) return;

    const currentDate = format(new Date(), "yyyy-MM-dd");
    const currentDateHomeWork = parsed(localStorage.getItem(userHomeWorkCurrentDay), {});

    // const existingLSGrammarLessonNumber = currentDateHomeWork[currentDate]?.grammarLessonNumber;

    // if (existingLSGrammarLessonNumber) {
    //   setLessonNumber(existingLSGrammarLessonNumber);
    //   // setRandomIds(currentDateHomeWork[currentDate]?.videosToRepeat || []);
    //   return;
    // }

    const { data: lastLesson } = await supabaseClient
      .from("grammar_course")
      .select()
      .order("module", {
        ascending: false,
      })
      .limit(1)
      .single();

    if (!lastLesson) return;

    const userLastLessonNumber = Math.min(authUser?.grammar_lesson || 1, lastLesson.module);

    if (!userLastLessonNumber) return;

    const existingVideosIds = currentDateHomeWork[currentDate]?.videosToRepeat;

    if (userLastLessonNumber && existingVideosIds?.length) {
      setRandomIds(existingVideosIds);
      setLessonNumber(userLastLessonNumber);
      return;
    }

    if (userLastLessonNumber > 10) {
      const { data } = await supabaseClient
        .from("grammar_course")
        .select("stories, module")
        .order("module", {
          ascending: false,
        })
        .lt("module", userLastLessonNumber)
        .is("is_video", true)
        .limit(100);

      const storiesIds = data?.map((s) => s.stories[0]) || [];
      const array = Array(userLastLessonNumber < 25 ? 2 : 3).fill("");
      const randomIds: number[] = Array.from(
        new Set(
          array
            .map((i) => storiesIds[Math.floor(Math.random() * storiesIds.length)])
            .filter(Boolean),
        ),
      );

      const [lastUserLesson] = [...(data || [])];
      if (lastUserLesson && !randomIds.includes(lastUserLesson.stories[0])) {
        randomIds.push(...lastUserLesson.stories);
      }

      setRandomIds(randomIds);
    }
    setLessonNumber(userLastLessonNumber);
  }, [authUser, homeWork]);

  return { lastLessonNumber: lessonNumber, randomIds };
};

export default useGrammarLastLesson;

import { format } from "date-fns";
import { useMemo } from "react";
import { userHomeWorkCurrentDay } from "../common/constants";
import parsed from "../common/parsed";
import { ILearnToReadLesson, initialLessons } from "../components/LearnToRead/LearnToReadScreen";
import { useLessonsStore } from "../store/lessonsStore";

const useLearnToReadLastLesson = (): ILearnToReadLesson | null => {
  const authUser = useLessonsStore((state) => state.authUser);
  // const homeWork = useLessonsStore((state) => state.homeWork);

  const lastLesson = useMemo(() => {
    if (!authUser) return null;

    const currentDate = format(new Date(), "yyyy-MM-dd");
    const currentDateHomeWork = parsed(localStorage.getItem(userHomeWorkCurrentDay), {});

    const existingLSLessonNumber = currentDateHomeWork[currentDate]?.lessonNumber;

    if (
      existingLSLessonNumber &&
      authUser?.read_lesson &&
      Math.floor(authUser.read_lesson) === Math.floor(existingLSLessonNumber)
    ) {
      const lesson = initialLessons[existingLSLessonNumber];
      if (lesson) return lesson;
    } else if (currentDateHomeWork[currentDate] && !currentDateHomeWork[currentDate].isDone) {
      localStorage.setItem(
        userHomeWorkCurrentDay,
        JSON.stringify({
          ...currentDateHomeWork,
          [currentDate]: {},
        }),
      );
    }

    const lessons = Object.values(initialLessons)
      .filter((l) => Math.floor(l.number) <= authUser?.read_lesson!)
      .sort((a, b) => b.number - a.number);
    // .reverse();

    const [lesson] = lessons;

    return lesson;
  }, [authUser]);

  return lastLesson;
};

export default useLearnToReadLastLesson;

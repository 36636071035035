import { useEffect, useMemo } from "react";
import { useLessonsStore } from "../../../store/lessonsStore";
import { typesOfHomeWork } from "../../../types";
import parsed from "../../../common/parsed";
import { userHomeWorkCurrentDay } from "../../../common/constants";
import { format } from "date-fns";

const useLastPrev = ({ type }: { type: typesOfHomeWork }) => {
  const repeatedWords = useLessonsStore((state) => state.homeWork.repeatedWords);
  const builtWords = useLessonsStore((state) => state.homeWork.builtWords);
  const wordsToBuild = useLessonsStore((state) => state.homeWork.wordsToBuild);
  const wordsToRepeat = useLessonsStore((state) => state.homeWork.wordsToRepeat);
  const rulesToRepeat = useLessonsStore((state) => state.homeWork.rulesToRepeat);
  const repeatedRules = useLessonsStore((state) => state.homeWork.repeatedRules);
  const videoToRepeat = useLessonsStore((state) => state.homeWork.videosToRepeat);
  const repeatedVideos = useLessonsStore((state) => state.homeWork.repeatedVideos);
  const greetings = useLessonsStore((state) => state.homeWork.greetings);
  const updateHomeWork = useLessonsStore((state) => state.updateHomeWork);

  useEffect(() => {
    if (greetings[type]) {
      const currentDate = format(new Date(), "yyyy-MM-dd");
      const lshw = parsed(localStorage.getItem(userHomeWorkCurrentDay), {});

      const updateGreetings = {
        ...(lshw[currentDate]?.greetings || {}),
      };

      if (!updateGreetings[type]) {
        updateGreetings[type] = true;

        localStorage.setItem(
          userHomeWorkCurrentDay,
          JSON.stringify(
            {
              ...lshw,
              [currentDate]: {
                ...lshw[currentDate],
                greetings: updateGreetings,
              },
            },
            null,
            2,
          ),
        );
      }
    }
  }, [greetings]);

  const { last, prev } = useMemo(() => {
    if (type === "build-words") {
      const last = wordsToBuild.filter((w) => !builtWords.includes(w)).length;
      if (!last && !greetings[type]) {
        updateHomeWork({ ...greetings, [type]: true });
      }

      return {
        last,
        prev: builtWords.length,
      };
    } else if (type === "learn-words") {
      const last = wordsToRepeat.filter((w) => !repeatedWords.includes(w)).length;
      if (!last && !greetings[type]) {
        updateHomeWork({ ...greetings, [type]: true });
      }
      return {
        last,
        prev: repeatedWords.length,
      };
    } else if (type === "rules") {
      const last = rulesToRepeat.filter((r) => !repeatedRules.includes(r)).length;
      if (!last && !greetings[type]) {
        updateHomeWork({ ...greetings, [type]: true });
      }
      return {
        last,
        prev: repeatedRules.length,
      };
    } else if (type === "videos") {
      let l = 0;

      if (videoToRepeat.length) {
        l = videoToRepeat.length - repeatedVideos.length;
        if (!l && !greetings[type]) {
          updateHomeWork({ ...greetings, [type]: true });
        }
      }
      return {
        last: l,
        prev: repeatedVideos.length,
      };
    }
    return {
      prev: 0,
      last: 0,
    };
  }, [
    type,
    greetings,
    repeatedWords,
    repeatedRules,
    rulesToRepeat,
    builtWords,
    wordsToBuild,
    wordsToRepeat,
    videoToRepeat,
    repeatedVideos,
  ]);

  return { last, prev };
};

export default useLastPrev;

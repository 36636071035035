const rules13 = [
  {
    id: "175",
    questionUa:
      "Чому літера 'a' читається як 'ей' у словах 'baby', 'lady' а не як 'е' як у словах 'apple, cat, can...'?",
    correctAnswer:
      "Тому що у кінці слова літера 'y' яка змінює попередній голосний звук з короткого на довгий",
  },
  {
    id: "176",
    questionUa: "Чому літера 'h' не читається у слові 'where'?",
    correctAnswer:
      "Літера 'h' не читається у словах після літери 'w'. Подібні слова: 'what, white, when' ",
  },
  {
    id: "177",
    questionUa:
      "Чому літера 'a' читається як 'o' у словах 'want', 'wash', 'water' а не як 'е' як у словах 'apple, cat, can...'?",
    correctAnswer: "Літера 'a' зазвичай читається як 'o' якщо вона знаходиться після літери 'w'",
  },
  {
    id: "178",
    questionUa:
      "Чому літера 'c' читається як українська 'c' у слові 'fleece' а не як 'к' як у словах 'cat, climb, coffee, can...'?",
    correctAnswer:
      "Тому що ця літера стоїть пере літерою 'e', в такому випадку вона вимовляється як українська 'c'",
  },
  {
    id: "179",
    questionUa: "Яка минула форма слова 'go'?",
    correctAnswer: "went",
  },
  {
    id: "180",
    questionUa: "Яка минула форма слова 'follow'?",
    correctAnswer: "followed",
  },
  {
    id: "181",
    questionUa: "Слово 'follow' це правильне чи неправильне дієслово?",
    correctAnswer: "правильне",
  },
  {
    id: "182",
    questionUa: "Слово 'go' це правильне чи неправильне дієслово?",
    correctAnswer: "неправильне",
  },
  {
    id: "183",
    questionUa: "Як буде 'це' та 'те'?",
    correctAnswer: " 'this' та 'that' ",
  },
  {
    id: "184",
    questionUa:
      "Чому у словах 'sure, rule' літера 'u' читається як українська 'у' а не 'a' як у словах 'run, understand, summer...'?",
    correctAnswer:
      "тому що літера 'e' у кінці слова змінює попередній голосний звук з короткого на довгий",
  },
  {
    id: "185",
    questionUa: "У чому різниця між 'at school' та 'in school'?",
    correctAnswer:
      " 'at school' - на території школи або всередині будівлі, 'in school' - всередині будівлі ",
  },
];

export default rules13;

import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Box from "../../Box";
import filterRules from "./utils/filterRules";
import AccentWord from "../../../Difference/components/AccentWord";
import { RuleType } from "../../Root/grammarMap";
import { useLessonsStore } from "../../../../store/lessonsStore";
import AccordionRule from "./AccordionRule";
import HStack from "../../HStack";
import H3Title from "../../H3Title";

type CreateDataProp = {
  first: any;
  second: any;
  lesson: number;
  ua: string;
};

function createData(item1: CreateDataProp, item2?: CreateDataProp, item3?: CreateDataProp) {
  return {
    item1,
    item2,
    item3,
  };
}

const rows = (lessonNumber: number) => [
  createData(
    {
      first: "long",
      second: "short",
      lesson: 19,
      ua: "довгий / короткий",
    },
    {
      first: "usual",
      second: "unusual",
      lesson: 16,
      ua: "звичайний / незвичайний",
    },
  ),
  createData(
    {
      first: "tall",
      second: "short",
      lesson: 11,
      ua: "високий / низький",
    },
    {
      first: "yes",
      second: "no",
      lesson: 11,
      ua: "так / ні",
    },
    {
      first: "do",
      second: "doesn't",
      lesson: 11,
      ua: "робити / не робити",
    },
  ),
];

const Antonyms = () => {
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);

  return (
    <Box>
      <H3Title>Антоніми</H3Title>
      <Typography gutterBottom>слова, які мають протилежні значення.</Typography>
      <TableContainer
        component={Paper}
        sx={{
          width: 600,
        }}
      >
        <Table sx={{ minWidth: 600, width: 600 }} aria-label="simple table">
          <TableBody>
            {rows(lessonNumber)
              .filter((r) => r.item1.lesson <= lessonNumber)
              .map((row, index) => (
                <TableRow key={index}>
                  <CustomTableCell
                    width={200}
                    first={row.item1.first}
                    second={row.item1.second}
                    lesson={row.item1?.lesson || 0}
                    ua={row.item1?.ua}
                  />
                  {row.item2 && (
                    <CustomTableCell
                      width={200}
                      first={row.item2?.first}
                      second={row.item2?.second}
                      lesson={row.item2?.lesson || 0}
                      ua={row.item2?.ua}
                    ></CustomTableCell>
                  )}
                  {row.item3 && (
                    <CustomTableCell
                      width={200}
                      first={row.item3?.first}
                      second={row.item3?.second}
                      lesson={row.item3?.lesson || 0}
                      ua={row.item3?.ua}
                    ></CustomTableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const CustomTableCell: React.FC<{
  ua?: string;
  first: string;
  second: string;
  width: number;
  lesson: number;
}> = ({ first, second, lesson, width, ua }) => {
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);
  const isReady = lesson <= lessonNumber;

  return (
    <TableCell
      component="th"
      scope="row"
      sx={{
        m: 0,
        width,
        pointerEvents: isReady ? "auto" : "none",
        backgroundColor:
          lesson === lessonNumber ? "#ddffc5" : lesson <= lessonNumber ? "#fcffdb" : "#d0d0d0",
        height: "auto",
        cursor: "pointer",
        minHeight: 0,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderLeft: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
        py: 1,
      }}
    >
      <Typography variant="body1" fontWeight={500}>
        {first} / {second}
      </Typography>
      <Typography variant="caption">{ua}</Typography>
    </TableCell>
  );
};

export default Antonyms;

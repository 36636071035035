import isAdmin from "../../../common/isAdmin";
import HeadsetIcon from "@mui/icons-material/Headset";
import { useLessonsStore } from "../../../store/lessonsStore";
import { ExtendedUserSentence, Sentence } from "../../../types/supabase";
import EditIcon from "@mui/icons-material/Edit";
import Box from "../../Unknown/Box";
import IconButton from "../../Unknown/IconButton";
import { useEffect, useState } from "react";
import { Button, Modal, TextField, Typography } from "@mui/material";

import supabaseClient from "../../../common/supabaseClient";
import VStack from "../../Unknown/VStack";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AdminAddToListeningList: React.FC<{ sentence: ExtendedUserSentence }> = ({ sentence }) => {
  const authUser = useLessonsStore((state) => state.authUser);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [ua, setUa] = useState(sentence.sentences.ua);
  // const [en, setEn] = useState(sentence.en);
  const [sentenceExists, setSentenceExists] = useState(false);
  console.log("🚀 ~ sentenceExists:", sentenceExists);

  const checkSentenceIsAdded = async () => {
    if (!authUser) return;

    const { count } = await supabaseClient
      .from("listening_sentences")
      .select("", { count: "exact", head: true })
      .eq("sentence", sentence.sentences.id)
      .eq("user", authUser.id);
    console.log("🚀 ~ count:", count);

    setSentenceExists(!!count);
  };

  useEffect(() => {
    setUa(sentence.sentences.ua);
  }, [sentence]);

  useMountedWaitAsyncEffect(async () => {
    await checkSentenceIsAdded();
  }, [sentence]);

  if (!authUser || !isAdmin(authUser?.id)) return null;

  return (
    <Box>
      <IconButton
        sx={{ color: sentenceExists ? "green" : "black" }}
        onClick={async () => {
          const addListeningSentenceResult = await supabaseClient
            .from("listening_sentences")
            .insert({
              id: `${authUser.id}-${sentence.sentences.id}`,
              sentence: sentence.sentences.id,
              user: authUser.id,
              last_repeated: new Date().toISOString(),
            });
          console.log("🚀 ~ addListeningSentenceResult:", addListeningSentenceResult);
          await checkSentenceIsAdded();
          // setOpen(true);
        }}
      >
        <HeadsetIcon />
      </IconButton>
    </Box>
  );
};

export default AdminAddToListeningList;

import { Link } from "@mui/material";
import Button from "../Button";
import { useLessonsStore } from "../../../store/lessonsStore";
import isAdmin from "../../../common/isAdmin";

const CopySupabaseStoryLink: React.FC<{ id: string; admin?: boolean }> = ({ id, admin }) => {
  const authUser = useLessonsStore((state) => state.authUser);

  if (admin && !isAdmin(authUser?.id)) return null;

  return (
    <Button
      onClick={() => {
        navigator.clipboard.writeText(
          `https://supabase.com/dashboard/project/rjrjsfbijrnincmyibxa/editor/40443?schema=public&filter=id%3Aeq%3A${id}`,
        );
      }}
    >
      copy supabase story link
    </Button>
  );
};

export default CopySupabaseStoryLink;

import {
  Badge,
  Box,
  Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useLessonsStore } from "../../../../../store/lessonsStore";
import AW from "../../../../Difference/components/AW";
import WordTranscription from "../../../../Word/WordTranscription";
import useGlobalWords from "../../../../hooks/getGlobalWords";
import ModalCloseButton from "../../../ModalCloseButton";
import VStack from "../../../VStack";
import WithDotBadge from "../../../../../unknown/WithDotBadge";
import PresentContinuousTenseTitle from "../PresentContinuousTenseTitle";
import PresentSimpleTenseTitle from "../PresentSimpleTenseTitle";
import BackgroundBoxWraper from "../BackgroundBoxWraper";

type CreateDataProp = {
  lessonVisibility?: number;
  en?: any;
  examples: any[];
  topOffeset?: number;
};

function createData(
  item1: CreateDataProp,
  item2?: CreateDataProp,
  item3?: CreateDataProp,
  item4?: CreateDataProp,
) {
  return {
    item1,
    item2,
    item3,
    item4,
  };
}

const rows = [
  createData(
    {
      lessonVisibility: 1,
      en: <PresentSimpleTenseTitle />,
      topOffeset: 25,
      examples: [
        {
          lesson: 1,
          title: (
            <BackgroundBoxWraper lesson={1}>
              <Typography>
                <AW word="Do" /> I work?
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 1,
          title: (
            <BackgroundBoxWraper lesson={1}>
              <Typography>
                <AW word="Do" /> you work?
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 1,
          title: (
            <BackgroundBoxWraper lesson={1}>
              <Typography>
                <AW word="Do" /> we work?
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 1,
          title: (
            <BackgroundBoxWraper lesson={1}>
              <Typography>
                <AW word="Do" /> they work?
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 1,
          title: (
            <BackgroundBoxWraper lesson={1}>
              <Typography>
                <AW word="Does" /> he work?
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 1,
          title: (
            <BackgroundBoxWraper lesson={1}>
              <Typography>
                <AW word="Does" /> she work?
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 1,
          title: (
            <BackgroundBoxWraper lesson={1}>
              <Typography>
                <AW word="Does" /> it work?
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 4,
          title: (
            <Box position="relative">
              <BackgroundBoxWraper lesson={4} mt={4} isWide>
                <Typography variant="h3">Опис. To Be.</Typography>
                <Box>
                  <strong>I</strong> → am{" "}
                  <Typography variant="caption">( для 1-ї особи однини: я)</Typography>
                </Box>
                <Box>
                  <strong>He, She, It</strong> → is{" "}
                  <Typography variant="caption">( для 3-ї особи однини)</Typography>
                </Box>
                <Box>
                  <strong>We, You, They</strong> → are{" "}
                  <Typography variant="caption">( для 1-ї, 2-ї та 3-ї особи множини)</Typography>
                </Box>
              </BackgroundBoxWraper>
            </Box>
          ),
        },
        {
          lesson: 10,
          title: (
            <BackgroundBoxWraper lesson={10} mt={29}>
              <Typography>
                Where <AW word="am" /> I?
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 10,
          title: (
            <BackgroundBoxWraper lesson={10}>
              <Typography>
                <AW word="Are" /> you happy?
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 10,
          title: (
            <BackgroundBoxWraper lesson={10}>
              <Typography>
                <AW word="Are" /> we happy?
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 10,
          title: (
            <BackgroundBoxWraper lesson={10}>
              <Typography>
                <AW word="Are" /> they happy?
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 10,
          title: (
            <BackgroundBoxWraper lesson={10}>
              <Typography>
                <AW word="Is" /> he happy?
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 10,
          title: (
            <BackgroundBoxWraper lesson={10}>
              <Typography>
                <AW word="Is" /> she happy?
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 10,
          title: (
            <BackgroundBoxWraper lesson={10} mb={3}>
              <Typography>
                <AW word="Is" /> it good?
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
      ],
    },
    {
      topOffeset: 25,
      examples: [
        {
          lesson: 1,
          title: (
            <BackgroundBoxWraper lesson={1}>
              <Typography>
                <AW word="I" /> work.
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 1,
          title: (
            <BackgroundBoxWraper lesson={1}>
              <Typography>
                <AW word="You" /> work.
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 1,
          title: (
            <BackgroundBoxWraper lesson={1}>
              <Typography>
                <AW word="We" /> work.
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 1,
          title: (
            <BackgroundBoxWraper lesson={1}>
              <Typography>
                <AW word="They" /> work.
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 1,
          title: (
            <BackgroundBoxWraper lesson={1}>
              <Typography>
                <AW word="He" /> work
                <AW word="s" />.
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 1,
          title: (
            <BackgroundBoxWraper lesson={1}>
              <Typography>
                <AW word="She" /> work
                <AW word="s" />.
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 1,
          title: (
            <BackgroundBoxWraper lesson={1}>
              <Typography>
                <AW word="It" /> work
                <AW word="s" />.
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 4,
          title: (
            <BackgroundBoxWraper lesson={4} mt={29}>
              <Typography>
                I <AW word="am" /> happy.
                <BackgroundBoxWraper lesson={8} isSpan isBold>
                  I'm
                </BackgroundBoxWraper>
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 4,
          title: (
            <BackgroundBoxWraper lesson={4}>
              <Typography>
                You <AW word="are" /> happy.
                <BackgroundBoxWraper lesson={8} isSpan isBold>
                  You're
                </BackgroundBoxWraper>
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 4,
          title: (
            <BackgroundBoxWraper lesson={4}>
              <Typography>
                We <AW word="are" /> happy.
                <BackgroundBoxWraper lesson={8} isSpan isBold>
                  We're
                </BackgroundBoxWraper>
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 4,
          title: (
            <BackgroundBoxWraper lesson={4}>
              <Typography>
                They <AW word="are" /> happy.
                <BackgroundBoxWraper lesson={8} isSpan isBold>
                  They're
                </BackgroundBoxWraper>
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 4,
          title: (
            <BackgroundBoxWraper lesson={4}>
              <Typography>
                He <AW word="is" /> happy.
                <BackgroundBoxWraper lesson={8} isSpan isBold>
                  He's
                </BackgroundBoxWraper>
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 4,
          title: (
            <BackgroundBoxWraper lesson={4}>
              <Typography>
                She <AW word="is" /> happy.
                <BackgroundBoxWraper lesson={8} isSpan isBold>
                  She's
                </BackgroundBoxWraper>
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 4,
          title: (
            <BackgroundBoxWraper lesson={4} mb={3}>
              <Typography>
                It <AW word="is" /> good.
                <BackgroundBoxWraper lesson={8} isSpan isBold>
                  It's
                </BackgroundBoxWraper>
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
      ],
    },
    {
      topOffeset: 25,
      examples: [
        {
          lesson: 1,
          title: (
            <BackgroundBoxWraper lesson={1}>
              <Typography>
                I <AW word="don't" /> work.
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 1,
          title: (
            <BackgroundBoxWraper lesson={1}>
              <Typography>
                You <AW word="don't" /> work.
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 1,
          title: (
            <BackgroundBoxWraper lesson={1}>
              <Typography>
                We <AW word="don't" /> work.
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 1,
          title: (
            <BackgroundBoxWraper lesson={1}>
              <Typography>
                They <AW word="don't" /> work.
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 1,
          title: (
            <BackgroundBoxWraper lesson={1}>
              <Typography>
                He <AW word="doesn't" /> work.
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 1,
          title: (
            <BackgroundBoxWraper lesson={1}>
              <Typography>
                She <AW word="doesn't" /> work.
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 1,
          title: (
            <BackgroundBoxWraper lesson={1}>
              <Typography>
                It <AW word="doesn't" /> work.
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
      ],
    },
  ),
  createData(
    {
      lessonVisibility: 8,
      en: <PresentContinuousTenseTitle />,
      topOffeset: 29,
      examples: [
        {
          lesson: 90000,
          title: <></>,
        },
      ],
    },
    {
      lessonVisibility: 8,
      topOffeset: 29,
      examples: [
        {
          lesson: 8,
          title: (
            <BackgroundBoxWraper lesson={8}>
              <Typography pt={2}>
                I <AW word="am" /> work
                <AW word="ing" />.
                <BackgroundBoxWraper lesson={8} isSpan isBold>
                  I'm
                </BackgroundBoxWraper>
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 8,
          title: (
            <BackgroundBoxWraper lesson={8}>
              <Typography>
                You <AW word="are" /> work
                <AW word="ing" />.
                <BackgroundBoxWraper lesson={8} isSpan isBold>
                  You're
                </BackgroundBoxWraper>
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 8,
          title: (
            <BackgroundBoxWraper lesson={8}>
              <Typography>
                We <AW word="are" /> work
                <AW word="ing" />.
                <BackgroundBoxWraper lesson={8} isSpan isBold>
                  We're
                </BackgroundBoxWraper>
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 8,
          title: (
            <BackgroundBoxWraper lesson={8}>
              <Typography>
                They <AW word="are" /> work
                <AW word="ing" />.
                <BackgroundBoxWraper lesson={8} isSpan isBold>
                  They're
                </BackgroundBoxWraper>
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 8,
          title: (
            <BackgroundBoxWraper lesson={8}>
              <Typography>
                He <AW word="is" /> work
                <AW word="ing" />.
                <BackgroundBoxWraper lesson={8} isSpan isBold>
                  He's
                </BackgroundBoxWraper>
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 8,
          title: (
            <BackgroundBoxWraper lesson={8}>
              <Typography>
                She <AW word="is" /> work
                <AW word="ing" />.
                <BackgroundBoxWraper lesson={8} isSpan isBold>
                  She's
                </BackgroundBoxWraper>
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
        {
          lesson: 8,
          title: (
            <BackgroundBoxWraper lesson={8}>
              <Typography pb={2}>
                It <AW word="is" /> work
                <AW word="ing" />.
                <BackgroundBoxWraper lesson={8} isSpan isBold>
                  It's
                </BackgroundBoxWraper>
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
      ],
    },
    {
      lessonVisibility: 8,
      topOffeset: 29,
      examples: [
        {
          lesson: 800,
          title: (
            <BackgroundBoxWraper lesson={8}>
              <Typography>
                I <AW word="am" /> work
                <AW word="ing" />.
                <BackgroundBoxWraper lesson={8} isSpan isBold>
                  I'm
                </BackgroundBoxWraper>
              </Typography>
            </BackgroundBoxWraper>
          ),
        },
      ],
    },
  ),
  createData({
    lessonVisibility: 1000,
    en: (
      <Box>
        <Typography variant="h3">Future Simple</Typography>
        <Typography gutterBottom>Прогнози або рішення щодо майбутнього</Typography>
        <Typography variant="h4">слова маркери:</Typography>
        <Typography>завтра, наступного тижня/місяця/року, у майбутньому, незабаром</Typography>
        {/* <Typography>tomorrow, next week/month/year, in the future, soon</Typography> */}
      </Box>
    ),

    examples: [
      {
        lesson: 1000,
        title: <></>,
      },
    ],
  }),
  createData({
    lessonVisibility: 1000,
    en: (
      <Box>
        <Typography variant="h3">Past Simple</Typography>
        <Typography gutterBottom>Завершені дії в минулому</Typography>
        <Typography variant="h4">слова маркери:</Typography>
        <Typography>вчора, минулого тижня/місяця/ріку, тому, у 2010 році, коли</Typography>
        {/* <Typography>yesterday, last week/month/year, ago, in 2010, when</Typography> */}
      </Box>
    ),

    examples: [
      {
        lesson: 1000,
        title: <></>,
      },
    ],
  }),
  createData({
    lessonVisibility: 1000,
    en: (
      <Box>
        <Typography variant="h3">Future Continuous</Typography>
        <Typography gutterBottom>Дії, які виконуватимуться в майбутньому</Typography>
        {/* <Typography variant="h4">слова маркери:</Typography> */}
        {/* <Typography>зараз, у даний момент, прямо зараз, у ці дні</Typography> */}
        {/* <Typography>yesterday, last week/month/year, ago, in 2010, when</Typography> */}
      </Box>
    ),

    examples: [
      {
        lesson: 1000,
        title: <></>,
      },
    ],
  }),
  createData({
    lessonVisibility: 1000,
    en: (
      <Box>
        <Typography variant="h3">Past Continuous</Typography>
        <Typography gutterBottom>Дії, які виконуватимуться в майбутньому</Typography>
        {/* <Typography variant="h4">слова маркери:</Typography> */}
        {/* <Typography>зараз, у даний момент, прямо зараз, у ці дні</Typography> */}
        {/* <Typography>yesterday, last week/month/year, ago, in 2010, when</Typography> */}
      </Box>
    ),

    examples: [
      {
        lesson: 1000,
        title: <></>,
      },
    ],
  }),
  createData({
    lessonVisibility: 1000,
    en: (
      <Box>
        <Typography variant="h3">Present Perfect</Typography>
        {/* <Typography gutterBottom>Дії, які виконуватимуться в майбутньому</Typography> */}
        {/* <Typography variant="h4">слова маркери:</Typography> */}
        {/* <Typography>зараз, у даний момент, прямо зараз, у ці дні</Typography> */}
        {/* <Typography>yesterday, last week/month/year, ago, in 2010, when</Typography> */}
      </Box>
    ),

    examples: [
      {
        lesson: 1000,
        title: <></>,
      },
    ],
  }),
  createData({
    lessonVisibility: 1000,
    en: (
      <Box>
        <Typography variant="h3">Future Perfect</Typography>
        {/* <Typography gutterBottom>Дії, які виконуватимуться в майбутньому</Typography> */}
        {/* <Typography variant="h4">слова маркери:</Typography> */}
        {/* <Typography>зараз, у даний момент, прямо зараз, у ці дні</Typography> */}
        {/* <Typography>yesterday, last week/month/year, ago, in 2010, when</Typography> */}
      </Box>
    ),

    examples: [
      {
        lesson: 1000,
        title: <></>,
      },
    ],
  }),
  createData({
    lessonVisibility: 1000,
    en: (
      <Box>
        <Typography variant="h3">Past Perfect</Typography>
        {/* <Typography gutterBottom>Дії, які виконуватимуться в майбутньому</Typography> */}
        {/* <Typography variant="h4">слова маркери:</Typography> */}
        {/* <Typography>зараз, у даний момент, прямо зараз, у ці дні</Typography> */}
        {/* <Typography>yesterday, last week/month/year, ago, in 2010, when</Typography> */}
      </Box>
    ),

    examples: [
      {
        lesson: 1000,
        title: <></>,
      },
    ],
  }),
  createData({
    lessonVisibility: 1000,
    en: (
      <Box>
        <Typography variant="h3">Present Perfect Continuous</Typography>
        {/* <Typography gutterBottom>Дії, які виконуватимуться в майбутньому</Typography> */}
        {/* <Typography variant="h4">слова маркери:</Typography> */}
        {/* <Typography>зараз, у даний момент, прямо зараз, у ці дні</Typography> */}
        {/* <Typography>yesterday, last week/month/year, ago, in 2010, when</Typography> */}
      </Box>
    ),
    examples: [
      {
        lesson: 1000,
        title: <></>,
      },
    ],
  }),
  createData({
    lessonVisibility: 1000,
    en: (
      <Box>
        <Typography variant="h3">Future Perfect Continuous</Typography>
        {/* <Typography gutterBottom>Дії, які виконуватимуться в майбутньому</Typography> */}
        {/* <Typography variant="h4">слова маркери:</Typography> */}
        {/* <Typography>зараз, у даний момент, прямо зараз, у ці дні</Typography> */}
        {/* <Typography>yesterday, last week/month/year, ago, in 2010, when</Typography> */}
      </Box>
    ),
    examples: [
      {
        lesson: 1000,
        title: <></>,
      },
    ],
  }),
  createData({
    lessonVisibility: 1000,
    en: (
      <Box>
        <Typography variant="h3">Past Perfect Continuous</Typography>
        {/* <Typography gutterBottom>Дії, які виконуватимуться в майбутньому</Typography> */}
        {/* <Typography variant="h4">слова маркери:</Typography> */}
        {/* <Typography>зараз, у даний момент, прямо зараз, у ці дні</Typography> */}
        {/* <Typography>yesterday, last week/month/year, ago, in 2010, when</Typography> */}
      </Box>
    ),
    examples: [
      {
        lesson: 1000,
        title: <></>,
      },
    ],
  }),
];

const style = {
  position: "absolute" as "absolute",
  top: "25px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 400,
  overflow: "auto",
  maxHeight: "calc(100% - 50px)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const TensesTable: React.FC<{}> = ({}) => {
  const [isCellModalOpen, setIsCellModalOpen] = useState(false);
  const [isHeaderCellModalOpen, setIsHeaderCellModalOpen] = useState(false);
  const [activeWord, setActiveWord] = useState("");
  const [activeTranslation, setActiveTranslation] = useState("");
  const [activeDescription, setActiveDescription] = useState("");
  const [activeExamples, setActiveExamples] = useState<any[]>([]);
  const [headerCellModalBody, setHeaderCellModalBody] = useState<any>(null);
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);

  const globalWordsOptions = useMemo(() => {
    if (activeWord) {
      return { words: [activeWord] };
    }
    return { words: [] };
  }, [activeWord]);
  const [word] = useGlobalWords(globalWordsOptions);

  return (
    <>
      <Modal
        open={isCellModalOpen}
        onClose={() => {
          setIsCellModalOpen(false);
        }}
      >
        <Box sx={style}>
          <ModalCloseButton onClose={() => setIsCellModalOpen(false)} />
          <VStack alignItems="flex-start">
            <Typography variant="h3">{activeWord}</Typography>
            <Typography>{activeTranslation}</Typography>
            {word && <WordTranscription word={word} play small={false} autoplay={false} />}
            <Typography>{activeDescription}</Typography>
            <VStack alignItems="flex-start">
              {activeExamples.map((e, i) => {
                return (
                  <Typography variant="h3" key={i}>
                    {e.title}
                  </Typography>
                );
              })}
            </VStack>
          </VStack>
        </Box>
      </Modal>
      <Modal
        open={isHeaderCellModalOpen}
        onClose={() => {
          setIsHeaderCellModalOpen(false);
        }}
      >
        <Box sx={style}>
          <ModalCloseButton onClose={() => setIsHeaderCellModalOpen(false)} />
          <VStack alignItems="flex-start">{headerCellModalBody}</VStack>
        </Box>
      </Modal>

      {/* <Box display="flex" justifyContent="center"> */}
      <TableContainer component={Paper} sx={{ p: 0, width: "auto" }}>
        <Table sx={{ minWidth: 650, width: "auto" }} aria-label="simple table">
          <TableHead
            sx={{
              border: "1px solid rgba(224, 224, 224, 1)",
            }}
          >
            <TableRow>
              <TableCell>
                <Typography variant="h4">питальна форма </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4">стверджувальна форма </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4">заперечна форма </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: any, i) => {
              if (row.item1.lessonVisibility > lessonNumber) return null;
              return (
                <TableRow key={i}>
                  <CustomTableCell
                    width={250}
                    lessons={row.item1?.examples.map((e: any) => e.lesson) || []}
                    en={row.item1?.en || ""}
                    topOffeset={row.item1?.topOffeset || 0}
                    examples={row.item1?.examples || []}
                  ></CustomTableCell>
                  <CustomTableCell
                    width={250}
                    lessons={row.item2?.examples.map((e: any) => e.lesson) || []}
                    en={row.item2?.en || ""}
                    topOffeset={row.item2?.topOffeset || 0}
                    examples={row.item2?.examples || []}
                  ></CustomTableCell>
                  <CustomTableCell
                    width={250}
                    lessons={row.item3?.examples.map((e: any) => e.lesson) || []}
                    topOffeset={row.item3?.topOffeset || 0}
                    examples={row.item3?.examples || []}
                    en={row.item3?.en || ""}
                  ></CustomTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* </Box> */}
    </>
  );
};

const CustomTableCell: React.FC<{
  en: string;
  lessons: number[];
  width: number;
  topOffeset: number;
  examples: any[];
  onClick?: () => void;
}> = ({ en, lessons, width, examples, topOffeset, onClick }) => {
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);
  const isReady =
    (lessons?.length && lessons.includes(lessonNumber)) ||
    (lessons?.length && Math.min(...lessons) <= lessonNumber);

  const examplesArr = useMemo(() => {
    return examples
      .filter((e) => e.lesson <= lessonNumber)
      .map((e, i) => <Typography key={i}>{e.title}</Typography>);
  }, [examples, lessonNumber]);

  return (
    <TableCell
      component="th"
      scope="row"
      onClick={() => {
        if (onClick) onClick();
      }}
      sx={{
        p: 0,
        width,
        minWidth: width,
        pt: topOffeset,
        m: 0,
        // pointerEvents: isReady ? "auto" : "none",
        backgroundColor:
          // lessons?.length && lessons.includes(lessonNumber)
          //   ? "#ddffc5" green
          //   : lessons?.length && Math.min(...lessons) <= lessonNumber
          //   ? "#fcffdb" yellow,
          //   : "#d0d0d0", gray
          lessons?.length && Math.min(...lessons) <= lessonNumber ? "#fcffdb" : "#d0d0d0",
        // boxSizing: "content-box",
        height: "100%",
        // cursor: "pointer",
        minHeight: 0,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderLeft: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
        position: "relative",
        verticalAlign: "top",
      }}
    >
      <Box>
        <Box mb={0}>{en}</Box>
        {examplesArr}
      </Box>
    </TableCell>
  );
};

export default TensesTable;

const rules15 = [
  {
    id: "174",
    questionUa:
      "Чому літера 'o' читається як українські дві літери 'oу' у слові 'stone', а не просто 'o' як у слові 'not'?",
    correctAnswer:
      "Тому що у кінці слова літера 'e' яка змінює попередній голосний звук з короткого на довгий",
  },
  {
    id: "175",
    questionUa:
      "Чому літера 'a' читається як 'ей' у словах 'make', 'made' а не як 'е' як у словах 'apple, cat, can...'?",
    correctAnswer:
      "Тому що у кінці слова літера 'e' яка змінює попередній голосний звук з короткого на довгий",
  },
  {
    id: "176",
    questionUa: "Як читається літера 'і' у словах 'birth', 'birthday', 'circle'?",
    correctAnswer:
      "Літера 'i' перед літерою 'r' часто звучить як український мʼякий знак + літера 'o'. 'birth = бьорс', 'circle = сьоркл'",
  },
  {
    id: "177",
    questionUa:
      "Чому літера 'a' читається як 'o' у слові 'small' а не як 'е' як у словах 'apple, cat, can...'?",
    correctAnswer:
      "Літера 'a' зазвичай читається як 'o' якщо вона знаходиться перед двома літерами 'll'",
  },
  {
    id: "178",
    questionUa: "Як читаються літери 'ou' у словах 'round', 'down', 'our'?",
    correctAnswer: "Вимовляється як українські дві літери 'a' і 'у', тобто 'ау'",
  },
  {
    id: "179",
    questionUa: "Яка повна форма словосполучення 'letʼs', та що воно означає?",
    correctAnswer:
      "Повна форма 'let us' що перекладається 'дозволь нам'. Наприклад 'letʼs go' що дослівно означає 'дозволь нам піти', або ж просто 'давай ідемо'",
  },
];

export default rules15;

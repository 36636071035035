function timeToSeconds(time: string) {
  // const [minutes, seconds] = time.split(":").filter(Boolean).map(Number);
  // return minutes * 60 + seconds;

  const parts = time.split(":").filter(Boolean).map(Number);

  if (parts.length === 2) {
    // Format: minutes:seconds
    const [minutes, seconds] = parts;
    return minutes * 60 + seconds;
  } else if (parts.length === 3) {
    // Format: minutes:seconds:milliseconds
    const [minutes, seconds, milliseconds] = parts;
    let millisecondsToSeconds = milliseconds;
    if (millisecondsToSeconds.toString().length === 1)
      millisecondsToSeconds = millisecondsToSeconds / 10;
    if (millisecondsToSeconds.toString().length === 2)
      millisecondsToSeconds = millisecondsToSeconds / 100;
    if (millisecondsToSeconds.toString().length === 3)
      millisecondsToSeconds = millisecondsToSeconds / 1000;

    return minutes * 60 + seconds + millisecondsToSeconds;
  } else {
    throw new Error("Invalid time format");
  }
}

export default timeToSeconds;

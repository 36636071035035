"use client";

import { Box, Button, Modal, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ModalCloseButton from "../ModalCloseButton";
import { useLessonsStore } from "../../../store/lessonsStore";

const style = {
  position: "fixed",
  top: "25px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: "1000px",
  height: 1600,
  overflow: "auto",
  maxHeight: "calc(100% - 50px)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  zIndex: 9999999999999999999,
  boxShadow: 24,
  overflowX: "hidden",
};

const PageTranslation = () => {
  const [selectedText, setSelectedText] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const inputRef = useRef<any>(null);
  const [isDataModified, setIsDataModified] = useState(false);

  const onKeyDown = (event: any) => {
    // event.preventDefault(); // Prevent immediate closure
    // event.returnValue = ""; // Chrome requires returnValue to be set (can be empty)

    if (!isDataModified && event.metaKey && event.key === "w") {
      alert("____");
      for (let i = 0; i < 10000; i++) {
        // console.log("i", i);
      }
      inputRef.current.focus();
    }

    // alert("___");
    // return ""; // For other browsers
  };

  // const preventTabClose = (event: any) => {
  //   event.preventDefault(); // Prevent immediate closure
  //   event.returnValue = ""; // Chrome requires returnValue to be set (can be empty)

  //   if (event.metaKey && event.key === "w") {
  //     alert("____");
  //     for (let i = 0; i < 100000; i++) {
  //       // console.log("i", i);
  //     }
  //     inputRef.current.focus();
  //   }

  //   // alert("___");
  //   return ""; // For other browsers
  // };

  function markDataAsModified() {
    setIsDataModified(true);
  }

  useEffect(() => {
    // window.addEventListener("beforeunload", preventTabClose);
    window.addEventListener("keydown", onKeyDown);

    const inputElements = document.querySelectorAll("input, textarea");

    inputElements.forEach((element) => {
      element.addEventListener("input", markDataAsModified);
    });

    return () => {
      // window.removeEventListener("beforeunload", preventTabClose);
      window.removeEventListener("keydown", onKeyDown);
      inputElements.forEach((element) => {
        element.removeEventListener("input", markDataAsModified);
      });
    };
  }, []);

  const onMouseUp = (event: any) => {
    console.log("🚀 ~ event:", event);
    const selectedText = window.getSelection()?.toString();

    if (selectedText && event.altKey && !event.metaKey) {
      console.log("Selected text:", selectedText);
      // Add your custom logic here:
      // - Display an alert, tooltip, or custom context menu
      // - Perform actions on the selected text (copy, search, etc.)
      setSelectedText(selectedText);
      setIsPopupOpen(true);
    } else if (selectedText && event.metaKey && !event.altKey) {
      setTimeout(() => {
        window.open(
          `https://translate.google.com/?hl=uk&sl=en&tl=uk&text=${selectedText}&op=translate`,
          "_blank",
          "location=yes,height=1000,width=1500,scrollbars=yes,status=yes",
        );
      }, 100);
    } else if (selectedText && event.metaKey && event.altKey) {
      setTimeout(() => {
        window.open(
          `https://context.reverso.net/translation/english-ukrainian/${selectedText}`,
          "_blank",
          "location=yes,height=600,width=800,scrollbars=yes,status=yes",
        );
      }, 100);
    }
  };

  useEffect(() => {
    document.addEventListener("mouseup", onMouseUp);

    return () => {
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, []);

  const handleOpen = () => setIsPopupOpen(true);
  const handleClose = () => setIsPopupOpen(false);

  // useEffect(() => {

  // })

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: -1000,
        }}
      >
        <TextField value={Math.random()} />
      </Box>
      <Modal
        open={isPopupOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ModalCloseButton onClose={handleClose} />
          <Box
            component="iframe"
            sx={{
              width: "100%",
              border: "none",
              height: "100%",
              display: "flex",
              overflowX: "hidden",
            }}
            src={`https://wooordhunt.ru/word/${selectedText}`}
          ></Box>
        </Box>
      </Modal>
    </>
  );
};

export default PageTranslation;

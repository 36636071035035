import Box from "@mui/material/Box";

const lesson11: any = {
  letters: [],
  number: 11,
  storyIds: [
    1697,
    // 1698,
    // 1589, 1725, 1699, 1753
  ],
  poemIds: [1826],
  newLettersCount: 0,
  newSounsCount: 0,
  title: <Box>Mary had a little lamb</Box>,
  sounds: [
    "Oo(ɒ)",
    "Nn",
    "Tt",
    "Ee(e)",
    "Bb",
    "Dd",
    "Ll",
    "Ii(i)",
    "Pp",
    "Ss(s)",
    "Ss(z)",
    "Zz",
    "Aa(æ)",
    "Hh",
    "Vv",
    "Kk",
    "Aa(æ)",
    "Hh",
    "Vv",
    "Kk",
    "Mm",
    "Ff",
    "Gg",
    "Mm",
    "Ff",
    "Gg",
    "Cc(k)",
    "Rr",
    "oo(uː)",
    "ee(i:)",
    "th(ð)",
    "oo(ʊ)",
    "ea(i:)",
    "sh(ʃ)",
    "Ww",
    "Oo(əʊ)",
    "Ee(ɪ)",
    "j(dʒ)",
    "Uu(ʌ)",
    "Yy(j)",
    "Yy(i)",
    "oy(ɔɪ)",
    "ay(eɪ)",
  ],
  newSounds: [],
  words: [
    "he",
    "she",
    "want",
    "climb",
    "can",
    "do",
    "can't",
    "had",
    // "lamb",
    "its",
    "fleece",
    "was",
    "white",
    // "as",
    // "snow",
    // "and",
    // "everywhere",
    "that",
    "went",
    "sure",
    // "go",
    "follow",
    "followed",
    "her",
    // "school",
    "one",
    // "day",
    "against",
    "rule",
    "made",
    "child",
    "children",
    "laugh",
    // "play",
    // "see",
    // "in",
    // "at",
    //
    // "fast",
    // "slow",
    // "runs",
    // "very",
    // "jump",
    // "high",
    // "sun",
    // "low",
    // "plane",
    // "ring",
    // "here",
    // "there",
    // "glass",
    // "need",
    // "dishes",
    // "want",
    // "animal",
    // "run",
    // "every",
    // "sleep",
    // "store",
    // "zoo",
    // "today",
    // "hare",
    // "with",
    // "boy",
    // "plays",
    // "walk",
    // "understand",
    // "next",
    // "do",
    // "hot",
    // "climb",
    // "tree",
    // "bag",
    // "eat",
    // "sheep",
    // "geese",
    // "man",
    // "box",
    // "boxes",
    // "fox",
    // "foxes",
    // "mix",
    // "mixer",
    // "six",
    // "text",
    // "taxi",
    // "read",
    // "problem",
    // "they",
    // "animals",
    // "cup",
    // "understands",
    // "me",
    // "wash",
    // "water",
    // "wants",
    // "swan",
    // "cold",
    // "red",
    // "van",
    // "jeep",
    // "jam",
    // "trash",
    // "he",
    // "has",
    // "she",
    // "shop",
    // "we",
    // "likes",
    // "park",
    // "fish",
    // "climbs",
    // "needs",
    // "am",
    // "hamster",
    // "are",
    // "lambs",
    // "be",
    // "rat",
    // "mat",
    // "look",
    // "sat",
    // "too",
    // "fat",
    // "coffee",
    // "tea",
    // "cat",
    // "this",
    // "root",
    // "roots",
    // "seven",
    // "ran",
    // "room",
    // "pink",
    // "banana",
    // "cats",
    // "on",
    // "green",
    // "goose",
    // "plan",
    // "bedroom",
    // "bananas",
    // "pan",
    // "pans",
    // "fan",
    // "bike",
    // "five",
    // "kite",
    // "bikes",
    // "lime",
    // "life",
    // "time",
    // "map",
    // "maps",
    // "big",
    // "dog",
    // "dogs",
    // "pig",
    // "milk",
    // "is",
    // "hi",
    // "test",
    // "bed",
    // "ten",
    // "sport",
    // "leg",
    // "sister",
    // "continent",
    // "egg",
    // "car",
    // "star",
    // "end",
    // "no",
    // "note",
    // "stone",
    // "poet",
    // "arm",
    // "art",
    // "card",
    // "apple",
    // "hand",
    // "hat",
    // "yes",
    // "yard",
    // "hobby",
    // "Mary",
    // "hello",
    // "letter",
    // "dad",
    // "daddy",
    // "fact",
    // "family",
    // "frog",
    // "nose",
    // "rose",
    // "roses",
    // "mom",
    // "mommy",
    // "finger",
    // "song",
    // "I",
    // "tiger",
    // "spider",
    // "winter",
    // "spring",
    // "hop",
    // "can",
    // "England",
    // "summer",
    // "butter",
    // "up",
    // "the",
    // "bank",
    // "book",
    // "notebook",
    // "spoon",
    // "wood",
    // "brother",
    // "group",
    // "soup",
    // "you",
    // "where",
    // "baby",
    // "lady",
    // "name",
    // "cow",
    // "how",
    // "down",
    // "two",
  ],
  speakingWords: [
    "I can run fast.",
    "He can run fast.",
    "A hare can run fast.",
    "A hare is a fast animal.",
    "This dog is slow.",
    "A hamster is a slow animal.",
    "He runs every day. He is very fast.",
    "A cat can jump high.",
    "The sun is low.",
    "The sun is high.",
    "I can jump high.",
    "The plane is high.",
    "A cat can climb high.",
    "A cat can climb a high tree.",
    "The kite is high.",
    "The tree is high.",
    "The star is high.",
    "She runs in the park every day.",
    "I have a little bell. I can ring it fast and slow.",
    "She has a little bell. She can ring it high and low.",
    "Sit over here.",
    "Look over there.",
    "Stars are everywhere.",
    "This bell can ring.",
    "I run in the park every day.",
  ],
};

export default lesson11;

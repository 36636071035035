import { Box, Button } from "@mui/material";
import supabaseClient from "../../common/supabaseClient";
import { memo, useState } from "react";
import { Sentence } from "../../types/supabase";
import LoadingButton from "@mui/lab/LoadingButton";
import { AdminItem } from "../Admin/AdminAddSentences";
import ExampleSentenceItem from "../Word/WordTranslationSection/ExampleSentenceItem";
import VideoQuestionSentence from "./VideoQuestionSentence";
import useMountedWaitAsyncEffect from "../../hooks/useMountedWaitAsyncEffect";

const LoadExistingQuestionAndAnswer: React.FC<{ storyId: number; isVerified: boolean | null }> = ({
  storyId,
  isVerified,
}) => {
  const [sentences, setSentences] = useState<Sentence[]>([]);
  const [loading, setLoading] = useState(false);
  const [sentencesExists, setSentencesExists] = useState(false);
  console.log("LoadExistingQuestionAndAnswer");

  useMountedWaitAsyncEffect(async () => {
    const { data } = await supabaseClient
      .from("sentences")
      .select("*", { count: "exact", head: true })
      .eq("video_id", Number(storyId));

    if (data) setSentencesExists(true);
  }, []);

  if (!isVerified && !sentencesExists) return null;

  if (sentences.length) {
    return (
      <Box>
        {sentences.map((sentence, index) => {
          return (
            <Box key={index} mb={4}>
              <VideoQuestionSentence sentence={sentence} en={sentence.en} ua={sentence.ua} />
            </Box>
          );
        })}
      </Box>
    );
  }

  return (
    <Box>
      <LoadingButton
        loading={loading}
        variant="contained"
        color="error"
        onClick={async () => {
          setLoading(true);

          console.log("Number(storyId)", storyId);

          try {
            const { data } = await supabaseClient
              .from("sentences")
              .select()
              .eq("video_id", Number(storyId));
            console.log("🚀 ~ data:", data);

            if (data) setSentences(data);
          } catch (err: any) {
            console.log("🚀 ~ err:", err);
            alert(err.message);
          }
          setLoading(false);
        }}
      >
        Load existing questions
      </LoadingButton>
    </Box>
  );
};

export default memo(LoadExistingQuestionAndAnswer);

import AddIcon from "@mui/icons-material/Add";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveIcon from "@mui/icons-material/Remove";
import LoadingButton from "@mui/lab/LoadingButton";
import { IconButton, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import SwitchSelector from "react-switch-selector";
import isAdmin from "../../../common/isAdmin";
import supabaseClient from "../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../../store/lessonsStore";
import { DialogRepl as DialogReplType } from "../../../types/supabase";
import Accordion from "../Accordion";
import Box from "../Box";
import DialogRepl from "../Dialog/DialogRepl";
import HStack from "../HStack";
import LearnWordSection from "../LearnWordsSection";

interface PersonalDialogPreviewProps {
  type: string;
  label: string;
  person: "both" | "first" | "second";
  dialog: DialogReplType[];
  topics: string[];
  activeRepl: number;
  dialogWords: string[];
  expandedLabel: string;
  ignoredByUser: boolean;
  anotherUserUid: string | null;
  belongsToCurrentUser: boolean;
  onDialogExpnsion: (label: string) => void;
}

const PersonalDialogPreview: React.FC<PersonalDialogPreviewProps> = ({
  type,
  label,
  person,
  dialog,
  topics,
  activeRepl,
  dialogWords,
  expandedLabel,
  ignoredByUser,
  anotherUserUid,
  belongsToCurrentUser,
  onDialogExpnsion,
}) => {
  const authUser = useLessonsStore((state) => state.authUser);
  const [isLoading, setIsLoading] = useState(false);
  const [isIgnoreButtonLoading, setIsIgnoreButtonLoading] = useState(false);

  const handleAddToMyDialogs = useCallback(async () => {
    setIsLoading(true);

    const dialogId = `${anotherUserUid || authUser?.uuid}-${label}`;

    const { data: existingUserDialog } = await supabaseClient
      .from("user-dialogs")
      .select("id, ignored")
      .eq("id", dialogId)
      .maybeSingle();

    if (existingUserDialog) {
      await supabaseClient.from("user-dialogs").delete().eq("id", dialogId);
    } else {
      await supabaseClient.from("user-dialogs").upsert({
        id: dialogId,
        dialog: label,
        user_id: anotherUserUid || authUser?.uuid,
      });
    }
    setIsLoading(false);
  }, [authUser, label, anotherUserUid]);

  const handleIgnoreToMyDialogs = useCallback(async () => {
    setIsIgnoreButtonLoading(true);

    const dialogId = `${anotherUserUid || authUser?.uuid}-${label}`;

    const { data: existingUserDialog } = await supabaseClient
      .from("user-dialogs")
      .select("id, ignored")
      .eq("id", dialogId)
      .maybeSingle();

    if (existingUserDialog) {
      await supabaseClient.from("user-dialogs").delete().eq("id", dialogId);
    } else {
      await supabaseClient.from("user-dialogs").upsert({
        id: dialogId,
        dialog: label,
        ignored: true,
        user_id: anotherUserUid || authUser?.uuid,
      });
    }

    setIsIgnoreButtonLoading(false);
  }, [authUser, label, anotherUserUid]);

  return (
    <HStack>
      <HStack mb={5}>
        <HStack width="100%">
          {isAdmin(authUser?.id) && (
            <LoadingButton
              loading={isLoading}
              color="inherit"
              // disabled={belongsToCurrentUser}
              sx={{
                backgroundColor: "white",
                color: "black",
                border: "1px solid green",
                padding: 0,
                minWidth: 0,
              }}
              onClick={handleAddToMyDialogs}
            >
              {belongsToCurrentUser ? <DownloadDoneIcon color="primary" /> : <AddIcon />}
            </LoadingButton>
          )}

          {isAdmin(authUser?.id) && (
            <LoadingButton
              loading={isIgnoreButtonLoading}
              color="inherit"
              // disabled={belongsToCurrentUser}
              sx={{
                backgroundColor: "white",
                color: "black",
                border: "1px solid red",
                padding: 0,
                minWidth: 0,
              }}
              onClick={handleIgnoreToMyDialogs}
            >
              {ignoredByUser ? <DownloadDoneIcon color="error" /> : <RemoveIcon />}
            </LoadingButton>
          )}
          <Typography variant="h4" fontStyle="italic" color="primary">
            {type}
          </Typography>
          {topics.map((t) => (
            <Typography variant="h4" fontStyle="italic">
              {t}
            </Typography>
          ))}
        </HStack>
        {authUser && (
          <ShowDialog
            label={label}
            activeRepl={activeRepl}
            dialog={dialog}
            person={person}
            anotherUserUid={anotherUserUid}
            dialogWords={dialogWords}
            isActive={belongsToCurrentUser}
            userUuid={anotherUserUid || authUser.uuid}
            expandedLabel={expandedLabel}
            ignoredByUser={ignoredByUser}
            onDialogExpnsion={onDialogExpnsion}
            belongsToCurrentUser={belongsToCurrentUser}
          />
        )}
      </HStack>
    </HStack>
  );
};

const personOptions = [
  {
    label: "Перша",
    value: "first",
  },
  {
    label: "Обидві",
    value: "both",
  },
  {
    label: "Друга",
    value: "second",
  },
];

const ShowDialog: React.FC<{
  label: string;
  dialogWords: string[];
  dialog: DialogReplType[];
  activeRepl: number;
  person: "both" | "first" | "second";
  isActive: boolean;
  userUuid: string;
  expandedLabel: string;
  ignoredByUser: boolean;
  anotherUserUid: string | null;
  belongsToCurrentUser: boolean;
  onDialogExpnsion: (label: string) => void;
}> = ({
  label,
  dialog,
  activeRepl: inputActiveRepl,
  person: inputPerson,
  // isActive,
  expandedLabel,
  userUuid,
  dialogWords,
  ignoredByUser,
  anotherUserUid,
  belongsToCurrentUser,
  onDialogExpnsion,
}) => {
  const [person, setPerson] = useState<"both" | "first" | "second">("both");
  const [newDialogWords, setNewDialogWords] = useState<string[]>([]);
  const [newWordsCount, setNewWordsCount] = useState<number | null>(null);
  const [isWordsLoading, setIsWordsLoading] = useState(false);
  const [learningWordsModalIsOpen, setLearningWordsModalIsOpen] = useState(false);
  // const [expanded, setExpanded] = useState(false);
  const handleExpansion = () => {
    onDialogExpnsion(label);
    // setExpanded((prevExpanded) => !prevExpanded);
  };
  const [currentRepl, setCurrentRepl] = useState(0);
  const authUser = useLessonsStore((state) => state.authUser);

  useEffect(() => {
    setCurrentRepl(inputActiveRepl);
  }, [inputActiveRepl]);

  const splitedDealog = useMemo(() => {
    const sD: DialogReplType[][] = [];

    dialog.forEach((d, i) => {
      if (i % 2 !== 0) {
        sD.push([dialog[i - 1], dialog[i]]);
      }
    });

    return sD;
  }, [dialog]);

  const [firstCurrentRepl, secondCurrentRepl] = useMemo(() => {
    return splitedDealog[currentRepl];
  }, [splitedDealog, currentRepl]);

  useMountedWaitAsyncEffect(async () => {
    const { count } = await supabaseClient
      .from("user-words")
      .select("", { count: "exact", head: true })
      .in(
        "id",
        dialogWords.map((w) => `${anotherUserUid || userUuid}-${w}`),
      );

    if (count !== null) setNewWordsCount(dialogWords.length - count);
  }, [dialogWords, userUuid, anotherUserUid]);

  return (
    <>
      <Accordion.Accordion
        disableGutters
        sx={{
          boxShadow: "none",
        }}
        expanded={expandedLabel === label}
        onChange={handleExpansion}
      >
        <Accordion.AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          sx={{
            padding: 0,
          }}
          id="panel1-header"
        >
          <Typography
            color={
              !ignoredByUser && belongsToCurrentUser
                ? "primary"
                : ignoredByUser
                ? "error"
                : "inherit"
            }
          >
            {label}
          </Typography>
        </Accordion.AccordionSummary>
        <Accordion.AccordionDetails
          sx={{
            padding: 0,
          }}
        >
          <Box>
            <Box mb={4}>
              {!!newWordsCount && newWordsCount > 0 && (
                <LoadingButton
                  loading={isWordsLoading || newWordsCount === null}
                  variant="outlined"
                  onClick={async () => {
                    if (!newDialogWords.length) {
                      setIsWordsLoading(true);

                      const { data } = await supabaseClient
                        .from("user-words")
                        .select("word")
                        .in(
                          "id",
                          dialogWords.map((w) => `${anotherUserUid || userUuid}-${w}`),
                        );

                      if (data) {
                        const existingRowWords = data.map(({ word }) => word);
                        const newWords = dialogWords.filter((w) => !existingRowWords.includes(w));

                        setNewDialogWords(newWords);
                      }

                      setIsWordsLoading(false);
                    }

                    setLearningWordsModalIsOpen(true);
                  }}
                >
                  Вчити слова ({newWordsCount})
                </LoadingButton>
              )}
            </Box>
            <LearnWordSection
              currentWords={newDialogWords}
              withoutButton
              isOpen={learningWordsModalIsOpen}
              lessonId={`personal_dialog_lesson_${label}`}
              onDrawerClose={() => {
                setLearningWordsModalIsOpen(false);
              }}
              onUpdateWords={(newWords) => {
                // setCurrentWords(newWords);
                setNewDialogWords(newWords);
              }}
            />

            {isAdmin(authUser?.id) && (
              <Box width={300} py={4} sx={{ fontFamily: "Rubik" }}>
                <SwitchSelector
                  name="Особа"
                  onChange={async (value) => {
                    setPerson(value as "both" | "first" | "second");
                    await supabaseClient
                      .from("dialog-admin")
                      .update({
                        value,
                      })
                      .eq("id", "active-person");
                  }}
                  options={personOptions}
                  initialSelectedIndex={personOptions.findIndex(({ value }) => value === person)}
                  fontSize={18}
                />
              </Box>
            )}

            <HStack>
              <Box width="100%" key={firstCurrentRepl.audio}>
                <DialogRepl
                  en={firstCurrentRepl.en}
                  ua={firstCurrentRepl.ua}
                  onUaChanged={async ({ ua, en }) => {
                    const newDialog = dialog.map((repl) => {
                      if (repl.en === en)
                        return {
                          en: repl.en,
                          ua,
                        };
                      return repl;
                    });
                    await supabaseClient
                      .from("dialogs")
                      .update({
                        dialog: newDialog,
                      })
                      .eq("id", label);
                  }}
                  person={inputPerson}
                  isVisible={
                    isAdmin(authUser?.id) || inputPerson === "both" || inputPerson === "first"
                  }
                  url={firstCurrentRepl.audio}
                  enVisible
                  uaVisible
                />
                <DialogRepl
                  en={secondCurrentRepl.en}
                  ua={secondCurrentRepl.ua}
                  onUaChanged={async ({ ua, en }) => {
                    const newDialog = dialog.map((repl) => {
                      if (repl.en === en)
                        return {
                          en: repl.en,
                          ua,
                        };
                      return repl;
                    });
                    await supabaseClient
                      .from("dialogs")
                      .update({
                        dialog: newDialog,
                      })
                      .eq("id", label);
                  }}
                  person={inputPerson}
                  isVisible={
                    isAdmin(authUser?.id) || inputPerson === "both" || inputPerson === "second"
                  }
                  url={secondCurrentRepl.audio}
                  enVisible
                  uaVisible
                />
              </Box>

              {isAdmin(authUser?.id) &&
                splitedDealog.map((_, i) => {
                  return (
                    <IconButton
                      color={+currentRepl === i ? "primary" : "default"}
                      onClick={async () => {
                        await supabaseClient
                          .from("dialog-admin")
                          .update({
                            value: i,
                          })
                          .eq("id", "active-repl");

                        setCurrentRepl(i);
                      }}
                    >
                      <Box
                        width={30}
                        height={30}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {i + 1}
                      </Box>
                    </IconButton>
                  );
                })}
            </HStack>
          </Box>
        </Accordion.AccordionDetails>
      </Accordion.Accordion>
    </>
  );
};

export default PersonalDialogPreview;

import { useEffect, useMemo, useState } from "react";
import { usePrevious } from "react-use";
import firstLetterUppercase from "../common/firstLetterUppercase";
import getSentenceWords from "../common/getSentenceWords";
import supabaseClient from "../common/supabaseClient";
import { Word } from "../types/supabase";

const useLessonWords = ({ sentences, trigger }: { sentences: string[]; trigger: any }) => {
  const prevSentences = usePrevious(sentences);
  const personalLessonsSentencesWords = useMemo(() => {
    if (!sentences) return;
    const words = sentences.map((s) => getSentenceWords(s));

    const uniqueWords = Array.from(new Set(words.flat())).map((w) => w.toLowerCase());
    const uniqueWordsWithFirstUppercase = Array.from(new Set(words.flat())).map((w) =>
      firstLetterUppercase(w),
    );

    return [...uniqueWords, ...uniqueWordsWithFirstUppercase];
  }, [sentences]);

  const [lessonWords, setLessonWords] = useState<Word[]>([]);

  useEffect(() => {
    if (!personalLessonsSentencesWords) return;
    if (prevSentences && prevSentences[0] === sentences[0]) return;

    (async () => {
      const { data } = await supabaseClient
        .from("words")
        .select()
        .in("name", personalLessonsSentencesWords)
        .returns<Word[]>();

      if (data) setLessonWords(data);
    })();
  }, [trigger, prevSentences, personalLessonsSentencesWords, sentences]);

  return {
    lessonWords,
  };
};

export default useLessonWords;

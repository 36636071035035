import { Checkbox, IconButton, Menu, MenuItem, Popover, Typography } from "@mui/material";
import { useLessonsStore } from "../../../store/lessonsStore";
import Box from "../Box";
import HStack from "../HStack";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";

const Settings: React.FC<{
  isUserAbleToGoAhead: boolean;
  setIsUserAbleToGoAhead: any;
}> = ({ isUserAbleToGoAhead, setIsUserAbleToGoAhead }) => {
  const isTeacherLesson = useLessonsStore((state) => state.isTeacherLesson);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  if (!isTeacherLesson) return null;

  return (
    <Box>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          margin: 5,
        }}
      >
        <MoreVertIcon />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {/* <Typography sx={{ p: 2 }}>

        </Typography> */}
        <HStack
          px={5}
          py={3}
          sx={{
            border: "1px solid black",
          }}
        >
          <Typography>кнопки</Typography>
          <Checkbox
            checked={isUserAbleToGoAhead}
            // defaultChecked
            onChange={(event) => {
              setIsUserAbleToGoAhead(event.target.checked);
              // console.log("🚀 ~ value:", value.target.checked);
            }}
          />
        </HStack>
      </Popover>
    </Box>
  );
};

export default Settings;

import { Box, Button, Link as MuiLink, Typography } from "@mui/material";

import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import getRandomGreetings from "../../../common/getRandomGreetings";
import isAdmin from "../../../common/isAdmin";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import supabaseClient from "../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../../store/lessonsStore";
import {
  EnglishQuote,
  EnglishStory,
  Module,
  Sentence,
  UserSentence,
  Word,
} from "../../../types/supabase";
import AudioPlayer from "../../Audio/AudioPlayer";
import WordAudio from "../../Audio/WordAudio";
import MenuLayout from "../../Layout/MenuLayout";
import MicSentences from "../../Mic/MicSentences";
import SentencePazleItem from "../../Sentence/SentencePazleItem";
import UkrainianWord from "../../Sentence/UkrainianWord";
import HStack from "../../Unknown/HStack";
import Loader from "../../Unknown/Loader";
import VStack from "../../Unknown/VStack";
import HoverDetails from "../../Word/HoverDetails";
import WordLetterPazleItem from "../../Word/WordLetterPazleItem";
import GrammarHomework from "./tabs/GrammarHomework";
import Quotes from "../Quotes";
import Stories from "../Stories";
import LinearProgress from "../../User/LinearProgress";
import BuildWords from "./tabs/BuildWords";
import getStyleForTab from "./utils/getStyleForTab";
import WordsCarousel from "./tabs/WordsCarousel";
import BuildSentences from "./tabs/BuildSentences";
import Listening from "./tabs/Listening";
import Speaking from "./tabs/Speaking";
import QuotesDrawer from "./tabs/QuotesDrawer";
import useGlobalWords from "../../hooks/getGlobalWords";
import ControllingStudentPage from "../../Unknown/Root/ControllingStudentPage";

const GrammarLesson: React.FC = () => {
  const { lessonId, lessonType } = useParams();
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);
  const setLessonNumber = useLessonsStore((state) => state.setLessonNumber);

  useEffect(() => {
    if (lessonId) setLessonNumber(+lessonId);
  }, [lessonId]);

  const globalWords = useLessonsStore((state) => state.globalWords);
  const [curerntModuleWordsMap, setCurrentModuleWordsMap] = useState<Record<string, Word>>({});
  const lessonData = useLessonsStore((state) => state.lessonData);
  console.log("🚀 ~ lessonData:", lessonData);
  const setLessonData = useLessonsStore((state) => state.setLessonData);
  const { lessonAllWords, lessonWriteWords, sentences } = useMemo(() => {
    const initial = {
      lessonAllWords: (lessonId && lessonData[lessonId]?.module?.words) || [],
      lessonWriteWords: (lessonId && lessonData[lessonId]?.module?.write_words) || [],
      sentences: (lessonId && lessonData[lessonId]?.sentences) || [],
    };

    return initial;
  }, [lessonData]);

  const options = useMemo(() => {
    return { words: lessonAllWords };
  }, [lessonAllWords]);
  console.log("🚀 [grammarLesson] lesson words to fetch:", options);

  useGlobalWords(options);

  useEffect(() => {
    if (lessonAllWords?.length) {
      const map = globalWords
        .filter(
          (w) => lessonAllWords.includes(w.name) || lessonAllWords.includes(w.name.toLowerCase()),
        )
        .reduce((acm, w) => {
          return {
            ...acm,
            [w.name]: w,
            [w.name.toLowerCase()]: w,
          };
        }, {});

      setCurrentModuleWordsMap(map);
    }
  }, [globalWords, lessonAllWords]);

  // useMountedWaitAsyncEffect(async () => {
  //   if (module?.words) {
  //     const existingWords = globalWords.filter(
  //       (w) => module.words.includes(w.name) || module.words.includes(w.name.toLowerCase()),
  //     );

  //     if (existingWords.length === module.words.length) {
  //       setCurrentModuleWords(existingWords);
  //     } else {
  //       const rowExistingWords = existingWords.map((w) => w.name);
  //       const notExistingWords = module.words.filter(
  //         (w) => !rowExistingWords.includes(w) && !rowExistingWords.includes(w.toLowerCase()),
  //       );

  //       const { data } = await supabaseClient.from("words").select().in("name", notExistingWords);

  //       if (data) {
  //         setCurrentModuleWords([...existingWords, ...data]);

  //         setGlobalWords(data);
  //       }
  //     }
  //   }
  // }, [module]);

  useMountedWaitAsyncEffect(async () => {
    if (!lessonId) return;

    let module, sentences, withoutGrammar;

    if (!lessonData[lessonId]?.module) {
      ({ data: module } = await supabaseClient
        .from("grammar_course")
        .select()
        .eq("module", lessonId)
        .returns<Module>()
        .single());
    }

    if (!lessonData[lessonId]?.sentences) {
      ({ data: sentences } = await supabaseClient
        .from("grammar_course_sentences")
        .select("*, sentence(*)")
        .eq("module", lessonId)
        .order("order"));
    }

    if (module && sentences) {
      setLessonData({ lessonId, module, sentences: sentences.map((s) => s.sentence) });
    } else if (module) {
      setLessonData({ lessonId, module, sentences: [] });
    }
  }, [lessonId, lessonData]);

  const [buildSentenceCount, setBuildSentenceCount] = useState(0);
  const [wordIndex, setWordIndex] = useState(0);

  const authUser = useLessonsStore((state) => state.authUser);
  const [userSentences, setUserSentences] = useState<string[]>([]);

  useMountedWaitAsyncEffect(async () => {
    if (lessonWriteWords) {
      const { data: notExistingWords } = await supabaseClient.rpc("get_not_existing_words", {
        word_list: lessonWriteWords,
      });

      if (notExistingWords.length) {
        await Promise.all(
          notExistingWords.map((w: string) => {
            return supabaseClient.rpc("add_user_word", {
              userprop: authUser!.id,
              word: w,
            });
          }),
        );
      }
    }
  }, [lessonWriteWords]);

  const getUserSentences = useCallback(async () => {
    if (!sentences.length) return;

    const { data: userExistingSentences } = await supabaseClient
      .from("user-sentences")
      .select("en")
      .in("id", Array.from(new Set(sentences.map((s) => `${authUser!.uuid}-${s.en}`))))
      .returns<UserSentence[]>();

    if (userExistingSentences) {
      setUserSentences(userExistingSentences.map(({ en }) => en));
    }
  }, [sentences, authUser]);

  useMountedWaitAsyncEffect(async () => {
    if (authUser) await getUserSentences();
  }, [sentences]);

  useEffect(() => {
    const channel = supabaseClient
      .channel("room7")
      .on(
        "postgres_changes",
        { event: "INSERT", schema: "public", table: "user-sentences" },
        async (payload) => {
          console.log("🚀 ~ payload:", payload);
          await getUserSentences();
          if (payload.new?.en) {
            setUserSentences((prev) => [...prev, payload.new.en]);
          }
        },
      )
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, []);

  const { prevWords, currentWord } = useMemo(() => {
    const words = [...lessonWriteWords];

    words.length = wordIndex;

    const currentWord = lessonWriteWords[wordIndex];

    return { prevWords: words, currentWord };
  }, [lessonWriteWords, wordIndex]);

  const wordsGreeting = useMemo(() => {
    return getRandomGreetings("Всі слова складені.");
  }, []);
  const sentencesGreeting = useMemo(() => {
    return getRandomGreetings("Всі речення перекладені.");
  }, []);

  const [quotes, setQuotes] = useState<EnglishQuote[]>([]);
  const [stories, setStories] = useState<EnglishStory[]>([]);
  const areThereQuotes = !!(lessonId && lessonData[lessonId]?.module?.quotes?.length);
  const areThereStories = !!(lessonId && lessonData[lessonId]?.module?.stories?.length);
  const setIsGrammarFeedOpen = useLessonsStore((state) => state.setIsGrammarFeedOpen);

  useMountedWaitAsyncEffect(async () => {
    const quotes = lessonId && lessonData[lessonId]?.module?.quotes;

    if (!areThereQuotes || !quotes) return;

    const { data } = await supabaseClient.from("english_quotes").select().in("id", quotes);

    if (data) setQuotes(data);
  }, [lessonData, lessonId, areThereQuotes]);

  useMountedWaitAsyncEffect(async () => {
    const stories = lessonId && lessonData[lessonId]?.module?.stories;
    if (!areThereStories || !stories) return;

    const { data } = await supabaseClient.from("english_verses").select().in("id", stories);

    if (data) setStories(data);
  }, [lessonData, lessonId, areThereStories]);

  return (
    <MenuLayout isFluid>
      <Box width="100%" overflow="hidden" position="relative">
        <Box sx={{ backgroundColor: "white" }} py={3} px={3} mb={2} width="100%">
          <VStack gap={1}>
            <MuiLink color="secondary.dark" component={Link} to={`/grammar`}>
              <Typography>Усі уроки</Typography>
            </MuiLink>
            <HStack>
              <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                <ControllingStudentPage isNested />
                <Typography variant="h3">Урок {lessonNumber}</Typography>
                <WordsCarousel words={lessonWriteWords || []} />
                {/* {!lessonData[lessonId || "0"]?.module.without_grammar && (
                  <Button
                    variant="outlined"
                    // size="small"
                    onClick={() => {
                      setLessonNumber(+(lessonId || 0));
                      setIsGrammarFeedOpen(true);
                    }}
                  >
                    Граматика
                  </Button>
                )} */}
                {areThereStories && <Stories stories={stories} />}
                <BuildSentences
                  lessonSentences={sentences}
                  userSentences={userSentences}
                  curerntModuleWordsMap={curerntModuleWordsMap}
                />
                {/* <BuildWords
                  curerntModuleWordsMap={curerntModuleWordsMap}
                  areThereQuotes={areThereQuotes}
                  wordsGreeting={wordsGreeting}
                  prevWords={prevWords}
                  moduleWords={lessonWriteWords || []}
                  wordsLength={lessonWriteWords?.length || 0}
                /> */}
                {/* <Listening lessonSentences={sentences} /> */}
                {/* <Speaking lessonSentences={sentences} /> */}
                {/* {areThereQuotes && <QuotesDrawer quotes={quotes} />} */}
                <GrammarHomework sentences={sentences} userSentences={userSentences} />
              </Box>
            </HStack>
            <HStack>
              {/* {isAdmin(authUser?.id) && (
                <MuiLink
                  color={lessonType === "build-words-list" ? "primary" : "secondary.dark"}
                  component={Link}
                  to={`/grammar/grammar-lesson/${lessonId}/build-words-list`}
                >
                  <Typography color="red">
                    Нові слова {module?.write_words.length ? `(${module.write_words.length})` : ""}
                  </Typography>
                </MuiLink>
              )}
              {isAdmin(authUser?.id) && (
                <MuiLink
                  color={lessonType === "build-sentences-list" ? "primary" : "secondary.dark"}
                  component={Link}
                  to={`/grammar/grammar-lesson/${lessonId}/build-sentences-list`}
                >
                  <Typography color="red">Список речень</Typography>
                </MuiLink>
              )} */}
            </HStack>
          </VStack>
        </Box>
        {/* <Box sx={getStyleForTab(lessonType, "build-words-list")}>
          <Box sx={{ backgroundColor: "white" }} py={5} px={3} mb={2} width="100%">
            {!!module?.write_words && (
              <VStack gap={7}>
                {module.write_words.map((word) => {
                  if (!curerntModuleWordsMap[word]) return null;
                  const globalWord = globalWords.find((w) => w.name === word);

                  return (
                    <VStack gap={0}>
                      <Typography variant="h3">{word}</Typography>
                      <Typography>{globalWord?.transcription}</Typography>
                      <WordAudio url={globalWord?.audio_url || ""} />
                      <UkrainianWord translation={globalWord?.translation || ""} />
                    </VStack>
                  );
                })}
              </VStack>
            )}
          </Box>

          {!!prevWords.length && (
            <VStack sx={{ backgroundColor: "white" }}>
              <Box pt={5} pb={allWordsAreBuilt ? 0 : 5} px={3}>
                {prevWords.map((w) => {
                  return (
                    <HoverDetails
                      // isCenter
                      words={[
                        {
                          ...curerntModuleWordsMap[w],
                          id: w,
                          page: 1,
                          word: w,
                          label: w,
                        },
                      ]}
                    />
                  );
                })}
              </Box>
            </VStack>
          )}
        </Box> */}
        {/* <Box sx={getStyleForTab(lessonType, "build-sentences-list")}>
          <Box sx={{ backgroundColor: "white" }} py={5} px={3} mb={2} width="100%">
            {!lessonSentences.length && <Loader />}
            {!!lessonSentences.length && (
              <VStack alignItems="flex-start">
                {lessonSentences.map((sentence) => {
                  return (
                    <Box key={sentence.en}>
                      <Typography>{sentence.en}</Typography>
                      <Typography variant="caption">{sentence.ua}</Typography>
                    </Box>
                  );
                })}
              </VStack>
            )}
          </Box>
        </Box> */}
      </Box>
    </MenuLayout>
  );
};

export default GrammarLesson;

import { Box, Paper, Typography } from "@mui/material";
import { memo, useEffect, useState } from "react";
import firstLetterUppercase from "../../../common/firstLetterUppercase";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import startsWithSubstringAndNonLetter from "../../../common/startsWithSubstringAndNonLetter";

interface WordPazleItemProps {
  word: string;
  label: string;
  audio?: string;
  initialSentence: string;
  partialSentence: string;
  byLetters?: boolean;
  wordIndex: number;
  visible: boolean;
  onAnswer: (newPartialSentence?: string, minus?: number) => void;
}

// const alphabetIndexMap

const WordPazleItem = ({
  word,
  audio,
  wordIndex,
  visible,
  byLetters,
  label,
  initialSentence,
  partialSentence,
  onAnswer,
}: WordPazleItemProps) => {
  const [color, setColor] = useState<"inherit" | "error">("inherit");
  const [play, setPlay] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (color === "error") {
      timeout = setTimeout(() => {
        setColor("inherit");
      }, 1000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [color]);

  return (
    <Box
      component={Paper}
      elevation={2}
      borderRadius={2}
      borderColor="#efefef"
      alignItems="center"
      display="flex"
      color={color}
      px={4}
      py={2}
      gap={2}
      sx={{
        padding: "5px 17px",
        fontSize: "20px",
        textAlign: "center",
        cursor: "pointer",
        outline: "none",
        color: !visible ? "#e3e3e3" : "#a77301",
        backgroundColor: "#fff",
        border: `3px solid ${color === "error" ? "#ffb0b0" : "#efefef"}`,
        borderRadius: "15px",
        boxShadow: "0 5px #e8e8e8",
        pointerEvents: !visible ? "none" : "auto",
        opacity: !visible ? 0.6 : 1,
        transition: "border .3s ease",
        "&:active": {
          backgroundColor: "#fff",
          boxShadow: "0 5px #e8e8e8",
          transform: "translateY(2px)",
        },
      }}
      onClick={() => {
        setPlay(true);
        const cleanLabel = removePunctuationFromString(label);

        console.log("🚀 ~ partialSentence:", partialSentence);
        let maybeCorrect = !!partialSentence.length
          ? `${partialSentence}${byLetters ? "" : " "}${cleanLabel}`
          : cleanLabel;
        let maybeCorrectWithLowerLabel = !!partialSentence.length
          ? `${partialSentence}${byLetters ? "" : " "}${cleanLabel.toLowerCase()}`
          : cleanLabel.toLowerCase();
        let maybeCorrectWithoutPunctuation = !!partialSentence.length
          ? `${partialSentence}${byLetters ? "" : " "}${removePunctuationFromString(label)}`
          : removePunctuationFromString(label);
        console.log("🚀 ~ maybeCorrectWithLowerLabel:", maybeCorrectWithLowerLabel);
        console.log("🚀 ~ maybeCorrect:", maybeCorrect);
        if (!partialSentence.length && !byLetters) {
          // fix first letter uppercase if there is more then one the same word in the sentence
          // accume that all sentences start with upper case letter
          maybeCorrect = firstLetterUppercase(maybeCorrect);
          maybeCorrectWithoutPunctuation = firstLetterUppercase(maybeCorrectWithoutPunctuation);
        }

        console.log(
          " startsWithSubstringAndNonLetter(removePunctuationFromString(initialSentence),removePunctuationFromString(maybeCorrect))) ",

          startsWithSubstringAndNonLetter(
            removePunctuationFromString(initialSentence),
            removePunctuationFromString(maybeCorrect),
          ),
          removePunctuationFromString(initialSentence),
          removePunctuationFromString(maybeCorrect),
        );

        if (
          (byLetters && initialSentence.startsWith(maybeCorrect)) ||
          (!byLetters &&
            startsWithSubstringAndNonLetter(
              removePunctuationFromString(initialSentence),
              removePunctuationFromString(maybeCorrect),
            )) ||
          (!byLetters && initialSentence === maybeCorrect)
        ) {
          onAnswer(maybeCorrect);
        } else if (
          (byLetters && initialSentence.startsWith(maybeCorrectWithoutPunctuation)) ||
          (!byLetters &&
            startsWithSubstringAndNonLetter(initialSentence, maybeCorrectWithoutPunctuation))
        ) {
          onAnswer(maybeCorrectWithoutPunctuation);
        } else if (
          (byLetters && initialSentence.startsWith(maybeCorrectWithLowerLabel)) ||
          (!byLetters &&
            startsWithSubstringAndNonLetter(initialSentence, maybeCorrectWithLowerLabel))
        ) {
          onAnswer(maybeCorrectWithLowerLabel);
        } else {
          setColor("error");

          let newPartial = partialSentence;

          if (newPartial.length > 3) {
            newPartial = newPartial.slice(0, newPartial.length - 2);
            // onAnswer(partialSentence.slice(0, partialSentence.length - 2), -3);
          } else {
            newPartial = newPartial.slice(0, newPartial.length - 1);
            // onAnswer(partialSentence.slice(0, partialSentence.length - 1), -2);
          }
          onAnswer("");
        }
        setTimeout(() => {
          setPlay(false);
        }, 2000);
      }}
    >
      <Typography
        variant="h3"
        fontSize={17}
        fontWeight={500}
        position="relative"
        sx={{
          // fontFamily: "Helvetica",
          fontWeight: 600,
          fontFamily: "monospace",
          // : Arimo (sans-serif), Tinos (serif), and Cousine (monospace)
        }}
      >
        {word.trim() ? word : <Box component="span">_</Box>}
        {/* {word} */}
        {/* {audio && play && <WordAudio url={audio} small autoPlay boxHide />} */}
      </Typography>
    </Box>
  );
};

export default memo(WordPazleItem);

import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { wordsToSkip } from "../../common/constants";
import removePunctuationFromString from "../../common/removePunctuationFromString";
import supabaseClient from "../../common/supabaseClient";
import wait from "../../common/wait";
import useMountedWaitAsyncEffect from "../../hooks/useMountedWaitAsyncEffect";
import MenuLayout from "../Layout/MenuLayout";
import HStack from "../Unknown/HStack";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useFirebaseApp } from "reactfire";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import VStack from "../Unknown/VStack";
import getSentenceWords from "../../common/getSentenceWords";

const AdminAddVerse: React.FC = () => {
  const [text, setText] = useState("");
  const [author, setAuthor] = useState("");
  const [link, setLink] = useState("");
  const [storageLink, setStorageLink] = useState("");
  const [words, setWords] = useState<string[]>([]);
  // const [type, setType] = useState("song");
  const [type, setType] = useState("poem");
  // const [type, setType] = useState("story");
  const [newWords, setNewWords] = useState<string[]>([]);
  const [upperCaseVerseWords, setUpperCaseVerseWords] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newUpperCaseWords, setNewUpperCaseWords] = useState<string[]>([]);
  const [upperCaseWords, setUpperCaseWords] = useState<string[]>([]);
  const [storiesWords, setStoriesWords] = useState<string[]>([]);
  console.log("🚀 ~ upperCaseWords:", upperCaseWords);

  useMountedWaitAsyncEffect(async () => {
    const { data: wordsData } = await supabaseClient
      .from("dialog-admin")
      .select("words")
      .eq("id", "uppercase_words")
      .single();
    if (wordsData) {
      setUpperCaseWords(wordsData.words);
    }
    const { data: storiesWordsData } = await supabaseClient
      .from("dialog-admin")
      .select("words")
      .eq("id", "stories_words")
      .single();
    if (storiesWordsData) {
      setStoriesWords(storiesWordsData.words);
    }
  }, []);

  const getTextWords = (text: string) => {
    return Array.from(
      new Set(
        text
          .split(/[\s\n]+/)
          .map((w) => w.replace("’", "'"))
          .map((word) => {
            const cleanWord = removePunctuationFromString(word);
            // console.log("🚀 ~ cleanWord:", cleanWord);

            if (upperCaseWords.includes(cleanWord)) {
              return cleanWord;
            }
            return cleanWord.toLowerCase();
          })
          .filter(Boolean)
          .filter((w) => !wordsToSkip.includes(w))
          .filter((w) => !!isNaN(w as any)),
      ),
    );
  };

  useEffect(() => {
    const words = getTextWords(text);
    const newWords = words.filter((w) => !storiesWords.includes(w));

    setWords(words);
    setNewWords(newWords);
    setUpperCaseVerseWords(
      Array.from(
        new Set(
          text
            .split(/[\s\n]+/)
            .map((w) => removePunctuationFromString(w.replace("’", "'")))
            .filter((w) => !storiesWords.includes(w) && !storiesWords.includes(w.toLowerCase()))
            .filter((w) => w !== w.toLowerCase())
            .filter((w) => !upperCaseWords.includes(w)),
        ),
      ),
    );
  }, [text, upperCaseWords, storiesWords]);
  // }, [text, upperCaseWords, storiesWords]);

  const [selectedFile, setSelectedFile] = useState<any>(null);

  return (
    <MenuLayout>
      <Box sx={{ backgroundColor: "white" }} p={5} pt={40}>
        <Grid container>
          <Grid item xs={12}>
            <HStack flexWrap="nowrap">
              <VStack width={70} overflow="hidden">
                <Button
                  color="error"
                  variant="contained"
                  onClick={() => {
                    setAuthor("");
                  }}
                >
                  R
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setAuthor(text.trim());
                  }}
                >
                  T
                </Button>
              </VStack>
              <TextField
                fullWidth
                color="primary"
                value={author}
                placeholder="Author"
                multiline={true}
                onChange={(event) => {
                  setAuthor(event.target.value);
                }}
                type="text"
                variant="outlined"
              />
            </HStack>
          </Grid>
          <Grid item xs={12}>
            <HStack flexWrap="nowrap">
              <VStack width={70} overflow="hidden">
                <Button
                  color="error"
                  variant="contained"
                  onClick={() => {
                    setText("");
                  }}
                >
                  R
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setText(text.trim());
                  }}
                >
                  T
                </Button>
              </VStack>
              <TextField
                fullWidth
                color="primary"
                value={text}
                placeholder="Poem"
                multiline={true}
                onChange={(event) => {
                  setText(event.target.value);
                }}
                type="text"
                variant="outlined"
              />
            </HStack>
          </Grid>

          <Grid item xs={12}>
            <HStack flexWrap="nowrap">
              <VStack width={70} overflow="hidden">
                <Button
                  color="error"
                  variant="contained"
                  onClick={() => {
                    setLink("");
                  }}
                >
                  R
                </Button>
                <Button color="primary" variant="contained" onClick={() => {}}>
                  +
                </Button>
              </VStack>
              <TextField
                fullWidth
                color="primary"
                value={link}
                placeholder="Link"
                multiline={true}
                onChange={(event) => {
                  setLink(event.target.value.trim());
                }}
                type="text"
                variant="outlined"
              />
            </HStack>
          </Grid>
          <Grid item xs={12}>
            <HStack flexWrap="nowrap">
              <Button
                color="error"
                variant="contained"
                onClick={() => {
                  setStorageLink("");
                }}
              >
                R
              </Button>
              <TextField
                fullWidth
                color="primary"
                value={storageLink}
                placeholder="Storage Link"
                multiline={true}
                onChange={(event) => {
                  setStorageLink(event.target.value.trim());
                }}
                type="text"
                variant="outlined"
              />
            </HStack>
          </Grid>
          <Grid item xs={12}>
            <Select
              value={type}
              variant="standard"
              style={{
                height: "16px",
                fontSize: "12px",
                minHeight: 0,
                width: 300,
              }}
              onChange={(value) => setType(value.target.value.toLowerCase())}
            >
              {["song", "story", "pdf", "quote", "verse", "poem", "movie video", "video"].map(
                (w, index) => (
                  <MenuItem key={w} value={w.toLowerCase()}>
                    {w}
                  </MenuItem>
                ),
              )}
            </Select>
          </Grid>
          <Grid item xs={12}>
            {/* <Button
              onClick={() => {
                setText(text.replace(/\n\n\s/g, ""));
              }}
            >
              Remove enters
            </Button> */}
            <HStack>
              <Button variant="contained" color="error" onClick={() => {}}>
                Clear text and storage link
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  setText("");
                  setAuthor("");
                  setStorageLink("");
                  setLink("");
                }}
              >
                Clear all
              </Button>
            </HStack>
          </Grid>
          {/* {!!newWords.length && (
            <Grid item xs={12}>
              New words count: {newWords.length}
              <CopyToClipboard text={JSON.stringify(newWords)} onCopy={() => {}}>
                <Button variant="outlined">Copy words</Button>
              </CopyToClipboard>
              <Button
                onClick={async () => {
                  const updatedWords = Array.from(new Set([...newWords, ...storiesWords]));

                  await supabaseClient
                    .from("dialog-admin")
                    .update({
                      words: updatedWords,
                    })
                    .eq("id", "stories_words");
                  setStoriesWords(updatedWords);
                }}
              >
                Upload new words
              </Button>
            </Grid>
          )} */}
          {/* {!!newWords.length && (
            <Grid item xs={12}>
              <HStack>
                {newWords.map((w) => (
                  <Typography>{w}</Typography>
                ))}
              </HStack>
            </Grid>
          )} */}
          <Grid item xs={12}>
            <hr />
          </Grid>
          {!!upperCaseVerseWords.length && (
            <Grid item xs={12}>
              <HStack>
                {upperCaseVerseWords
                  .filter((w) => !newUpperCaseWords.includes(w))
                  .map((w) => (
                    <Button
                      variant={newUpperCaseWords.includes(w) ? "outlined" : "text"}
                      onClick={() => {
                        if (newUpperCaseWords.includes(w)) {
                          setNewUpperCaseWords(newUpperCaseWords.filter((i) => i !== w));
                        } else {
                          setNewUpperCaseWords([...newUpperCaseWords, w]);
                        }
                      }}
                    >
                      {w}
                    </Button>
                  ))}
              </HStack>
            </Grid>
          )}
          {!!newUpperCaseWords.length && (
            <>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  onClick={async () => {
                    const { data: wordsData } = await supabaseClient
                      .from("dialog-admin")
                      .select("words")
                      .eq("id", "uppercase_words")
                      .single();

                    if (wordsData) {
                      const { words } = wordsData;

                      const newWords = Array.from(new Set([...newUpperCaseWords, ...words]));
                      await supabaseClient
                        .from("dialog-admin")
                        .update({
                          words: newWords,
                        })
                        .eq("id", "uppercase_words")
                        .single();

                      setUpperCaseWords(newWords);
                      setNewUpperCaseWords([]);
                    }
                  }}
                >
                  Upload uppercase words
                </Button>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <input
              type="file"
              accept="video/*"
              onChange={(event) => {
                setSelectedFile(null);
                setTimeout(() => {
                  setSelectedFile(event.target.files?.[0]);
                  navigator.clipboard.writeText(event.target.files?.[0]?.name || "");
                }, 300);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={isLoading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
              onClick={async () => {
                if (type === "pdf") {
                  const [pdfName, ...restTextArr] = text.split("\n");
                  const restText = restTextArr.join("\n");
                  const words = getSentenceWords(restText);

                  await supabaseClient.from("english_verses").insert({
                    verse: restText,
                    pdf_name: pdfName,
                    words,
                    type,
                  });
                } else if (type === "poem") {
                  try {
                    setIsLoading(true);
                    const result = await supabaseClient.from("poems").insert({
                      text,
                      author,
                      words,
                      link,
                    });
                    console.log("🚀 ~ result:", result);
                    setText("");
                    setAuthor("");
                    // const newLink = getNewLink(link);
                    setLink("");
                    setIsLoading(false);
                  } catch (err: any) {
                    alert(err.message);
                  }
                  setIsLoading(false);
                } else {
                  try {
                    setIsLoading(true);
                    const result = await supabaseClient.from("english_verses").insert({
                      verse: text,
                      words,
                      link,
                      type,
                      storage_link: storageLink,
                    });
                    console.log("🚀 ~ result:", result);
                    setIsLoading(false);
                  } catch (err: any) {
                    alert(err.message);
                  }
                  setIsLoading(false);
                }
              }}
            >
              Save
            </LoadingButton>
          </Grid>
          <Grid
            item
            sx={{
              position: "fixed",
              top: 0,
              right: 0,
              zIndex: 10000,
            }}
          >
            {selectedFile && !storageLink && (
              // <video ref={videoRef} width="640" height="360" controls>
              <video width="300" height="160" controls>
                <source src={URL.createObjectURL(selectedFile)} type={selectedFile.type} />
                Your browser does not support the video tag.
              </video>
            )}
            {storageLink && (
              // <video ref={videoRef} width="640" height="360" controls>
              <video width="300" height="160" controls>
                <source src={storageLink} type={"video/mp4"} />
                Your browser does not support the video tag.
              </video>
            )}
          </Grid>
        </Grid>
      </Box>
    </MenuLayout>
  );
};

export default AdminAddVerse;

import { Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { usePrevious } from "react-use";
import formatLetterWithSound from "../../../../common/formatLetterWithSound";
import Box from "../../../Unknown/Box";
import Button from "../../../Unknown/Button";
import Drawer from "../../../Unknown/Drawer";
import ModalCloseButton from "../../../Unknown/ModalCloseButton";
import CheckAnim from "../CheckAnim";
import ReactPlayer from "react-player";
import useGlobalWords from "../../../hooks/getGlobalWords";
import { useLessonsStore } from "../../../../store/lessonsStore";
import { Word } from "../../../../types/supabase";
import HStack from "../../../Unknown/HStack";
import { lastDayOfMonth } from "date-fns";
import ItalicTitle from "../../../Title/ItalicTitle";
import VStack from "../../../Unknown/VStack";
import WordAudio from "../../../Audio/WordAudio";

const drawerContentStyle = {
  height: "100vh",
  width: "100vw",
  overflowX: "hidden",
};

const soundsConfig: Record<
  string,
  {
    videoLinks: {
      link: string;
      title: string;
    }[];
    words: string[];
  }
> = {
  "Oo(ɒ)": {
    videoLinks: [
      {
        link: "https://www.youtube.com/watch?v=bMAOfn7zKQY",
        title: "",
      },
    ],
    words: ["not", "dot", "doll"],
  },
  Nn: {
    videoLinks: [
      {
        link: "https://www.youtube.com/watch?v=9Qa3eH-T-XA",
        title: "",
      },
    ],
    words: ["not", "den", "end", "ten"],
  },
  Tt: {
    videoLinks: [
      {
        link: "https://www.youtube.com/watch?v=wFhn0aK---I",
        title: "",
      },
    ],
    words: ["not", "little", "dot", "belt", "ten"],
  },
  "Ee(e)": {
    videoLinks: [
      {
        link: "https://www.youtube.com/watch?v=no6h_obC5Gw",
        title: "",
      },
    ],
    words: ["bell", "den", "belt", "ten"],
  },
  Bb: {
    videoLinks: [
      {
        link: "https://www.youtube.com/watch?v=efzMufer1Ls",
        title: "",
      },
    ],
    words: ["bell", "bin", "belt", "bell", "bed"],
  },
  Dd: {
    videoLinks: [
      {
        link: "https://www.youtube.com/watch?v=RAQLzAUx5tE",
        title: "",
      },
    ],
    words: ["den", "doll", "dot", "end", "bed"],
  },
  Ll: {
    videoLinks: [
      {
        link: "https://www.youtube.com/watch?v=h39rL-i6-94",
        title: "",
      },
    ],
    words: ["little", "doll", "belt"],
  },
  "Ii(i)": {
    videoLinks: [
      {
        link: "https://www.youtube.com/watch?v=xikVnq0LZQg",
        title: "",
      },
    ],
    words: ["little", "bin"],
  },
  Pp: {
    videoLinks: [
      {
        link: "https://www.youtube.com/watch?v=1v8O5kAiJ5s",
        title: "",
      },
    ],
    words: ["pet", "pen", "lip", "top", "zip"],
  },
  "Ss(s)": {
    videoLinks: [
      {
        link: "https://www.youtube.com/watch?v=ebkwRAl2HXM",
        title: "",
      },
    ],
    words: ["dots", "pets", "tennis", "nest", "best"],
  },
  "Ss(z)": {
    videoLinks: [
      {
        link: "https://youtube.com/watch?v=xKc9uBqmFyw",
        title: "",
      },
    ],
    words: ["bells", "dolls", "pens", "beds", "bins"],
  },
  Zz: {
    videoLinks: [
      {
        link: "https://youtube.com/watch?v=xKc9uBqmFyw",
        title: "",
      },
    ],
    words: ["zip"],
  },
  "Aa(æ)": {
    videoLinks: [
      {
        link: "https://www.youtube.com/watch?v=PMDAAcIWTNg",
        title: "",
      },
    ],
    words: ["apple", "battle", "pineapple", "hat", "have", "van"],
  },
  Vv: {
    videoLinks: [
      {
        link: "https://www.youtube.com/watch?v=grv5R0COTGI",
        title: "",
      },
    ],
    words: ["van", "have"],
  },
  Hh: {
    videoLinks: [
      {
        link: "https://www.youtube.com/watch?v=na5MYk1OcmU",
        title: "",
      },
    ],
    words: ["have", "hat", "hats"],
  },
  Kk: {
    videoLinks: [
      {
        link: "https://www.youtube.com/watch?v=5ASLaxjH6Kg",
        title: "",
      },
    ],
    words: ["like", "desk", "disk"],
  },
  Mm: {
    videoLinks: [
      {
        link: "https://www.youtube.com/watch?v=Y0zR28ogfrA",
        title: "",
      },
    ],
    words: ["map", "lime", "milk", "time", "lamb"],
  },
  Gg: {
    videoLinks: [
      {
        link: "https://www.youtube.com/watch?v=WV9pDWY-1dw",
        title: "",
      },
    ],
    words: ["dog", "pig", "big"],
  },
  Ff: {
    videoLinks: [
      {
        link: "https://www.youtube.com/watch?v=RJ_fB6RZmLA",
        title: "",
      },
    ],
    words: ["five", "life"],
  },
  "Cc(k)": {
    videoLinks: [
      {
        link: "https://www.youtube.com/watch?v=5ASLaxjH6Kg",
        title: "",
      },
    ],
    words: ["cat", "cook", "can"],
  },
  Rr: {
    videoLinks: [
      {
        link: "https://www.youtube.com/watch?v=PBL9hmzpv1w",
        title: "",
      },
    ],
    words: ["tree", "root", "ran", "red", "rose", "room", "dinner", "green"],
  },
  "Oo(əʊ)": {
    videoLinks: [
      {
        link: "https://youtu.be/Jj8QhWUbkG8?si=ty1fnTYF3JWGgfTO",
        title: "",
      },
    ],
    words: ["go", "rose", "note", "notebook"],
  },
  "oo(uː)": {
    videoLinks: [
      {
        link: "https://youtu.be/-2aYdAI0kJ0?si=crd3lE1jjSzNOyXg",
        title: "",
      },
    ],
    words: ["root", "goose", "room", "bedroom"],
  },
  "oo(ʊ)": {
    videoLinks: [
      {
        link: "https://youtu.be/nLJpY79l1lQ?si=RfwLNFTTrnU7KLZK",
        title: "",
      },
    ],
    words: ["cook", "look", "took"],
  },
  "th(ð)": {
    videoLinks: [
      {
        link: "https://youtu.be/irCa7f7tKKQ?si=nt9EVy4g2lM6CiEi",
        title: "",
      },
    ],
    words: ["this", "the", "there", "then"],
  },
  "ee(i:)": {
    videoLinks: [
      {
        link: "https://www.youtube.com/watch?v=xikVnq0LZQg",
        title: "",
      },
    ],
    words: ["see", "tree", "need", "green", "geese"],
  },
  "ea(i:)": {
    videoLinks: [
      {
        link: "https://www.youtube.com/watch?v=xikVnq0LZQg",
        title: "",
      },
    ],
    words: ["tea", "please", "speak", "eat"],
  },
  "j(dʒ)": {
    videoLinks: [
      {
        link: "https://youtu.be/GXo0rhBTMxE?si=a_RdmSTRFEJ-QhEj",
        title: "",
      },
    ],
    words: ["Jan", "John", "jam", "jeep"],
  },
  "sh(ʃ)": {
    videoLinks: [
      {
        link: "https://youtu.be/VLfQ4baJJX0?si=6cweuvs7LY-BK0cu",
        title: "",
      },
    ],
    words: ["she", "sheep", "trash", "shop"],
  },
  Ww: {
    videoLinks: [
      {
        link: "https://youtu.be/TV3zxFIDuIY?si=U9sVBagiQwP2Kt2l",
        title: "",
      },
    ],
    words: ["we", "winter", "where", "wood"],
  },
  "Ee(ɪ)": {
    videoLinks: [
      {
        link: "https://youtu.be/SuD7IKswe1s?si=Ia3RCf8NAiSt8Iqx",
        title: "",
      },
    ],
    words: ["we", "he", "be", "she"],
  },
  "Uu(ʌ)": {
    videoLinks: [
      {
        link: "https://www.youtube.com/watch?v=BLKeRAMtj9M",
        title: "",
      },
    ],
    words: ["cup", "understand", "summer", "run"],
  },
  "Yy(i)": {
    videoLinks: [
      {
        link: "https://www.youtube.com/watch?v=xikVnq0LZQg",
        title: "",
      },
    ],
    words: ["hobby", "every", "Mary"],
  },
  "Yy(j)": {
    videoLinks: [
      {
        link: "https://youtu.be/b7Ycy4hSueY?si=Yeu3Lribk3zDQl9y",
        title: "",
      },
    ],
    words: ["yes", "yard"],
  },
  "ay(eɪ)": {
    videoLinks: [
      {
        link: "https://youtu.be/HY8ykAgzILA?si=B_vPlog_0F9t0iDe",
        title: "",
      },
    ],
    words: ["day", "play"],
  },
  "oy(ɔɪ)": {
    videoLinks: [
      {
        link: "https://youtu.be/GmZqHLkiZv0?si=pmLl8Dxr9zhpsr5r",
        title: "",
      },
    ],
    words: ["boy"],
  },
};
const CONSONANTS = [
  "Nn",
  "Tt",
  "Dd",
  "Bb",
  "Ll",
  "Pp",
  "Ss(s)",
  "Ss(z)",
  "Zz",
  "Hh",
  "Kk",
  "Vv",
  "Gg",
  "Mm",
  "Ff",
  "Rr",
  "Cc(k)",
  "th(ð)",
  "sh(ʃ)",
  "j(dʒ)",
  "Ww",
  "ng(ŋ)",
  // "y(й)",
  "Yy(j)",
];

const VOWELS = [
  "Oo(ɒ)",
  "Ee(e)",
  "Ii(i)",
  "Aa(æ)",
  "ee(i:)",
  "oo(uː)",
  "ea(i:)",
  "oo(ʊ)",
  "Ee(ɪ)",
  "Oo(əʊ)",
  "Aa(ɑː)",
  "Aa(eɪ)",
  "ay(eɪ)",
  "oy(ɔɪ)",
  // "Oo(uː)",
  "ou(uː)",
  "ow(aʊ)",
  "Ii(aɪ)",
  "Yy(i)",
  "Uu(ʌ)",
];

const removeUppercase = (str: string) => {
  // const splitStr = str.split("(").filter(Boolean);
  // const [letter] = splitStr;
  if (str[0] === str[0].toLowerCase()) return str;
  return str.slice(1);
  // if (splitStr.length > 1) {
  // } else {

  // }
};

const EnglishSoundsGrid: React.FC<{ sounds: string[]; newSounds: string[] }> = ({
  sounds,
  newSounds,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [letterIndex, setLetterIndex] = useState(0);
  const [curerntModuleWordsMap, setCurrentModuleWordsMap] = useState<Record<string, Word>>({});

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  const options = useMemo(() => {
    if (!isOpen) return { words: [] };
    return {
      words: Array.from(
        new Set(
          Object.values(soundsConfig)
            .map((i) => i.words)
            .flat(),
        ),
      ),
    };
  }, [isOpen]);

  useGlobalWords(options);

  const globalWords = useLessonsStore((state) => state.globalWords);

  useEffect(() => {
    if (options.words.length) {
      const map = globalWords
        .filter(
          (w) => options.words.includes(w.name) || options.words.includes(w.name.toLowerCase()),
        )
        .reduce((acm, w) => {
          return {
            ...acm,
            [w.name]: w,
            [w.name.toLowerCase()]: w,
          };
        }, {});

      setCurrentModuleWordsMap(map);
    }
  }, [globalWords, options.words]);

  const getStyles = (item: string, isSelected: boolean) => {
    let bg = "gray";

    if (!isSelected && sounds.includes(item)) {
      if (CONSONANTS.map((s) => s).includes(item)) {
        // bg = "rgba(33, 150, 243, 1)";
        bg = "rgb(102 136 133)";
      } else if (VOWELS.map((s) => s).includes(item)) {
        // bg = "rgb(41 167 41)";
        bg = "rgb(102 136 133)";
      }
    } else if (isSelected) {
      bg = "rgb(41 167 41)";
    }

    return {
      pointerEvents: sounds.includes(item) ? "auto" : "none",
      opacity: sounds.includes(item) ? 1 : 0.3,
      padding: "2px 10px",
      minWidth: 60,
      textAlign: "center",
      color: "white",
      borderRadius: "8px",
      userSelect: "none",
      WebkitUserSelect: "none",
      touchAction: "none",
      fontSize: 20,
      fontWeight: "bold",
      margin: "2px",
      display: "inline-block",
      zIndex: 1,
      position: "relative",
      cursor: "pointer",
      backgroundColor: bg,
      border: newSounds.includes(item) ? "3px solid rgb(255 138 8)" : "none",
    };
  };

  const [selectedLetter, setSelectedLetter] = useState("");

  return (
    <Box>
      <Button variant="outlined" onClick={() => setIsOpen(true)}>
        Літери та звуки
      </Button>

      <Drawer
        open={isOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 9,
        }}
      >
        <Box sx={drawerContentStyle}>
          <ModalCloseButton onClose={handleDrawerClose} />

          <Box
            sx={{
              display: "flex",
              // justifyContent:
            }}
          >
            <Box
              sx={{
                p: 1,
                width: 100,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              {CONSONANTS.sort((a, b) => (newSounds.includes(a) ? -1 : 1)).map((item) => (
                <Box
                  sx={{
                    ...getStyles(item, selectedLetter === item),
                  }}
                  key={item}
                  onClick={() => {
                    setSelectedLetter(item);
                  }}
                >
                  {/* <WordAudio boxHide url={`/audio/en_alphabet/${item.content}.mp3`} /> */}
                  {formatLetterWithSound(item)}
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                flex: "1",
              }}
            >
              <Box py={4}>
                {!selectedLetter && (
                  <Box
                    sx={{
                      py: 10,
                      textAlign: "center",
                    }}
                  >
                    <Typography variant="h3">Вибери літеру</Typography>
                  </Box>
                )}
                {selectedLetter && (
                  <Box>
                    {soundsConfig[selectedLetter]?.videoLinks?.map((item) => {
                      let title: any = "";

                      if (!item.title) {
                        title = (
                          <Typography variant="h2" gutterBottom display="flex">
                            <ItalicTitle size={25} text="Вимова звуку" />
                            <Typography
                              variant="h2"
                              component="span"
                              color="primary"
                              display="flex"
                              alignItems="center"
                            >
                              &nbsp;{formatLetterWithSound(selectedLetter)}
                            </Typography>
                          </Typography>
                        );
                      }

                      return (
                        <>
                          {title}
                          <Box
                            sx={{
                              position: "relative",
                              width: "100%",
                              pb: "52.5%",
                            }}
                          >
                            <ReactPlayer
                              controls
                              width="100%"
                              height="100%"
                              loop
                              style={{
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                top: 0,
                                left: 0,
                              }}
                              url={item.link}
                            />
                          </Box>
                        </>
                      );
                    })}
                    <Box py={4}>
                      <ItalicTitle size={25} text="Приклади в словах" />

                      <VStack gap={1} alignItems="flex-start">
                        {soundsConfig[selectedLetter]?.words?.map((item) => {
                          return (
                            <HStack>
                              {curerntModuleWordsMap[item]?.audio_url && (
                                <WordAudio small url={curerntModuleWordsMap[item].audio_url} />
                              )}
                              <Typography>
                                {item.split("").map((l, index) => {
                                  const [cleanLetter] = selectedLetter
                                    .split("(")
                                    .map((i) => i.trim());
                                  if (l === cleanLetter) {
                                    return (
                                      <Typography
                                        key={index}
                                        variant="h3"
                                        component="span"
                                        color="#356910"
                                      >
                                        {l}
                                      </Typography>
                                    );
                                  }
                                  return (
                                    <Typography key={index} variant="h3" component="span">
                                      {l}
                                    </Typography>
                                  );
                                })}
                              </Typography>
                              <Typography>{curerntModuleWordsMap[item]?.transcription}</Typography>
                            </HStack>
                          );
                        })}
                      </VStack>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                p: 1,
                width: 100,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {VOWELS.sort((a, b) => (newSounds.includes(a) ? -1 : 1)).map((item) => (
                <Box
                  sx={getStyles(item, selectedLetter === item)}
                  key={item}
                  onClick={() => {
                    setSelectedLetter(item);
                  }}
                >
                  {/* <WordAudio boxHide url={`/audio/en_alphabet/${item.content}.mp3`} /> */}
                  {formatLetterWithSound(item)}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default EnglishSoundsGrid;

import isAdmin from "../../../common/isAdmin";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import AppsIcon from "@mui/icons-material/Apps";
import { useLessonsStore } from "../../../store/lessonsStore";
import Box from "../Box";
import { Tooltip } from "@mui/material";
import PageTranslationWithInputText from "../PageTranslationWithInputText";
import { useState } from "react";

const AdminTranslateWithCambridge: React.FC<{ title: string }> = ({ title }) => {
  const authUser = useLessonsStore((state) => state.authUser);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  if (!isAdmin(authUser?.id)) return null;

  return (
    <Box
      sx={{
        cursor: "pointer",
      }}
      onClick={() => {
        setIsPopupOpen(true);
      }}
    >
      <PageTranslationWithInputText
        text={title}
        type="cambridge"
        isPopupOpen={isPopupOpen}
        setIsPopupOpen={setIsPopupOpen}
      />
      <GTranslateIcon sx={{ color: "orange" }} />
    </Box>
  );
};

export default AdminTranslateWithCambridge;

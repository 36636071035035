import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import checkNewWords from "../../common/checkNewWords";
import getSentenceWords from "../../common/getSentenceWords";
import removePunctuationFromString from "../../common/removePunctuationFromString";
import supabaseClient from "../../common/supabaseClient";
import upperCaseWords from "../../common/uppercaseWords";
import useCreateAudio from "../../common/useCreateAudio";
import { useLessonsStore } from "../../store/lessonsStore";
import CopyNewWordsForGemini from "../Admin/CopyNewWordsForGemini";
import AdminCreateSentenceFields from "../AdminCreateSentenceFields";
import createNewNotExistingSentenceFromRowSentences from "../CreatePersonalLesson/createNewNotExistingSentenceFromRowSentences";
import getExistingAndNotExistingSentences from "../CreatePersonalLesson/getExistingAndNotExistingSentences";
import GrammarSentences from "../GrammarSentences";
import MenuLayout from "../Layout/MenuLayout";
import HStack from "../Unknown/HStack";
import { useFirebaseApp } from "reactfire";
import { getFunctions, httpsCallable } from "firebase/functions";
import AiAskSentences from "../AiAskSentences";
import AiAskSentencesNaturalness from "../AiAskSentencesNaturality";
import AiAskTranslation from "../AiAskTranslation";
import useMountedWaitAsyncEffect from "../../hooks/useMountedWaitAsyncEffect";
import FindNotExistingWords from "./FindNotExistingWords";

const courseWords = {
  1: "I, speak, English, you, we, they, he, speaks, she, know, knows, do, does, don't, doesn't, study, in, school, studies, and, work, works, every, day, it, learn, learns, very, well, go, to, today",
};

// const newModuleWords = ["library", "hospital", "yes", "okay"];
const newModuleWords = ["calls"];

const skipWords = ["/", "not", "1–10"];

// play, yes, thank, no, sorry, alright

const AdminCreateGrammarCourse: React.FC = () => {
  const [text, setText] = useState("");
  const [topic, setTopic] = useState("");
  const [setnences, setSetnences] = useState<string[]>([]);
  const [newWords, setNewWords] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [words, setWords] = useState<string[]>([]);
  const { createAudio } = useCreateAudio();
  const [knowWordsInTheTopic, setKnowWordsInTheTopic] = useState<string[]>([]);
  const [newWordsInTheTopic, setNewWordsInTheTopic] = useState<string[]>([]);
  const [enValue, setEnValue] = useState("");
  const [wordsMap, setWordsMap] = useState<Record<string, number>>({});
  const [module, setModule] = useState(0);
  const [uaValue, setUaValue] = useState("");
  const [meaningValue, setMeaningValue] = useState("");
  const [currentModuleWords, setCurrentModuleWords] = useState<string[]>([]);

  useMountedWaitAsyncEffect(async () => {
    if (!module) {
      const { data } = await supabaseClient
        .from("grammar_course")
        .select("module")
        .order("module", { ascending: false })
        .limit(1)
        .single();

      if (data?.module) {
        setModule(data.module + 1);
      }
    }
  }, [module]);

  useEffect(() => {
    const allWords = getSentenceWords(enValue);

    const wordsMap = enValue
      .split(/[\s\n]/)
      .map((w) => removePunctuationFromString(w))
      .map((w) => {
        if (!upperCaseWords.includes(w)) {
          return w.toLowerCase();
        }
        return w;
      })
      .reduce<Record<string, number>>((acc, word) => {
        if (!newModuleWords.includes(word)) return acc;
        return {
          ...acc,
          [word]: (acc[word] || 0) + 1,
        };
      }, {});
    const uniqueWords = Array.from(new Set(allWords)).filter(Boolean);
    setWordsMap(wordsMap);
    setWords(uniqueWords);
  }, [enValue]);

  const authUser = useLessonsStore((state) => state.authUser);

  const getTextWords = (text: string) => {
    return Array.from(
      new Set(
        text
          .split(/[\s\n]+/)
          .map((word) => {
            if (upperCaseWords.includes(word)) {
              return removePunctuationFromString(word);
            }
            return removePunctuationFromString(word).toLowerCase();
          })
          .filter(Boolean),
      ),
    );
  };

  const getCurrentModuleWords = async () => {
    const { data } = await supabaseClient.from("grammar_course").select("words");
    const allModulesWords = data!.map((m) => m.words).flat();
    console.log("🚀 ~ allModulesWords:", allModulesWords);

    const newWords = words.filter((w) => !allModulesWords.includes(w) && !skipWords.includes(w));
    console.log("🚀 ~ newWords:", newWords);

    navigator.clipboard.writeText(JSON.stringify(newWords));

    return newWords;
  };

  const [storiesIds, setStoriesIds] = useState<number[]>([]);
  const [description, setDescription] = useState<string>("");
  const [learntRowWords, setLearntRowWords] = useState<string[]>([]);

  const app = useFirebaseApp();
  const functions = getFunctions(app, "europe-west3");
  const askOpenAi = httpsCallable(functions, "askOpenAiFunction");

  return (
    <MenuLayout isFluid>
      <Box p={5} sx={{ backgroundColor: "white" }}>
        <Grid container>
          <HStack>
            {!!words.length && (
              <HStack>
                <CopyToClipboard
                  text={`
                    Here are words: ${words.join(", ")}

                    1. Create some simple text of A1-A2 English level to train these word in speaking. It is for English student.
                    Use these words as much as possible. It will be very good if you give me a topic text to cover all these words. Also use other words as much as possible.

                    2. Maybe there is some verse or poem, maybe for children to cover these words as much as possible.

                    So I need the topic text and verse (or poem) if it exists.
                  `}
                  onCopy={() => {}}
                >
                  <Button variant="outlined" color="info" size="small">
                    AI Simple text
                  </Button>
                </CopyToClipboard>
                <CopyToClipboard text={words.join(", ")} onCopy={() => {}}>
                  <Button variant="outlined" color="info" size="small">
                    all words (string)
                  </Button>
                </CopyToClipboard>
                <CopyToClipboard text={JSON.stringify(words, null, 2)} onCopy={() => {}}>
                  <Button variant="outlined" color="info" size="small">
                    all words (json)
                  </Button>
                </CopyToClipboard>
              </HStack>
            )}
            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="warning"
              size="small"
              onClick={async () => {
                setIsLoading(true);

                console.log("🚀 ~ newModuleWords:", newModuleWords);
                const deployResult = await Promise.all(
                  newModuleWords.map((w: string) => {
                    return supabaseClient.from("user-words").insert({
                      user: "7dd6fd1b-a333-4fbf-98a7-245491416070",
                      id: `7dd6fd1b-a333-4fbf-98a7-245491416070-${w}`,
                      word: w,
                    });
                  }),
                );
                // console.log("🚀 ~ deployResult:", deployResult);

                setIsLoading(false);
              }}
            >
              Deploy words
            </LoadingButton>
          </HStack>
          <Grid item xs={12}>
            Words count: {words.length}
          </Grid>
          <HStack>
            <GrammarSentences />
            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="error"
              size="small"
              onClick={async () => {
                setIsLoading(true);

                const { data: userWords } = await supabaseClient
                  .from("user-words")
                  .select()
                  .eq("user", "7dd6fd1b-a333-4fbf-98a7-245491416070");

                const rowUserWords: string[] = Array.from(new Set(userWords?.map((w) => w.word)));

                const { data: grammarCourseWords } = await supabaseClient
                  .from("grammar_course")
                  .select();

                const rowGrammarCourseWords = Array.from(
                  new Set(grammarCourseWords?.map((i) => i.words).flat()),
                );

                const wrongUserWords = rowUserWords?.filter(
                  (w) => !rowGrammarCourseWords?.includes(w),
                );

                const notExistingUserWords = rowGrammarCourseWords?.filter((w) => {
                  if (w === "tv") return false;
                  return !rowUserWords?.includes(w);
                });
                console.log("🚀 ~ notExistingUserWords:", notExistingUserWords);

                if (notExistingUserWords.length > 0) {
                  await Promise.all(
                    notExistingUserWords.map((w: string) => {
                      return supabaseClient.from("user-words").insert({
                        user: "7dd6fd1b-a333-4fbf-98a7-245491416070",
                        id: `7dd6fd1b-a333-4fbf-98a7-245491416070-${w}`,
                        word: w,
                      });
                    }),
                  );
                }

                navigator.clipboard.writeText(JSON.stringify(wrongUserWords));

                await Promise.all(
                  wrongUserWords.map((w) =>
                    supabaseClient.from("user-words").delete().eq("word", w),
                  ),
                );

                setIsLoading(false);
              }}
            >
              Check wrong user words
            </LoadingButton>
            <br />

            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="info"
              size="small"
              onClick={async () => {
                setIsLoading(true);

                const currentWords = await getCurrentModuleWords();
                // const { data } = await supabaseClient.from("user-words").select("word").eq("user", "7dd6fd1b-a333-4fbf-98a7-245491416070");
                const { data } = await supabaseClient.from("grammar_course").select("words");

                if (data) {
                  navigator.clipboard.writeText(`
                  
                  
                  Give me 50 simple sentences with main words: ${currentWords}
                  
                  For building sentences use only main and next words:
                  ${JSON.stringify(Array.from(new Set(data.map((i) => i.words).flat())))}

                  Important: each sentence must contains at least one word from the main words list!!!
                  `);
                }

                setNewWords(newWords);
                setIsLoading(false);
              }}
            >
              Ask ai about new words sentences
            </LoadingButton>
            <br />
            <LoadingButton
              loading={isLoading}
              size="small"
              variant="outlined"
              color="info"
              onClick={async () => {
                setIsLoading(true);
                const { data } = await supabaseClient
                  .from("user-words")
                  .select("word")
                  .eq("user", "7dd6fd1b-a333-4fbf-98a7-245491416070");

                if (data) {
                  navigator.clipboard.writeText(data.map((i) => i.word).join(", "));
                }

                setNewWords(newWords);
                setIsLoading(false);
              }}
            >
              Copy all course words
            </LoadingButton>
            {!!newWords.length && <CopyNewWordsForGemini words={newWords} />}
            <Box width="100%"></Box>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="info"
              onClick={async () => {
                setIsLoading(true);
                const { words: returnWords, newWords } = await checkNewWords(words);
                console.log("🚀 ~ newWords:", newWords);
                console.log("🚀 ~ returnWords:", returnWords);

                setNewWords(newWords.filter((w) => !skipWords.includes(w)));
                setIsLoading(false);
              }}
            >
              Check new words
            </LoadingButton>

            <LoadingButton
              loading={isLoading}
              loadingPosition="start"
              variant="contained"
              onClick={async () => {
                try {
                  const userUuid = authUser?.uuid;

                  if (!userUuid) return;

                  setIsLoading(true);

                  const rowEnSentences = enValue
                    .split("\n")
                    .map((s) => s.trim())
                    .filter(Boolean);

                  const { notExistingSentences } = await getExistingAndNotExistingSentences({
                    currentUserUuid: userUuid,
                    textFieldMultipleString: enValue,
                    textFiledTranslationMultipleString: uaValue,
                    meanings: meaningValue,
                  });

                  console.log("notExistingSentences", notExistingSentences);

                  const sentencesToInsert = await createNewNotExistingSentenceFromRowSentences({
                    notExistingSentences: notExistingSentences,
                    createAudio,
                  });

                  console.log("sentencesToInsert", sentencesToInsert);

                  // try {
                  // } catch (err) {
                  //   console.log("🚀 ~ err:", err);
                  // }
                  const uploadNewSetnencesResult = await Promise.all(
                    sentencesToInsert.map((s) => supabaseClient.from("sentences").insert(s)),
                  );
                  console.log("🚀 ~ uploadNewSetnencesResult:", uploadNewSetnencesResult);
                  // }

                  const writeWords = await getCurrentModuleWords();

                  await Promise.all(
                    rowEnSentences.map((sentence, index) =>
                      supabaseClient.from("grammar_course_sentences").insert({
                        id: sentence,
                        sentence,
                        module: module,
                        order: index,
                      }),
                    ),
                  );

                  const { data: lastLesson } = await supabaseClient
                    .from("grammar_course")
                    .select()
                    .order("module", {
                      ascending: false,
                    })
                    .limit(1)
                    .single();

                  await supabaseClient
                    .from("grammar_course")
                    .insert({
                      module: module,
                      // sentence_count: rowEnSentences.length,
                      title: `Урок ${module}`,
                      words,
                      description,
                      write_words: writeWords,
                      new_words_count: writeWords.length,
                      words_count: lastLesson.words_count + writeWords.length,
                      is_video: true,
                      stories: storiesIds,
                    })
                    .throwOnError();

                  setModule((prev) => prev + 1);
                  setDescription("");
                  setStoriesIds([]);
                } catch (err) {
                  console.log("🚀 ~ err:", err);
                }

                setIsLoading(false);
              }}
            >
              Upload sentences
            </LoadingButton>
          </HStack>
          <Grid item xs={12} gap={4} display="flex" flexWrap="wrap">
            <Button
              onClick={() => {
                navigator.clipboard.writeText(`
Translate the following text to Ukrainian language. The response is a list of Ukrainian language strings without quotes at the beginning and at the end. Each translated sentence starts on the new line.
${enValue}`);
              }}
            >
              Copy for gemini translation
            </Button>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(`
                Чи є в наступних реченнях сталі словосполучення?
                Чи є в наступних реченнях сталі вирази?
                Чи є в наступних реченнях фразові дієслова?
                Чи всі ці речення звучать природньо?
                Чи всі ці речення граматично правильні?
                                
${enValue}`);
              }}
            >
              (ai) Чи є сталі вирази, фразові дієслова
            </Button>
            <TextField
              color="primary"
              value={module}
              multiline={true}
              placeholder="Module"
              onChange={(event) => {
                const module = event.target.value;

                setModule(+module);
              }}
              type="text"
              variant="outlined"
            />
            <TextField
              color="primary"
              value={storiesIds.join(",")}
              multiline={true}
              placeholder="Stories Ids"
              onChange={(event) => {
                const module = event.target.value;

                setStoriesIds(module.split(",").map((s) => Number(s.trim())));
              }}
              type="text"
              variant="outlined"
            />
            <TextField
              color="primary"
              value={description}
              multiline={true}
              placeholder="Description"
              onChange={(event) => {
                const module = event.target.value;

                setDescription(module);
              }}
              type="text"
              variant="outlined"
            />
            <FindNotExistingWords />
            <HStack>
              {Object.entries(wordsMap)
                .sort((a, b) => a[1] - b[1])
                .map(([word, number]) => {
                  return (
                    <span>
                      {word} ({number}),
                    </span>
                  );
                })}
            </HStack>
            <AdminCreateSentenceFields
              columnSize={500}
              uaValue={uaValue}
              enValue={enValue}
              onUpdateValues={(en, ua, meaning) => {
                setEnValue(en);
                setUaValue(ua);
                setMeaningValue(meaning);
              }}
            />
            <LoadingButton
              loading={isLoading}
              variant="outlined"
              size="small"
              color="info"
              onClick={async () => {
                setIsLoading(true);
                const words = await getCurrentModuleWords();

                setCurrentModuleWords(words);

                setIsLoading(false);
              }}
            >
              Get current module words
            </LoadingButton>
            <AiAskTranslation
              text={enValue}
              onTranslated={(text) => {
                setUaValue(text);
              }}
            />
            <HStack>
              <LoadingButton
                loading={isLoading}
                size="small"
                variant="outlined"
                color="error"
                onClick={async () => {
                  setIsLoading(true);
                  const { data } = await supabaseClient.from("grammar_course").select("words");
                  const allModulesWords = data!.map((m) => m.words).flat();

                  navigator.clipboard.writeText(allModulesWords.join(", "));
                  setLearntRowWords(allModulesWords);

                  setIsLoading(false);
                }}
              >
                learnt row words
              </LoadingButton>
              <AiAskSentences
                newWords={currentModuleWords}
                learntRowWords={learntRowWords}
                onAddSentence={(sentence) => {
                  setEnValue((text) => `${text}\n${sentence}`);
                }}
              />
            </HStack>
            <AiAskSentencesNaturalness text={enValue} />
            {/* <TextField
              fullWidth
              color="primary"
              value={text}
              multiline={true}
              placeholder="English sentences"
              onChange={(event) => {
                const text = event.target.value;

                const sentences = text
                  .split(/[\n]+/)
                  .map((sentence) => sentence.trim())
                  .filter(Boolean);

                setSetnences(sentences);

                const words = getTextWords(text);

                setWords(Array.from(new Set(words)));
                setText(text);
              }}
              type="text"
              variant="outlined"
            /> */}
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              fullWidth
              color="primary"
              value={topic}
              multiline={true}
              placeholder="Topic"
              onChange={(event) => {
                setTopic(event.target.value);

                const cWords = Object.values(courseWords)
                  .map((words) => words.split(", "))
                  .flat();
                console.log("🚀 ~ cWords:", cWords);
                const topicWords = getTextWords(topic);
                console.log("🚀 ~ topicWords:", topicWords);
                setKnowWordsInTheTopic(topicWords.filter((w) => cWords.includes(w)));
                setNewWordsInTheTopic(topicWords.filter((w) => !cWords.includes(w)));
              }}
              type="text"
              variant="outlined"
            />
          </Grid> */}
          {/* {!!knowWordsInTheTopic.length && (
            <Grid item xs={12}>
              Topic known words count: {knowWordsInTheTopic.length}
            </Grid>
          )} */}
          {/* {!!newWordsInTheTopic.length && (
            <Grid item xs={12}>
              Topic new words count: {newWordsInTheTopic.length}
            </Grid>
          )} */}
          {/* {!!newWordsInTheTopic.length && (
            <Grid item xs={12}>
              New words:{" "}
              {newWordsInTheTopic.map((w) => (
                <Typography p={1}>{w}</Typography>
              ))}
            </Grid>
          )} */}
        </Grid>
      </Box>
    </MenuLayout>
  );
};

export default AdminCreateGrammarCourse;

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { PropsWithChildren, useMemo, useState } from "react";
import AccentWord from "../../../Difference/components/AccentWord";
import Box from "../../Box";
import { RuleType } from "../../Root/grammarMap";

export const differenceWords: RuleType[] = [
  {
    name: "Різниця між словами",
    rules: [
      {
        title: "climb / grow",
        description: [
          <Typography component="span">
            Різниця у значенні:{" "}
            <Typography component="span" fontWeight={700}>
              зростати, підніматися
            </Typography>
          </Typography>,
        ],
        lesson: [5000],
        examples: [
          <>
            <AccentWord word="Climb" /> a ladder.
            <br />
            <AccentWord word="Climb" /> the corporate ladder.
            <br />
            <AccentWord word="Climb" /> Mount Everest.
            <br />
          </>,
          <>
            <AccentWord word="Grow" /> a beard.
            <br />
            <AccentWord word="Grow" /> old.
            <br />
            <AccentWord word="Grow" /> a business.
            <br />
            <AccentWord word="Grow" /> in confidence.
          </>,
        ],
      },
      {
        title: "like / as",
        description: [
          <Typography component="span">
            Різниця у значенні:{" "}
            <Typography component="span" fontWeight={700}>
              як
            </Typography>
          </Typography>,
        ],
        lesson: [5000],
        examples: [
          <>
            <Typography component="span" fontWeight={700}>
              Використовується для порівняння.
            </Typography>
            <Typography component="span" fontWeight={700}>
              Використовується для перерахунку. Можна замінити словом "наприклад"
            </Typography>
            <br />
            <AccentWord word="Climb" /> a ladder.
            <br />
            <AccentWord word="Climb" /> the corporate ladder.
            <br />
            <AccentWord word="Climb" /> Mount Everest.
            <br />
          </>,
          <>
            <AccentWord word="Grow" /> a beard.
            <br />
            <AccentWord word="Grow" /> old.
            <br />
            <AccentWord word="Grow" /> a business.
            <br />
            <AccentWord word="Grow" /> in confidence.
          </>,
        ],
      },
      {
        title: "tell / say / speak",
        description: [
          <Typography component="span">
            Різниця у значенні:{" "}
            <Typography component="span" fontWeight={700}>
              говорити, казати
            </Typography>
          </Typography>,
        ],
        lesson: [9],
        examples: [
          <>
            <Typography component="span">Використовується для звернення до людини.</Typography>
            <br />
            <AccentWord word="Tell" /> me what is wrong?
            <br />
            e doesn't want to <AccentWord word="tell" /> me about that.
            <br />
          </>,
          <>
            <Typography component="span">
              Використовується у загальному значенні без звернення до людини.
              <br />
              Також використовується у прямій мові. (сказав, сказала, сказали)
            </Typography>
            <br />
            I want to <AccentWord word="say" /> something.
            <br />
            Do you want to <AccentWord word="say" /> something?
            <br />
          </>,
          <>
            <Typography component="span">Вміння або здатність говорити.</Typography>
            <br />
            I <AccentWord word="speak" /> English.
            <br />
            She can't <AccentWord word="speak" /> English.
            <br />
          </>,
        ],
      },
      {
        title: "like / love",
        description: [
          <Typography component="span">
            Різниця у значенні:{" "}
            <Typography component="span" fontWeight={700}>
              любити
            </Typography>
          </Typography>,
          "Важливо зазначити що кожна людина вкладає свій власний зміст у ці слова залежно від свого досвіду та цінностей.",
        ],
        lesson: [4],
        examples: [
          <>
            I <AccentWord word="like" /> you.
            <br />
            <Typography fontSize={14}>
              Симпатія та прив'язаність: Ця фраза виражає позитивні почуття до людини, інтерес до
              неї, симпатію та прихильність.
              <br />
              Дружба та романтика: Вона може використовуватись як в дружньому, так і в романтичному
              контексті, але на початкових етапах стосунків.
              <br />
              Повага до особистих меж: "I like you" не передбачає глибокої емоційної залежності та
              поваги до особистих меж.
            </Typography>
          </>,
          <>
            I <AccentWord word="love" /> you.
            <br />
            <Typography fontSize={14}>
              Глибоке та сильне почуття: Ця фраза вказує на глибоке, всеосяжне та тривале кохання.
              Вона виходить за рамки простої симпатії.
              <br />
              Відданість та близькість: "I love you" говорить про емоційну близькість, відданість,
              бажання бути разом у довготривалій перспективі.
              <br />
              Серйозність намірів: Використання цієї фрази часто свідчить про серйозність намірів та
              глибину почуттів.
            </Typography>
          </>,
        ],
      },
      {
        title: "study / learn",
        description: [
          <Typography component="span">
            Різниця у значенні:{" "}
            <Typography component="span" fontWeight={700}>
              навчатися
            </Typography>
          </Typography>,
        ],
        lesson: [1],
        examples: [
          <>
            I <AccentWord word="learn" /> English.
            <br />
            <Typography fontSize={14}>
              Підкреслює сам процес засвоєння мови, набуття знань та навичок. Можете означати
              вивчення англійської пасивно, наприклад, дивлячись фільми. Часто вивчення мови
              самостійно.
            </Typography>
          </>,
          <>
            I <AccentWord word="study" /> English.
            <br />
            <Typography fontSize={14}>
              Акцент на свідомому та цілеспрямованому зусиллі вивчення мови. Часто вивчення мови у
              навчальному закладі.
            </Typography>
          </>,
        ],
      },
    ],
  },
];

const AccordionRule: React.FC<
  PropsWithChildren & {
    isActive: boolean;
    examples?: string[];
    title: string;
    raw?: boolean;
    description?: string[] | string;
  }
> = ({ isActive, raw, title, description, examples, children }) => {
  const [expanded, setExpanded] = useState(isActive);

  const descriptionArray = useMemo(() => {
    return typeof description === "string" ? [description] : description || [];
  }, [description]);

  if (raw) {
    return (
      <Typography
        component="span"
        color={isActive ? "primary" : "black"}
        variant="h3"
        fontSize={20}
      >
        {title}
      </Typography>
    );
  }

  return (
    <Accordion
      expanded={expanded}
      elevation={2}
      onChange={(value) => {
        setExpanded((prev) => !prev);
      }}
      defaultExpanded={isActive}
      disableGutters
      sx={{
        pl: 4,
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h3" fontSize={20}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
        {!!descriptionArray.length && (
          <Box m={0}>
            {descriptionArray.map((item) => (
              <Typography fontSize={18}>{item}</Typography>
            ))}
          </Box>
        )}
        {examples && !!examples.length && (
          <Box mt={descriptionArray.length ? 5 : 0} mb={5}>
            {examples?.map((item) => {
              return (
                <Box mb={3} fontWeight={600} fontSize={18} color="secondary.dark">
                  {item}
                </Box>
              );
            })}
          </Box>
        )}
      </AccordionDetails>
      <Box
        sx={{
          pointerEvents: "none",
          position: "absolute",
          top: 0,
          left: 0,
          width: 5,
          height: "100%",
          backgroundColor: "#4aa805",
        }}
      ></Box>
    </Accordion>
  );
};

export default AccordionRule;

import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import {
  Badge,
  Box,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import AW from "../../../Difference/components/AW";
import { useLessonsStore } from "../../../../store/lessonsStore";
import useGlobalWords from "../../../hooks/getGlobalWords";
import ModalCloseButton from "../../ModalCloseButton";
import VStack from "../../VStack";
import WordTranscription from "../../../Word/WordTranscription";
import WithDotBadge from "../../../../unknown/WithDotBadge";

type CreateDataProp = {
  en: any;
  lesson: number;
  examples?: any[];
};

function createData(item1: CreateDataProp, item2: CreateDataProp) {
  return {
    item1,
    item2,
  };
}

const rows = [
  createData(
    {
      lesson: 5,
      en: (
        <Box>
          <Typography variant="h3">the</Typography>
          <Typography>означений артикль</Typography>
        </Box>
      ),
    },
    {
      en: null,
      lesson: 5,
      examples: [
        {
          lesson: 5,
          en: (
            <Typography>
              <strong>The</strong> flowers are beautiful.
            </Typography>
          ),
        },
        {
          lesson: 5,
          en: (
            <Typography>
              What does <strong>the</strong> monkey say?
            </Typography>
          ),
        },
        {
          lesson: 5,
          en: (
            <Typography>
              <strong>The</strong> jungles of Brazil.
            </Typography>
          ),
        },
      ],
    },
  ),
  createData(
    {
      en: (
        <Box>
          <Typography variant="h3">a</Typography>
          <Typography>неозначений артикль</Typography>
        </Box>
      ),
      lesson: 4,
    },
    {
      en: null,
      lesson: 4,
      examples: [
        {
          lesson: 4,
          en: (
            <Typography>
              He is <strong>a</strong> boy.
            </Typography>
          ),
        },
        {
          lesson: 4,
          en: (
            <Typography>
              She is <strong>a</strong> very happy girl.
            </Typography>
          ),
        },
      ],
    },
  ),
  createData(
    {
      lesson: 6,
      en: (
        <Box>
          <Typography variant="h3">an</Typography>
          <Typography>неозначений артикль</Typography>
        </Box>
      ),
    },
    {
      lesson: 6,
      en: null,
      examples: [
        {
          lesson: 6,
          en: (
            <Typography>
              She eats <strong>an</strong> apple.
            </Typography>
          ),
        },
        {
          lesson: 6,
          en: (
            <Typography>
              What does <strong>an</strong> elephant eat?
            </Typography>
          ),
        },
      ],
    },
  ),
];

const style = {
  position: "absolute" as "absolute",
  top: "25px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 400,
  overflow: "auto",
  maxHeight: "calc(100% - 50px)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ArticleTable: React.FC<{}> = ({}) => {
  const [isCellModalOpen, setIsCellModalOpen] = useState(false);
  const [isHeaderCellModalOpen, setIsHeaderCellModalOpen] = useState(false);
  const [activeWord, setActiveWord] = useState("");
  const [activeTranslation, setActiveTranslation] = useState("");
  const [activeDescription, setActiveDescription] = useState("");
  const [activeExamples, setActiveExamples] = useState<any[]>([]);
  const [headerCellModalBody, setHeaderCellModalBody] = useState<any>(null);
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);

  const globalWordsOptions = useMemo(() => {
    if (activeWord) {
      return { words: [activeWord] };
    }
    return { words: [] };
  }, [activeWord]);
  const [word] = useGlobalWords(globalWordsOptions);

  return (
    <>
      <Modal
        open={isCellModalOpen}
        onClose={() => {
          setIsCellModalOpen(false);
        }}
      >
        <Box sx={style}>
          <ModalCloseButton onClose={() => setIsCellModalOpen(false)} />
          <VStack alignItems="flex-start">
            <Typography variant="h3">{activeWord}</Typography>
            <Typography>{activeTranslation}</Typography>
            {word && <WordTranscription word={word} play small={false} autoplay={false} />}
            <Typography>{activeDescription}</Typography>
            <VStack alignItems="flex-start">
              {activeExamples.map((e, i) => {
                return (
                  <Typography variant="h3" key={i}>
                    {e.title}
                  </Typography>
                );
              })}
            </VStack>
          </VStack>
        </Box>
      </Modal>
      <Modal
        open={isHeaderCellModalOpen}
        onClose={() => {
          setIsHeaderCellModalOpen(false);
        }}
      >
        <Box sx={style}>
          <ModalCloseButton onClose={() => setIsHeaderCellModalOpen(false)} />
          <VStack alignItems="flex-start">{headerCellModalBody}</VStack>
        </Box>
      </Modal>

      {/* <Box display="flex" justifyContent="center"> */}
      <TableContainer component={Paper} sx={{ p: 0, width: "auto" }}>
        <Table sx={{ minWidth: 650, width: "auto" }} aria-label="simple table">
          <TableBody>
            {rows.map((row: any, i) => {
              return (
                <TableRow key={i}>
                  <CustomTableCell lesson={row.item1.lesson} width={100} en={row.item1.en} />
                  <CustomTableCell
                    width={250}
                    lesson={row.item2.lesson}
                    en={row.item2.en}
                    examples={row.item2.examples}
                  ></CustomTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* </Box> */}
    </>
  );
};

const CustomTableCell: React.FC<{
  en: string;
  width: number;
  lesson: number;
  examples?: any[];
}> = ({ en, width, lesson, examples }) => {
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);

  const examplesArr = useMemo(() => {
    return examples
      ?.filter((e) => e.lesson <= lessonNumber)
      .map((e, i, examplesArr) => {
        const nextIsUpLesson = examplesArr[i + 1] && examplesArr[i + 1].lesson !== e.lesson;
        const prevIsDownLesson = examplesArr[i - 1] && examplesArr[i - 1].lesson !== e.lesson;
        const showIcon = prevIsDownLesson && e.lesson === lessonNumber;

        if (lesson !== e.lesson && e.lesson === lessonNumber) {
          return <WithDotBadge>{e.en}</WithDotBadge>;
        }
        return (
          <Typography key={i} sx={nextIsUpLesson ? { mb: 4 } : {}}>
            {e.en}
          </Typography>
        );
      });
  }, [examples, lessonNumber]);

  return (
    <TableCell
      component="th"
      scope="row"
      sx={{
        // p: 0,
        width,
        minWidth: width,
        m: 0,
        backgroundColor:
          lesson === lessonNumber ? "#ddffc5" : lesson <= lessonNumber ? "#fcffdb" : "#d0d0d0",
        // boxSizing: "content-box",
        height: "100%",
        // cursor: "pointer",
        minHeight: 0,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderLeft: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
        position: "relative",
      }}
    >
      <Box mb={0}>{en}</Box>
      {examplesArr}
    </TableCell>
  );
};

export default ArticleTable;

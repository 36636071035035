import filterSkipedWords from "../../common/filterSkipedWords";
import getSentenceWords from "../../common/getSentenceWords";
import getSkipWords from "../../common/getSkipWords";
import supabaseClient from "../../common/supabaseClient";
import useCreateAudio from "../../common/useCreateAudio";
import wait from "../../common/wait";
import { Sentence } from "../../types/supabase";
import getSentenceWordMatch from "../Word/WordTranslationSection/getSentenceWordMatch";

const checkSentencesExistence = async ({
  enS,
  uaS,
  mS,
  combination,
  createAudio,
}: {
  enS: string[];
  uaS: string[];
  mS: string[];
  combination: string;
  createAudio: (text: string) => Promise<string>;
}) => {
  const { data: sentences } = await supabaseClient
    .from("sentences")
    .select("en")
    .in("en", enS)
    .throwOnError();

  const { data: lastId } = await supabaseClient
    .from("sentences")
    .select("id")
    .order("id", { ascending: false })
    .limit(1)
    .single()
    .throwOnError();
  console.log("🚀 ~ lastId:", lastId);

  if (!lastId) throw new Error("Last id not found");

  const rowExistingSentences = sentences!.map((s) => s.en);
  console.log("🚀 ~ rowExistingSentences:", rowExistingSentences);
  const notExistingSentences = enS.filter((s) => !rowExistingSentences.includes(s));
  console.log("🚀 ~ notExistingSentences:", notExistingSentences);
  console.log("🚀 ~ notExistingSentences:", notExistingSentences);

  const sentencesToInsert: Sentence[] = [];

  for (let i = 0; i < enS.length; i++) {
    const en = enS[i];

    if (!notExistingSentences.includes(en)) {
      await supabaseClient
        .from("sentences")
        .update({
          meaning: mS[i],
        })
        .eq("en", en)
        .throwOnError();

      continue;
    }

    const audio = await createAudio(en);
    wait(300);

    sentencesToInsert.push({
      en,
      ua: uaS[i],
      meaning: mS[i],
      en_audio_url: audio,
      id: lastId.id + 1 + i,
    });
  }

  if (sentencesToInsert.length) {
    await supabaseClient.from("sentences").insert(sentencesToInsert).throwOnError();
  }

  const skipWords = await getSkipWords(getSentenceWords(enS.join(" ")));
  console.log("🚀 ~ skipWords:", skipWords);

  await supabaseClient
    .from("difference")
    .insert(
      enS.map((en, i) => {
        return {
          id: `${combination}-${en}`,
          en,
          combination,
          words: filterSkipedWords(getSentenceWords(en), skipWords),
          order: i,
        };
      }),
    )
    .throwOnError();

  return false;
};

export default checkSentencesExistence;

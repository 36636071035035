import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Box from "../../Box";
import filterRules from "./utils/filterRules";
import { useLessonsStore } from "../../../../store/lessonsStore";
import AccordionRule from "./AccordionRule";
import HStack from "../../HStack";
import H3Title from "../../H3Title";
import AccentStrong, {
  AccentType,
} from "../../../Description/ModuleDescription/utils/AccentString";
import { PropsWithChildren } from "react";

type CreateDataProp = {
  first: any;
  second: any;
  lesson: number;
  ua: string;
};

function createData(item1: CreateDataProp, item2?: CreateDataProp, item3?: CreateDataProp) {
  return {
    item1,
    item2,
    item3,
  };
}

const rows = (lessonNumber: number) => [
  createData(
    {
      first: "long",
      second: "short",
      lesson: 19,
      ua: "довгий / короткий",
    },
    {
      first: "usual",
      second: "unusual",
      lesson: 16,
      ua: "звичайний / незвичайний",
    },
  ),
  createData(
    {
      first: "tall",
      second: "short",
      lesson: 11,
      ua: "високий / низький",
    },
    {
      first: "yes",
      second: "no",
      lesson: 11,
      ua: "так / ні",
    },
    {
      first: "do",
      second: "doesn't",
      lesson: 11,
      ua: "робити / не робити",
    },
  ),
];

// const rule1 = {
//   start: 30,
//   words: [
//     {start: 30, words: [fast]}
//   ]
// }

const Show: React.FC<PropsWithChildren & { start: number }> = ({ children, start }) => {
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);
  if (lessonNumber < start) return null;
  return <>{children};</>;
};
const Words: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography fontSize={22} fontStyle="italic" sx={{ pl: 3 }}>
      {children}
    </Typography>
  );
};
const Rule: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography
      fontWeight={400}
      fontStyle="italic"
      color="secondary.dark"
      fontSize={16}
      sx={{
        backgroundColor: "#f6f6f6",
        padding: 3,
      }}
    >
      {children}
    </Typography>
  );
};

const ComparativeAdjectives = () => {
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);

  return (
    <Box mb={10}>
      <H3Title>Порівняльні прикметники (Comparative Adjectives)</H3Title>
      <Typography gutterBottom>
        використовуються для порівняння двох речей або для відображення зміни. Форма порівняння
        залежить від кількості складів у прикметнику.
      </Typography>

      <Box my={3}>
        {/* <hr /> */}
        <Rule>
          Щоб утворити порівняльні форми з односкладовими прикметниками, зазвичай додаємо{" "}
          <AccentStrong text="-er" type={[AccentType.accent]} />:
        </Rule>
        <Words>
          fast → fast
          <AccentStrong text="er" type={[AccentType.underlined]} />
        </Words>
        <Words>
          tall → tall
          <AccentStrong text="er" type={[AccentType.underlined]} />
        </Words>
        <Words>
          strong → strong
          <AccentStrong text="er" type={[AccentType.underlined]} />
        </Words>
        <Words>
          old → old
          <AccentStrong text="er" type={[AccentType.underlined]} />
        </Words>
        <Show start={9999}>
          <Words>
            clean → clean
            <AccentStrong text="er" type={[AccentType.underlined]} />
          </Words>
          <Words>
            slow → slow
            <AccentStrong text="er" type={[AccentType.underlined]} />
          </Words>
        </Show>
      </Box>
      <Show start={9999}>
        <Box my={3}>
          {/* <hr /> */}
          <Rule>
            Якщо прикметник закінчується на <AccentStrong text="-e" type={[AccentType.accent]} />,
            додаємо <AccentStrong text="-r" />:
          </Rule>
          <Words>
            safe → safe
            <AccentStrong text="r" type={[AccentType.underlined]} />
          </Words>
          <Words>
            nice → nice
            <AccentStrong text="r" type={[AccentType.underlined]} />
          </Words>
        </Box>
      </Show>
      <Box my={3}>
        {/* <hr /> */}
        <Rule>
          Якщо прикметник закінчується на голосну і приголосну, зазвичай подвоюємо приголосну:
        </Rule>
        <Words>
          big → big
          <AccentStrong text="ger" type={[AccentType.underlined]} />
        </Words>
        <Show start={99990}>
          <Words>
            hot → hot
            <AccentStrong text="ter" type={[AccentType.underlined]} />
          </Words>
        </Show>
      </Box>
      <Box my={3}>
        {/* <hr /> */}
        <Rule>
          Якщо двоскладовий прикметник закінчується на приголосний і{" "}
          <AccentStrong text="-y" type={[AccentType.accent]} />, то змінюємо{" "}
          <AccentStrong text="-y" type={[AccentType.accent]} /> на{" "}
          <AccentStrong text="-i" type={[AccentType.accent]} /> та додаємо{" "}
          <AccentStrong text="-er" />:
        </Rule>
        <Words>
          friendly → friendl
          <AccentStrong text="ier" type={[AccentType.underlined]} />
        </Words>
        <Show start={9999}>
          <Words>
            noisy → nois
            <AccentStrong text="ier" type={[AccentType.underlined]} />
          </Words>
          <Words>
            happy → happ
            <AccentStrong text="ier" type={[AccentType.underlined]} />
          </Words>
          <Words>
            easy → eas
            <AccentStrong text="ier" type={[AccentType.underlined]} />
          </Words>
        </Show>
      </Box>
      <Box my={2}>
        {/* <hr /> */}
        <Rule>
          У більшості двоскладових прикметників і для всіх прикметників із трьома або більше
          складами використовується <AccentStrong text="more/less" type={[AccentType.accent]} />:
        </Rule>
        <Words>
          friendly → <AccentStrong text="more" type={[AccentType.underlined]} /> friendly
        </Words>
        <Show start={9999}>
          <Words>
            crowded → <AccentStrong text="more" type={[AccentType.underlined]} /> crowded
          </Words>
          <Words>
            dangerous → <AccentStrong text="more" type={[AccentType.underlined]} /> dangerous
          </Words>
        </Show>
      </Box>
      <Box my={3}>
        {/* <hr /> */}
        <Rule>
          Виняток: можна додати <AccentStrong text="-er" type={[AccentType.accent]} /> /{" "}
          <AccentStrong text="-r" type={[AccentType.accent]} /> або використати{" "}
          <AccentStrong text="more" type={[AccentType.accent]} /> з деякими двоскладовими
          прикметниками:
        </Rule>
        <Words>
          friendly → <AccentStrong text="more" type={[AccentType.underlined]} /> friendly → friendl
          <AccentStrong text="ier" type={[AccentType.underlined]} />
        </Words>
        <Show start={9999}>
          <Words>
            simple → <AccentStrong text="more" type={[AccentType.underlined]} /> simple → simple
            <AccentStrong text="r" type={[AccentType.underlined]} />
          </Words>
          <Words>
            common → <AccentStrong text="more" type={[AccentType.underlined]} /> common → common
            <AccentStrong text="er" type={[AccentType.underlined]} />
          </Words>
          <Words>
            cruel → <AccentStrong text="more" type={[AccentType.underlined]} /> cruel → cruel
            <AccentStrong text="er" type={[AccentType.underlined]} />
          </Words>
          <Words>
            gentle → <AccentStrong text="more" type={[AccentType.underlined]} /> gentle → gentle
            <AccentStrong text="r" type={[AccentType.underlined]} />
          </Words>
          <Words>
            handsome → <AccentStrong text="more" type={[AccentType.underlined]} /> handsome →
            handsome
            <AccentStrong text="r" type={[AccentType.underlined]} />
          </Words>
          <Words>
            likely → <AccentStrong text="more" type={[AccentType.underlined]} /> likely → likel
            <AccentStrong text="ier" type={[AccentType.underlined]} />
          </Words>
          <Words>
            narrow → <AccentStrong text="more" type={[AccentType.underlined]} /> narrow → narrow
            <AccentStrong text="er" type={[AccentType.underlined]} />
          </Words>
          <Words>
            pleasant → <AccentStrong text="more" type={[AccentType.underlined]} /> pleasant →
            pleasant
            <AccentStrong text="er" type={[AccentType.underlined]} />
          </Words>
          <Words>
            polite → <AccentStrong text="more" type={[AccentType.underlined]} /> polite → polite
            <AccentStrong text="r" type={[AccentType.underlined]} />
          </Words>
          <Words>
            stupid → <AccentStrong text="more" type={[AccentType.underlined]} /> stupid → stupid
            <AccentStrong text="er" type={[AccentType.underlined]} />
          </Words>
        </Show>
      </Box>
      <Show start={9999}>
        <Box my={3}>
          <hr />
          <Rule>Неправильні прикметники</Rule>
          <Words>good → better</Words>
          <Words>bad → worse</Words>
          <Words>far → further/farther</Words>
        </Box>
      </Show>
    </Box>
  );
};

const CustomTableCell: React.FC<{
  ua?: string;
  first: string;
  second: string;
  width: number;
  lesson: number;
}> = ({ first, second, lesson, width, ua }) => {
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);
  const isReady = lesson <= lessonNumber;

  return (
    <TableCell
      component="th"
      scope="row"
      sx={{
        m: 0,
        width,
        pointerEvents: isReady ? "auto" : "none",
        backgroundColor:
          lesson === lessonNumber ? "#ddffc5" : lesson <= lessonNumber ? "#fcffdb" : "#d0d0d0",
        height: "auto",
        cursor: "pointer",
        minHeight: 0,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderLeft: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
        py: 1,
      }}
    >
      <Typography variant="body1" fontWeight={500} fontStyle="italic">
        {first}6/ {second}
      </Typography>
      <Typography variant="caption">{ua}</Typography>
    </TableCell>
  );
};

export default ComparativeAdjectives;

import LoadingButton from "@mui/lab/LoadingButton";
import { useCallback, useState } from "react";
import useCreateAudio from "../../../common/useCreateAudio";
import Box from "../../Unknown/Box";
import HStack from "../../Unknown/HStack";
import Typography from "../../Unknown/Typography";
import createNewNotExistingSentenceFromRowSentences from "../createNewNotExistingSentenceFromRowSentences";
import supabaseClient from "../../../common/supabaseClient";

const AdminNewLessonSentences: React.FC<{
  sentences: { en: string; ua: string }[];
  onInsertSentences: () => void;
}> = ({ sentences, onInsertSentences }) => {
  const [loading, setLoading] = useState(false);
  const { createAudio } = useCreateAudio();

  const handleCreateNewSentences = useCallback(async () => {
    setLoading(true);

    const sentencesToInsert = await createNewNotExistingSentenceFromRowSentences({
      notExistingSentences: sentences,
      createAudio,
    });
    console.log("🚀 ~ sentencesToInsert:", sentencesToInsert);

    try {
      await supabaseClient.from("sentences").insert(sentencesToInsert);
    } catch (err) {
      console.log("🚀 ~ err:", err);
    }
    onInsertSentences();

    setLoading(false);
  }, [createAudio, onInsertSentences, sentences]);

  if (!sentences.length) return null;

  return (
    <Box>
      <Typography variant="h3" gutterBottom>
        New lesson sentences:
      </Typography>
      <LoadingButton
        loading={loading}
        variant="contained"
        color="info"
        onClick={handleCreateNewSentences}
      >
        Create new sentences.
      </LoadingButton>
      <HStack my={5}>
        {sentences.map((sentence, i) => {
          return (
            <Box key={i} width="100%">
              <Typography>{sentence.en}</Typography>
            </Box>
          );
        })}
      </HStack>
    </Box>
  );
};

export default AdminNewLessonSentences;

import Box from "@mui/material/Box";

const lesson12: any = {
  letters: [],
  number: 12,
  storyIds: [
    // 1751,
    // 1698, // cat sat
    1589, // daddy finger
    // 1725, // jingle bells
    // 1699, // dad had a fan
    // 1753, // this is a Dan
  ],
  poemIds: [1826, 1828, 1589],
  newLettersCount: 0,
  newSounsCount: 0,
  title: <Box>Finger song</Box>,
  sounds: [
    "Oo(ɒ)",
    "Nn",
    "Tt",
    "Ee(e)",
    "Bb",
    "Dd",
    "Ll",
    "Ii(i)",
    "Pp",
    "Ss(s)",
    "Ss(z)",
    "Zz",
    "Aa(æ)",
    "Hh",
    "Vv",
    "Kk",
    "Aa(æ)",
    "Hh",
    "Vv",
    "Kk",
    "Mm",
    "Ff",
    "Gg",
    "Mm",
    "Ff",
    "Gg",
    "Cc(k)",
    "Rr",
    "oo(uː)",
    "ee(i:)",
    "th(ð)",
    "oo(ʊ)",
    "ea(i:)",
    "sh(ʃ)",
    "Ww",
    "Oo(əʊ)",
    "Ee(ɪ)",
    "j(dʒ)",
    "Uu(ʌ)",
    "Yy(j)",
    "Yy(i)",
    "oy(ɔɪ)",
    "ay(eɪ)",
  ],
  newSounds: [],
  words: [
    // "he",
    // "she",
    // "want",
    // "climb",
    // "can",
    // "do",
    // "can't",
    // "had",
    // // "lamb",
    // "its",
    // "fleece",
    // "was",
    // "white",
    // // "as",
    // // "snow",
    // // "and",
    // // "everywhere",
    // "that",
    // "went",
    // "sure",
    // // "go",
    // "follow",
    // "followed",
    // "her",
    // // "school",
    // "one",
    // "day",
    // "against",
    // "rule",
    // "made",
    // "child",
    // "children",
    // "laugh",
    // "play",
    // "see",
    // "in",
    // "at",
    //
    // "fast",
    // "slow",
    // "runs",
    // "very",
    // "jump",
    // "high",
    // "sun",
    // "low",
    // "plane",
    // "ring",
    // "here",
    // "there",
    // "glass",
    // "need",
    // "dishes",
    // "want",
    // "animal",
    // "run",
    // "every",
    // "sleep",
    // "store",
    // "zoo",
    // "today",
    // "hare",
    // "with",
    // "boy",
    // "plays",
    // "walk",
    // "understand",
    // "next",
    // "do",
    // "hot",
    // "climb",
    // "tree",
    // "bag",
    // "eat",
    // "sheep",
    // "geese",
    // "man",
    // "box",
    // "boxes",
    // "fox",
    // "foxes",
    // "mix",
    // "mixer",
    // "six",
    // "text",
    // "taxi",
    // "read",
    // "problem",
    // "they",
    // "animals",
    // "cup",
    // "understands",
    // "me",
    // "wash",
    // "water",
    // "wants",
    // "swan",
    // "cold",
    // "red",
    // "van",
    // "jeep",
    // "jam",
    // "trash",
    // "he",
    // "has",
    // "she",
    // "shop",
    // "we",
    // "likes",
    // "park",
    // "fish",
    // "climbs",
    // "needs",
    // "am",
    // "hamster",
    // "are",
    // "lambs",
    // "be",
    // "rat",
    // "mat",
    // "look",
    // "sat",
    // "too",
    // "fat",
    // "coffee",
    // "tea",
    // "cat",
    // "this",
    // "root",
    // "roots",
    // "seven",
    // "ran",
    // "room",
    // "pink",
    // "banana",
    // "cats",
    // "on",
    // "green",
    // "goose",
    // "plan",
    // "bedroom",
    // "bananas",
    // "pan",
    // "pans",
    // "fan",
    // "bike",
    // "five",
    // "kite",
    // "bikes",
    // "lime",
    // "life",
    // "time",
    // "map",
    // "maps",
    // "big",
    // "dog",
    // "dogs",
    // "pig",
    // "milk",
    // "is",
    // "hi",
    // "test",
    // "bed",
    // "ten",
    // "sport",
    // "leg",
    // "sister",
    // "continent",
    // "egg",
    // "car",
    // "star",
    // "end",
    // "no",
    // "note",
    // "stone",
    // "poet",
    // "arm",
    // "art",
    // "card",
    // "apple",
    // "hand",
    // "hat",
    // "yes",
    // "yard",
    // "hobby",
    // "Mary",
    // "hello",
    // "letter",
    // "dad",
    // "daddy",
    // "fact",
    // "family",
    // "frog",
    // "nose",
    // "rose",
    // "roses",
    // "mom",
    // "mommy",
    // "finger",
    // "song",
    // "I",
    // "tiger",
    // "spider",
    // "winter",
    // "spring",
    // "hop",
    // "can",
    // "England",
    // "summer",
    // "butter",
    // "up",
    // "the",
    // "bank",
    // "book",
    // "notebook",
    // "spoon",
    // "wood",
    // "brother",
    // "group",
    // "soup",
    // "you",
    // "where",
    // "baby",
    // "lady",
    // "name",
    // "cow",
    // "how",
    // "down",
    // "two",
    "your",
    "daddy",
    "mommy",
    "where",
    "baby",
    "how",
    "song",
    "finger",
    "fingers",
    "pens",
    "with",
    "many",
    "here",
    "there",
    "had",
    "its",
    "was",
    "as",
    "sure",
    "rule",
    "went",
    "that",
    "her",
    "laugh",
    "climb",
  ],
  speakingWords: [
    "Baby! Where is your mommy?",
    "Baby! Where is your daddy?",
    "Where is your pen?",
    "How are you?",
    "How many fingers do you have?",
    "I have ten fingers",
    "How many pens do you have?",
    "I have five pens.",
    "I have a red pen.",
    "Do you have a sister?",
    "Do you go with me?",
    "I want to go with you to the park.",
    "Do you have a red pen?",
    "Do you have a brother?",
    "Do you have a cat?",
    "Where is your sister?",
    "Where is your brother?",
    "Do you have a bell?",
    "Where is a baby?",
    "How many books do you have?",
    "Do you have a notebook?",
    "Do you like to eat soup?",
    "Do you like apples?",
    "Do you see a cat?",
    "Do you see a boy?",
  ],
};

export default lesson12;

"use client";

import { Box, Button, Modal, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ModalCloseButton from "../ModalCloseButton";
import { useLessonsStore } from "../../../store/lessonsStore";

const style = {
  position: "fixed",
  top: "25px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: "1000px",
  height: 1600,
  overflow: "auto",
  maxHeight: "calc(100% - 50px)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  zIndex: 9999999999999999999,
  boxShadow: 24,
  overflowX: "hidden",
};

const PageTranslationWithInputText: React.FC<{
  text: string;
  isPopupOpen: boolean;
  type?: string;
  fromUa?: boolean;
  setIsPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ text, type, fromUa, isPopupOpen, setIsPopupOpen }) => {
  const handleClose = () => setIsPopupOpen(false);

  const onKeyDown = (event: any) => {
    // event.preventDefault(); // Prevent immediate closure
    // event.returnValue = ""; // Chrome requires returnValue to be set (can be empty)

    if (event.key === "Escape") {
      setIsPopupOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  useEffect(() => {
    if (isPopupOpen && type === "google") {
      setTimeout(() => {
        window.open(
          `https://translate.google.com/?${
            fromUa ? "hl=en&sl=uk&tl=en" : "hl=uk&sl=en&tl=uk"
          } &text=${text}&op=translate`,
          "_blank",
          "location=yes,height=1000,width=1500,scrollbars=yes,status=yes",
        );
      }, 200);
      setIsPopupOpen(false);
    } else if (isPopupOpen && type === "cambridge") {
      setTimeout(() => {
        window.open(
          `https://dictionary.cambridge.org/dictionary/english/${text}`,
          "_blank",
          "location=yes,height=1000,width=1500,scrollbars=yes,status=yes",
        );
      }, 200);
      setIsPopupOpen(false);
    } else if (isPopupOpen) {
      setTimeout(() => {
        window.open(
          `https://wooordhunt.ru/word/${text}`,
          "_blank",
          "location=yes,height=1000,width=1500,scrollbars=yes,status=yes",
        );
      }, 200);
      setIsPopupOpen(false);
    }
  }, [isPopupOpen]);

  // if (type && ["google", "cambridge"].includes(type)) {
  // }
  return null;

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: -1000,
        }}
      >
        <TextField value={Math.random()} />
      </Box>
      <Modal
        open={isPopupOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ModalCloseButton onClose={handleClose} />
          <Box
            component="iframe"
            sx={{
              width: "100%",
              border: "none",
              height: "100%",
              display: "flex",
              overflowX: "hidden",
            }}
            src={`https://wooordhunt.ru/word/${text}`}
          ></Box>
        </Box>
      </Modal>
    </>
  );
};

export default PageTranslationWithInputText;

import AddIcon from "@mui/icons-material/Add";
import ChatIcon from "@mui/icons-material/Chat";
import { Box, Button, Checkbox, IconButton, Modal, TextField, Typography } from "@mui/material";
import "draft-js/dist/Draft.css";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import isAdmin from "../../../common/isAdmin";
import supabaseClient from "../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../../store/lessonsStore";
import AdminUsers from "../../AdminUsers";
import "./root.css";

import LoadingButton from "@mui/lab/LoadingButton";
import { format } from "date-fns";
import DOMPurify from "dompurify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import getSentenceWords from "../../../common/getSentenceWords";
import ItalicTitle from "../../Title/ItalicTitle";
import HStack from "../HStack";
import VStack from "../VStack";

// const Size = Quill.import("attributors/style/size");
// Size.whitelist = ["small", "medium", "large", "huge"]; // Allowed sizes
// Quill.register(Size, true);

const fontSizes = ["small", "medium", "large", "huge"];

export const createUserLesson = async (props: any): Promise<any> => {
  const date = format(new Date(), "yyyy-MM-dd");

  await supabaseClient.from("user_lessons").insert({
    ...props,
    id: `${props.user}_${date}`,
    date,
  });
};
export const updateUserLesson = async (props: any): Promise<any> => {
  await supabaseClient
    .from("user_lessons")
    .update({
      ...props,
    })
    .eq("date", format(new Date(), "yyyy-MM-dd"))
    .eq("user", props.user);
};
export const getUserLesson = async (userId: string): Promise<any> => {
  const { data } = await supabaseClient
    .from("user_lessons")
    .select()
    .eq("user", userId)
    .eq("date", format(new Date(), "yyyy-MM-dd"))
    .maybeSingle();

  // if (!data) {
  //   alert("Something went wrong. Data is empty");
  // }

  return data;
};

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    [{ size: fontSizes }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link", "image"],
    // [{ color: [] }], // Add the color picker to the toolbar
    [
      { color: "black" },
      { color: "rgb(230, 0, 0)" },
      { color: "rgb(255, 153, 0)" },
      { color: "rgb(0, 138, 0)" },
      { color: "rgb(0, 102, 204)" },
      { color: "rgb(153, 51, 255)" },
    ],
    ["clean"],
  ],
};

const formats = [
  "header",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];

const chatModalStyle = {
  position: "fixed",
  width: "100vw",
  maxWidth: "500px",
  height: "100%",
  maxHeight: "80vh",
  overflow: "auto",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  zIndex: 999999999999,
  // justifyContent: "center",
  // alignItems: "center",
  // border: "2px solid #000",
  // boxShadow: 24,
  p: 4,
};

const ControllingStudentPage: React.FC<{ isNested?: boolean; poemIds?: number[] }> = ({
  isNested,
  poemIds,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = async () => {
    setOpen(false);

    await supabaseClient
      .from("dialog-admin")
      .update({
        open: false,
      })
      .eq("id", "chat");
  };

  const isBoardTextAllowedByAdmin = useLessonsStore((state) => state.isBoardTextAllowedByAdmin);
  const setIsBoardTextAllowedByAdmin = useLessonsStore(
    (state) => state.setIsBoardTextAllowedByAdmin,
  );
  console.log("🚀 ~ isBoardTextAllowedByAdmin:", isBoardTextAllowedByAdmin);

  const inputRef = useRef<any>(null);
  const authUser = useLessonsStore((state) => state.authUser);
  const [users, setUsers] = useState<any[]>([]);
  const [dbChat, setDbChat] = useState<any>();
  const isTeacherLesson = useLessonsStore((state) => state.isTeacherLesson);
  const isUserBoardOpen = useLessonsStore((state) => state.isUserBoardOpen);
  const userBoardText = useLessonsStore((state) => state.userBoardText);
  const quillRef = useRef<any>(null);
  const studentUser = useLessonsStore((state) => state.studentUser);
  const setStudentUser = useLessonsStore((state) => state.setStudentUser);
  const setStudentWords = useLessonsStore((state) => state.setStudentWords);
  const newUserLesson = useLessonsStore((state) => state.newUserLesson);
  const setNewUserLesson = useLessonsStore((state) => state.setNewUserLesson);
  const setEditorState = useLessonsStore((state) => state.setEditorState);
  // do not include these words for user
  const [wordsToFilter] = useState(["a", "the", "an", "-"]);

  useMountedWaitAsyncEffect(async () => {
    if (!studentUser) return;

    let userLesson = await getUserLesson(studentUser.id);
    // new means current, lessen that was created today
    setNewUserLesson(userLesson);
  }, [studentUser]);

  useEffect(() => {
    setTimeout(() => {
      if (open && inputRef.current) {
        inputRef.current.focus();
      }
    }, 200);
  }, [open, inputRef]);

  const endSession = useCallback(async () => {
    await supabaseClient
      .from("admin_control")
      .update({ user: null, is_teacher_lesson: false, board_is_open: false, active_video_id: null })
      .eq("id", 0);

    setStudentWords([]);
    setStudentUser(null);
  }, [setStudentWords, setStudentUser]);

  useMountedWaitAsyncEffect(async () => {
    // if (isAdmin(authUser?.id)) {
    // }
    const { data } = await supabaseClient
      .from("admin_control")
      .select()
      .eq("id", "0")
      .single()
      .throwOnError();
    if (data) setDbChat(data);

    // const channel = supabaseClient
    //   .channel("room5")
    //   .on(
    //     "postgres_changes",
    //     { event: "UPDATE", schema: "public", table: "dialog-admin" },
    //     (payload) => {

    //       if (payload.new.id === "chat") {
    //         setDbChat(payload.new as any);
    //       }
    //       if (payload.new.id === "words_moving") {
    //         if (authUser?.uuid === payload.new.user) {
    //           setRPCUser({
    //             ...authUser,
    //             type: payload.new.type,
    //             studentUserId: payload.new.user,
    //           } as any);
    //         }
    //       }
    //     },
    //   )
    //   .subscribe();

    // return () => {
    //   channel.unsubscribe();
    // };
  }, []);

  useMountedWaitAsyncEffect(async () => {
    if (isAdmin(authUser?.id) && !users?.length) {
      const { data } = await supabaseClient.from("users").select();
      if (data) setUsers(data);
    }
  }, [users, authUser]);

  useEffect(() => {
    if (isAdmin(authUser?.id)) {
      const handleKeyDown = async (event: any) => {
        if (!event.ctrlKey && event.shiftKey && event.metaKey && event.keyCode === 13) {
          setOpen(true);
        } else if (event.ctrlKey && event.metaKey && event.keyCode === 13) {
          setEditorState("");

          await supabaseClient
            .from("admin_control")
            .update({
              board_text: "",
              board_is_open: isBoardTextAllowedByAdmin,
            })
            .eq("id", 0);
          setOpen(true);
        }
      };

      window.addEventListener("keydown", handleKeyDown);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [authUser, isBoardTextAllowedByAdmin, setEditorState]);

  useEffect(() => {
    if (isUserBoardOpen && isTeacherLesson) {
      setOpen(true);
    } else if (authUser && !isAdmin(authUser.id)) {
      setOpen(false);
    }
  }, [dbChat, isTeacherLesson, isUserBoardOpen, authUser]);

  const editorState = useLessonsStore((state) => state.editorState);
  const studentWords = useLessonsStore((state) => state.studentWords);
  const [newUserWords, setNewUserWords] = useState<string[]>([]);
  const [existingUserWords, setExistingNewUserWords] = useState<string[]>([]);

  useMountedWaitAsyncEffect(
    () => {
      if (!studentWords?.length) return;
      const intermediate = editorState.replace(/<\/?[^>]+(>|$)/g, " ");
      const clean = DOMPurify.sanitize(intermediate);
      const words = getSentenceWords(clean)
        .filter((w) => !studentWords.includes(w))
        .filter((w) => !wordsToFilter.includes(w));
      setNewUserWords(words);
      let existingUserWords = getSentenceWords(clean).filter((w) => studentWords.includes(w));

      if (newUserLesson && newUserLesson?.words?.length) {
        existingUserWords = existingUserWords
          .filter((w) => !newUserLesson?.words?.includes(w))
          .filter((w) => !wordsToFilter.includes(w));
      }
      setExistingNewUserWords(existingUserWords);
    },
    [editorState, studentWords, newUserLesson, setEditorState],
    0,
  );

  useEffect(() => {
    if (!isAdmin(authUser?.id)) {
      setEditorState(userBoardText);
    }
  }, [authUser, userBoardText, setEditorState]);

  useMountedWaitAsyncEffect(
    async () => {
      if (isAdmin(authUser?.id)) {
        await supabaseClient
          .from("admin_control")
          .update({
            board_text: editorState,
            // text: text.reverse(),
            // open: !!text.length,
            board_is_open: isBoardTextAllowedByAdmin && !!editorState.trim(),
          })
          .eq("id", 0);
      } else {
      }
    },
    // [textValue, authUser],
    [editorState, authUser, isBoardTextAllowedByAdmin],
    200,
  );

  useMountedWaitAsyncEffect(async () => {
    if (isAdmin(authUser?.id)) {
      await supabaseClient
        .from("admin_control")
        .update({
          board_is_open: isBoardTextAllowedByAdmin && open,
        })
        .eq("id", 0);
    }
  }, [open, isBoardTextAllowedByAdmin, authUser]);

  const [needToAddPoems, setNeedToAddPoems] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsBoardTextAllowedByAdmin(event.target.checked);
  };

  useMountedWaitAsyncEffect(async () => {
    await supabaseClient
      .from("admin_control")
      .update({
        board_is_open: isBoardTextAllowedByAdmin,
      })
      .eq("id", 0);
  }, [isBoardTextAllowedByAdmin]);

  const checkUserPoemsExistence = useCallback(async () => {
    if (!poemIds?.length || !studentUser) return;
    const { data: poems } = await supabaseClient
      .from("user_poems_old")
      .select()
      .in("poem", poemIds)
      .eq("user", studentUser.id);

    if (poems && poems?.length !== poemIds?.length) {
      setNeedToAddPoems(true);
    } else {
      setNeedToAddPoems(false);
    }
  }, [setNeedToAddPoems, studentUser, poemIds]);

  useMountedWaitAsyncEffect(async () => {
    if (isAdmin(authUser?.id) && poemIds?.length && studentUser) {
      await checkUserPoemsExistence();
    }
  }, [poemIds, authUser, studentUser]);

  const [loading, setLoading] = useState(false);

  const content = useCallback(() => {
    return (
      <Box maxWidth={600} mb={2} width="100%">
        {!isAdmin(authUser?.id) && (
          <>
            <Box display="flex" flexDirection="column" alignItems="revert">
              {/* {dbChat.text.map((text: string) => {
            return (
              <Typography variant="body1" fontSize={25} width="100%" gutterBottom>
                {text}
              </Typography>
            );
          })} */}
              <ReactQuill
                theme="snow"
                value={editorState}
                // placeholder="Baba nam kevalam"
                onChange={setEditorState}
                readOnly={true} // Make the editor read-only
                modules={{ toolbar: false }} // Disable the toolbar
                // modules={modules}
                // formats={formats}
              />
            </Box>
          </>
        )}
        {isAdmin(authUser?.id) && (
          <>
            <HStack gap={2}>
              <Button variant="contained" size="small" color="error" onClick={endSession}>
                end session
              </Button>
              <Checkbox
                // checked={isBoardTextAllowedByAdmin}
                onChange={handleChange}
              />
              <AdminUsers
                users={users}
                onChange={async (user) => {
                  await supabaseClient
                    .from("admin_control")
                    .update({ user: user?.id || null, is_teacher_lesson: !!user?.id })
                    .eq("id", 0);
                }}
              />
              {studentUser && studentUser.read_lesson && (
                <VStack gap={0} width="auto">
                  <Typography variant="caption">reading</Typography>
                  <Box display="flex" alignItems="center">
                    <Typography>{studentUser.read_lesson}</Typography>
                    <IconButton
                      size="small"
                      onClick={async () => {
                        await supabaseClient
                          .from("users")
                          .update({
                            read_lesson: studentUser.read_lesson! + 1,
                          })
                          .eq("id", studentUser.id);
                        const { data: updatedUser } = await supabaseClient
                          .from("users")
                          .select()
                          .eq("id", studentUser.id)
                          .single();

                        setStudentUser(updatedUser);
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                </VStack>
              )}
              {needToAddPoems && (
                <Button
                  variant="contained"
                  color="error"
                  onClick={async () => {
                    if (!poemIds || !studentUser) return;

                    await Promise.all(
                      poemIds.map((poemId) => {
                        return supabaseClient.from("user_poems_old").insert({
                          id: `${studentUser.id}-${poemId}`,
                          user: studentUser.id,
                          poem: poemId,
                        });
                      }),
                    );

                    await checkUserPoemsExistence();
                  }}
                >
                  Add poems
                </Button>
              )}
            </HStack>
            <Box mb={10}>
              <ReactQuill
                theme="snow"
                ref={quillRef}
                placeholder="Baba nam kevalam"
                value={editorState}
                onChange={(text, delta, source, editor) => {
                  console.log("🚀 ~ text:", text);
                  setEditorState(text);

                  // Persist font size when typing
                  // if (source === "user") {
                  //   const quill = quillRef.current.getEditor();
                  //   const currentFormat = quill.getFormat();

                  //   // If no size is set, apply the last selected size
                  //   if (!currentFormat.size) {
                  //     quill.format("size", currentSize);
                  //   }
                  // }
                }}
                modules={modules}
                formats={formats}
              />
            </Box>

            {Boolean(newUserWords?.length) && (
              <Box mb={5}>
                <ItalicTitle text="New user words:" color="red" />
                <br />

                <TextField
                  color="primary"
                  value={newUserWords?.join(", ")}
                  multiline
                  fullWidth
                  onChange={(event) => {
                    setNewUserWords(event.target.value.split(",").map((w) => w.trim()));
                  }}
                  type="text"
                  variant="outlined"
                />
              </Box>
            )}
            {Boolean(existingUserWords?.length) && (
              <Box mb={5}>
                <ItalicTitle text="Existing but may need to add to the new lesson:" color="red" />
                <br />
                {existingUserWords?.map((w) => (
                  <Button
                    onClick={() => {
                      setNewUserWords((prev) => [...prev, w]);
                      setExistingNewUserWords((prev) => prev.filter((pw) => pw !== w));
                    }}
                  >
                    {w}
                  </Button>
                ))}
              </Box>
            )}
            {Boolean(newUserWords?.length) && (
              <Box>
                <LoadingButton
                  loading={loading}
                  variant="outlined"
                  onClick={async () => {
                    if (!studentUser) {
                      alert("no user found");
                      return;
                    }
                    setLoading(true);

                    try {
                      let userLesson = await getUserLesson(studentUser?.id);
                      // create new user lesson if there is no lesson for today
                      if (!userLesson) {
                        userLesson = { words: newUserWords, user: studentUser.id };
                        await createUserLesson(userLesson);
                      } else {
                        // update user lesson
                        userLesson = {
                          words: Array.from(
                            new Set([...newUserWords, ...(userLesson?.words || [])]),
                          ),
                          user: studentUser.id,
                        };
                        await updateUserLesson(userLesson);
                      }

                      setStudentWords([...studentWords, ...newUserWords]);

                      // add new words to user words
                      await Promise.all(
                        studentWords.map((word) =>
                          supabaseClient.from("user-words").insert({
                            id: `${studentUser.uuid}-${word}`,
                            user: studentUser.uuid,
                            word,
                          }),
                        ),
                      );

                      setNewUserWords([]);

                      const updatedOrCreatedUserLesson = await getUserLesson(studentUser.id);
                      setNewUserLesson(updatedOrCreatedUserLesson);
                    } catch (err: any) {
                      console.log("🚀 ~ err:", err);
                      alert(err.message);
                    }

                    setLoading(false);
                  }}
                >
                  Add new words to the new lesson
                </LoadingButton>
              </Box>
            )}
            {newUserLesson && Boolean(newUserLesson?.words?.length) && (
              <Box mb={5}>
                <ItalicTitle text="New user lesson" />
                <br />
                <ItalicTitle text={`Words count: ${newUserLesson?.words?.length}`} />
                <br />
                <ItalicTitle text={`Words:`} />
                <Typography>{newUserLesson?.words?.join(", ")}</Typography>
                <br />
                {Boolean(newUserLesson.video_ids?.length) && (
                  <>
                    <ItalicTitle text={`Video ids:`} />
                    <Typography>{newUserLesson.video_ids?.join(", ")}</Typography>
                  </>
                )}
                {Boolean(newUserLesson.sentence_ids?.length) && (
                  <>
                    <ItalicTitle text={`Sentence ids:`} />
                    <Typography>{newUserLesson.sentence_ids?.join(", ")}</Typography>
                  </>
                )}
              </Box>
            )}
          </>
        )}
      </Box>
    );
  }, [
    authUser?.id,
    checkUserPoemsExistence,
    editorState,
    endSession,
    existingUserWords,
    loading,
    needToAddPoems,
    newUserLesson,
    newUserWords,
    poemIds,
    setEditorState,
    setNewUserLesson,
    setStudentUser,
    setStudentWords,
    studentUser,
    studentWords,
    users,
  ]);

  if ((isAdmin(authUser?.id) && isNested) || (!open && isNested)) {
    return <Box>{content()}</Box>;
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={chatModalStyle}>
          <Box mb={20}></Box>
          <Box maxWidth={600} mb={20} width="100%">
            {!isAdmin(authUser?.id) && (
              <>
                <Box display="flex" flexDirection="column" alignItems="revert">
                  {/* {dbChat.text.map((text: string) => {
                    return (
                      <Typography variant="body1" fontSize={25} width="100%" gutterBottom>
                        {text}
                      </Typography>
                    );
                  })} */}
                  <ReactQuill
                    theme="snow"
                    value={editorState}
                    // placeholder="Baba nam kevalam"
                    // onChange={setEditorState}
                    readOnly={true} // Make the editor read-only
                    modules={{ toolbar: false }} // Disable the toolbar
                    // modules={modules}
                    // formats={formats}
                  />
                </Box>
              </>
            )}
            {isAdmin(authUser?.id) && (
              <>
                <HStack pb={5}>
                  {/* <AdminUsers
                    users={users}
                    onChange={(user) => {
                      // setStudentUserId(user?.id || null);
                    }}
                  /> */}
                  <Button variant="contained" color="error" onClick={endSession}>
                    end session
                  </Button>
                </HStack>
                <Box mb={10}>
                  {/* <ReactQuill
                    ref={quillRef}
                    theme="snow"
                    placeholder="Baba nam kevalam"
                    value={editorState}
                    onChange={(text, ...rest) => {
                      setEditorState(text);
                    }}
                    modules={modules}
                    formats={formats}
                  /> */}
                  <ReactQuill
                    theme="snow"
                    ref={quillRef}
                    placeholder="Baba nam kevalam"
                    value={editorState}
                    onChange={(text, delta, source, editor) => {
                      setEditorState(text);
                    }}
                    modules={modules}
                    formats={formats}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Modal>
      {isAdmin(authUser?.id) && (
        <Box
          position="fixed"
          sx={{
            right: 10,
            bottom: 10,
          }}
        >
          <IconButton onClick={handleOpen}>
            <ChatIcon />
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default memo(ControllingStudentPage);

import isAdmin from "../../../common/isAdmin";
import AppsIcon from "@mui/icons-material/Apps";
import { useLessonsStore } from "../../../store/lessonsStore";
import Box from "../Box";
import { Tooltip } from "@mui/material";

const AdminComponentIndicator: React.FC<{ title: string }> = ({ title }) => {
  const authUser = useLessonsStore((state) => state.authUser);

  if (!isAdmin(authUser?.id)) return null;

  return (
    <Box
      sx={{
        cursor: "pointer",
      }}
      onClick={() => {
        navigator.clipboard.writeText(title);
      }}
    >
      <Tooltip title={title}>
        <AppsIcon color="info" />
      </Tooltip>
    </Box>
  );
};

export default AdminComponentIndicator;

import { Box, Typography } from "@mui/material";
import { addDays, addYears } from "date-fns";
import { memo, useCallback, useEffect, useState } from "react";
import supabaseClient from "../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../../store/lessonsStore";
import { SanskritWord } from "../../../types/supabase";
import MenuLayout from "../../Layout/MenuLayout";
import SanskritRepetitionWord from "../../Sentence/SanskritRepetitionWord";
import Loader from "../../Unknown/Loader";
import VStack from "../../Unknown/VStack";
import WhiteBoxWrap from "../../Unknown/WhiteBoxWrap";

const SanskritRepetitionWordsScreen: React.FC = () => {
  const [count, setCount] = useState(0);
  const authUser = useLessonsStore((state) => state.authUser);

  const getTodayWordsCount = useCallback(async () => {
    if (!authUser) return;

    const { count: spWordCount } = await supabaseClient
      .from("sanskrit-words")
      .select("*", { count: "exact", head: true })
      // .like("id", `${authUser.uuid}-%`)
      .lte("repeat", addDays(new Date().setHours(0, 0, 0, 0), 1).toISOString());

    if (spWordCount && (spWordCount < count || (count === 0 && spWordCount >= 2)))
      setCount(spWordCount);
  }, [authUser, count]);

  const [words, setWords] = useState<SanskritWord[] | null>(null);

  useMountedWaitAsyncEffect(async () => {
    if (words === null && authUser) {
      const { data: spWords } = await supabaseClient
        .from("sanskrit-words")
        // .select("*, words(audio_url, transcription, translation)")
        .select()
        // .like("id", `${authUser.uuid}-%`)
        .order("repeat")
        .order("id")
        .limit(45)
        .returns<SanskritWord[]>();
      if (spWords) setWords(spWords);
    }
  }, [words, authUser]);

  const goToNextSlide = async (word: string, period: string) => {
    if (!words || !authUser) return;

    const [currentWord, ...restWords] = words;

    const rowWords = Array.from(new Set(words.map((w) => w.id)));

    if (period === "never" && authUser) {
      setWords(restWords);
      if (count) setCount((prev) => prev - 1);

      await supabaseClient.rpc("update_user_word", {
        userprop: authUser.id,
        input_word: word,
        period: addYears(new Date(), 100),
      });

      const { data: spWord } = await supabaseClient
        .from("sanskrit-words")
        .select()
        .order("repeat")
        .order("id")
        // .like("id", `${authUser.uuid}-%`)
        .range(+period, +period + 1)
        .not("id", "in", `(${rowWords.join(",")})`)
        .limit(1)
        .returns<SanskritWord[]>()
        .single();

      if (spWord && !rowWords.includes(spWord.id)) setWords((prev) => [...restWords, spWord]);
    } else if (period.includes("d") && authUser) {
      setWords(restWords);
      if (count) setCount((prev) => prev - 1);

      await supabaseClient.rpc("update_user_word", {
        userprop: authUser.id,
        input_word: word,
        period: addDays(new Date(), +period.replace("d", "")),
      });

      const { data: spWord } = await supabaseClient
        .from("sanskrit-words")
        .select()
        .order("repeat")
        .order("id")
        // .like("id", `${authUser.uuid}-%`)
        .range(+period, +period + 1)
        .not("id", "in", `(${rowWords.join(",")})`)
        .limit(1)
        .returns<SanskritWord[]>()
        .single();

      if (spWord && !rowWords.includes(spWord.id)) setWords((prev) => [...restWords, spWord]);
    } else {
      setWords(
        restWords.reduce<SanskritWord[]>((prev, cW, index) => {
          const updated = [...prev];
          if (index + 1 === +period) updated.push(currentWord);
          updated.push(cW);
          return updated;
        }, []),
      );
    }

    getTodayWordsCount();
  };

  useEffect(() => {
    if (!words) getTodayWordsCount();
  }, [words, authUser, getTodayWordsCount]);

  const [word] = words || [];

  if (words?.length === 0)
    return (
      <MenuLayout>
        <Box p={2} textAlign="center" sx={{ backgroundColor: "white" }}>
          <Typography variant="h3" py={20}>
            Поки що нема слів для повторення
          </Typography>
        </Box>
      </MenuLayout>
    );

  if (!word)
    return (
      <MenuLayout>
        <WhiteBoxWrap>
          <Loader />
        </WhiteBoxWrap>
      </MenuLayout>
    );

  if (words && words.length < 30) {
    return (
      <MenuLayout>
        <WhiteBoxWrap>
          <VStack px={4} textAlign="center">
            <Typography>Недостатня кількість слів для повторення.</Typography>
            <Typography>
              Після проходження 1-2 уроків, слова автоматично добавляться сюди та буде можливість їх
              повторювати.
            </Typography>
          </VStack>
        </WhiteBoxWrap>
      </MenuLayout>
    );
  }

  return (
    <MenuLayout>
      <Box sx={{ backgroundColor: "white" }}>
        {Boolean(count) && (
          <Typography textAlign="center" pt={2}>
            Залишилось повторити: {count}
          </Typography>
        )}
        {!Boolean(count) && (
          <Typography textAlign="center" pt={2}>
            На сьогодні всі слова повторені, але ти можеш продовжити&nbsp;🚀
          </Typography>
        )}
        <SanskritRepetitionWord
          word={word}
          wordsCount={words!.length}
          goToNextSlide={goToNextSlide}
        />
      </Box>
    </MenuLayout>
  );
};

export default memo(SanskritRepetitionWordsScreen);

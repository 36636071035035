import { Box, Button } from "@mui/material";
import { useState } from "react";
import CreateDialogLesson from "../../CreateDialogLesson";
import HStack from "../../Unknown/HStack";
import WhiteBoxWrap from "../../Unknown/WhiteBoxWrap";
import EnglishAndUkrainianAudioRecord from "../EnglishAndUkrainianAudioRecord";
import AdminVerifyWords from "../AdminVerifyWords";
import WordUpdate from "../WordUpdate";
import AdminAddSentences from "../AdminAddSentences";
import BookSpliter from "../BookSpliter";
import BookSpliter2 from "../BookSpliter2";
import CreateStory from "../CreateStory";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      py={5}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}

const AdminTabs = () => {
  const [tabValue, setTabValue] = useState(0);

  return (
    <>
      <WhiteBoxWrap>
        <HStack>
          {[
            "Verify words",
            "Word update",
            "Book spliter",
            "Book spliter 2",
            "Create Story",
            "Add sentences",
            "En/Uk mp3",
            "Create Dialog Lesson",
          ].map((label, index) => {
            return (
              <Button key={label} variant="outlined" onClick={() => setTabValue(index)}>
                {label}
              </Button>
            );
          })}
        </HStack>
      </WhiteBoxWrap>
      <WhiteBoxWrap>
        {[
          <AdminVerifyWords />,
          <WordUpdate />,
          <BookSpliter />,
          <BookSpliter2 />,
          <CreateStory />,
          <AdminAddSentences />,
          <EnglishAndUkrainianAudioRecord />,
          <CreateDialogLesson />,
        ].map((children, index) => {
          return (
            <CustomTabPanel key={index} value={tabValue} index={index}>
              {children}
            </CustomTabPanel>
          );
        })}
      </WhiteBoxWrap>
    </>
  );
};

export default AdminTabs;

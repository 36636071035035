import { Link, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Button from "../Button";
import { useLessonsStore } from "../../../store/lessonsStore";
import isAdmin from "../../../common/isAdmin";

const CopyStoryIdButton: React.FC<{ id: string; admin?: boolean }> = ({ id, admin }) => {
  const authUser = useLessonsStore((state) => state.authUser);

  if (admin && !isAdmin(authUser?.id)) return null;

  return (
    <Button
      // sx={{ textDecoration: "underline" }}
      variant="contained"
      color="info"
      onClick={() => {
        navigator.clipboard.writeText(id);
      }}
    >
      <Tooltip title="copy story id">
        <ContentCopyIcon />
      </Tooltip>
    </Button>
  );
};

export default CopyStoryIdButton;

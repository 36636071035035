const storiesData = [
  {
    row_new_words: ["I", "dig", "the"],
    words: ["I", "dig", "sit", "mix", "it", "zip", "am", "on", "top", "in", "the", "end"],
    link: null,
    type: "pdf",
    youtube_id: null,
    storage_link: null,
    id: "I dig.\nI sit.\nI mix it.\nI zip it.\nI am on top.\nI am in it.\nThe end.",
    story_id: 1755,
    word_count: 12,
    all_intersection_count: 9,
    new_words_count: 3,
  },
  {
    row_new_words: ["thank", "you're", "welcome"],
    words: ["here", "you", "are", "thank", "you're", "welcome"],
    link: "7e6wNF-WcI4",
    type: "song",
    youtube_id: "7e6wNF-WcI4",
    storage_link: null,
    id: "Here you are. Thank you. You're welcome.",
    story_id: 1850,
    word_count: 6,
    all_intersection_count: 3,
    new_words_count: 3,
  },
  {
    row_new_words: ["the", "gets", "gas", "loves", "so", "fun"],
    words: [
      "the",
      "jet",
      "he",
      "gets",
      "on",
      "has",
      "gas",
      "can",
      "go",
      "up",
      "loves",
      "it",
      "she",
      "a",
      "cup",
      "is",
      "wet",
      "was",
      "so",
      "fun",
      "end",
    ],
    link: null,
    type: "pdf",
    youtube_id: null,
    storage_link: null,
    id: "The jet\nHe gets on.\nThe jet has gas.\nThe jet can go up.\nHe loves it!\nShe has a cup.\nIt is wet.\nIt was so fun!\nThe end.",
    story_id: 1759,
    word_count: 21,
    all_intersection_count: 15,
    new_words_count: 6,
  },
  {
    row_new_words: ["put", "shoes", "let’s", "outside", "hurry", "jacket", "scarf"],
    words: [
      "put",
      "on",
      "your",
      "shoes",
      "let’s",
      "go",
      "outside",
      "hurry",
      "up",
      "jacket",
      "scarf",
      "hat",
      "and",
    ],
    link: "D_FGBpQ0iOg",
    type: "song",
    youtube_id: "D_FGBpQ0iOg",
    storage_link: null,
    id: "Put on your shoes, your shoes, your shoes.\nPut on your shoes, your shoes, your shoes.\nPut on your shoes.\nLet’s go outside.\nHurry up. Hurry up. Hurry hurry up.\n\nPut on your jacket, your jacket, your jacket.\nPut on your jacket, your jacket, your jacket.\nPut on your jacket.\nLet’s go outside.\nHurry up. Hurry up. Hurry hurry up.\n\nPut on your scarf, your scarf, your scarf.\nPut on your scarf, your scarf, your scarf.\nPut on your scarf.\nLet’s go outside.\nHurry up. Hurry up. Hurry hurry up.\n\nPut on your hat, your hat, your hat.\nPut on your hat, your hat, your hat.\nPut on your hat.\nLet’s go outside.\nHurry up. Hurry up. Hurry hurry up.\n\nPut on your shoes.\nYour jacket.\nYour scarf.\nAnd your hat.\nHurry up. Hurry up. Hurry hurry up.",
    story_id: 1845,
    word_count: 13,
    all_intersection_count: 6,
    new_words_count: 7,
  },
  {
    row_new_words: ["spin", "around", "now", "try", "faster", "really", "sp"],
    words: [
      "up",
      "down",
      "stand",
      "sit",
      "and",
      "spin",
      "around",
      "now",
      "let's",
      "try",
      "it",
      "faster",
      "go",
      "really",
      "fast",
      "sp",
    ],
    link: "-iMGSD_35pM",
    type: "song",
    youtube_id: "-iMGSD_35pM",
    storage_link: null,
    id: "Up, up, up, down, down, down? Up, up, up, down, down, down. Up, up, up, down, down, down. Up, up, up, down, down, down? Stand up, Sit down, stand up and spin around. Now sit down. Let's try it faster. Up, up, up, up, down, down, down. Up, up, up, down, down, down. Up, up, up, down, down, down. Up, up, up, down, down, down. Stand up. Sit, Sit down, stand up and spin around. Now sit down. Let's go really fast. Up, up, up, down, down, down. Up, up, up, down, down, down. Up, up, up, down, down, down. Up, up, up, down, down, down? Stand up, sit down, stand up and sp around. Now sit down.",
    story_id: 1846,
    word_count: 16,
    all_intersection_count: 9,
    new_words_count: 7,
  },
  {
    row_new_words: ["jim", "dad", "pam", "love", "nap", "tim", "the"],
    words: [
      "we",
      "go",
      "jim",
      "and",
      "dad",
      "pam",
      "love",
      "to",
      "nap",
      "sit",
      "tim",
      "can",
      "on",
      "pet",
      "the",
      "dog",
      "end",
    ],
    link: null,
    type: "pdf",
    youtube_id: null,
    storage_link: null,
    id: "We go\nJim and dad go.\nWe go.\nDad and Pam\nlove to nap.\nWe love to sit.\nTim can sit on Dad.\nWe pet the dog.\nThe end.",
    story_id: 1758,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["head", "shoulders", "knees", "toes", "eyes", "ears", "mouth", "nose"],
    words: ["head", "shoulders", "knees", "and", "toes", "eyes", "ears", "mouth", "nose"],
    link: "3KdzRdrBJeM",
    type: "song",
    youtube_id: "3KdzRdrBJeM",
    storage_link: null,
    id: "Head, shoulders, knees, and toes, knees and toes.\nHead, shoulders, knees, and toes, knees and toes.\nAnd eyes and ears and mouth and nose.\nHead, shoulders, knees, and toes, knees and toes.",
    story_id: 1855,
    word_count: 9,
    all_intersection_count: 1,
    new_words_count: 8,
  },
  {
    row_new_words: ["sad", "wag", "yes", "nap", "jen", "love", "fed", "the"],
    words: [
      "big",
      "red",
      "is",
      "a",
      "dog",
      "he",
      "not",
      "sad",
      "can",
      "you",
      "wag",
      "sit",
      "yes",
      "go",
      "we",
      "let",
      "it",
      "nap",
      "and",
      "jen",
      "love",
      "to",
      "get",
      "fed",
      "the",
      "end",
    ],
    link: null,
    type: "pdf",
    youtube_id: null,
    storage_link: null,
    id: "Big Red\nBig Red is a big red dog.\nHe is not sad.\nCan you wag, big Red?\nCan he sit?\nYes!\nCan he go?\nYes!\nBig Red, we can let it nap.\nBig Red and Jen love to get fed.\nThe end.",
    story_id: 1760,
    word_count: 26,
    all_intersection_count: 18,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "how’s",
      "the",
      "weather",
      "sunny",
      "rainy",
      "cloudy",
      "snowy",
      "let’s",
      "outside",
    ],
    words: [
      "how’s",
      "the",
      "weather",
      "today",
      "is",
      "it",
      "sunny",
      "rainy",
      "cloudy",
      "snowy",
      "let’s",
      "look",
      "outside",
    ],
    link: "D1qV5PsDoQw",
    type: "song",
    youtube_id: "D1qV5PsDoQw",
    storage_link: null,
    id: "How’s the weather? How’s the weather?\nHow’s the weather today?\nIs it sunny?\nIs it rainy?\nIs it cloudy?\nIs it snowy?\nHow’s the weather today?\n\nLet’s look outside.\nHow’s the weather?\nIs it sunny today?\nLet’s look outside.\nHow’s the weather?\nIs it rainy today?\nLet’s look outside.\nHow’s the weather?\nIs it cloudy today?\nLet’s look outside.\nIs it snowy today?\n\nHow’s the weather? How’s the weather?\nHow’s the weather today?\nIs it sunny?\nIs it rainy?\nIs it cloudy?\nIs it snowy?\nHow’s the weather today?",
    story_id: 1712,
    word_count: 13,
    all_intersection_count: 4,
    new_words_count: 9,
  },
  {
    row_new_words: [
      "everybody",
      "move",
      "slowly",
      "hands",
      "by",
      "side",
      "quiet",
      "sh",
      "the",
      "leader",
    ],
    words: [
      "line",
      "up",
      "everybody",
      "let's",
      "make",
      "a",
      "move",
      "slowly",
      "hands",
      "by",
      "your",
      "side",
      "quiet",
      "please",
      "sh",
      "follow",
      "the",
      "leader",
      "here",
      "we",
      "go",
    ],
    link: "ciE3JOaiU_k",
    type: "song",
    youtube_id: "ciE3JOaiU_k",
    storage_link: null,
    id: "Line up. Line up. Everybody line up. Line up. Line up. Let's make a line. Line up. Line up. Everybody line up. Line up. Line up. Let's make a line. Move slowly. Hands by your side. Quiet, please. Sh. Follow the leader. Here we go. Line up. Line up. Everybody line up. Line up. Line up. Let's make a line. Line up. Line up. Everybody line up. Line up. Line up. Let's make a line. Move slowly. Hands by your side. Quiet, please. Sh. Follow the leader. Here we go. Line up. Line up. Everybody line up. Line up. Line up. Let's make a line. Line up. Line up. Everybody line up. Line up. Line up. Let's make a line.",
    story_id: 1853,
    word_count: 21,
    all_intersection_count: 11,
    new_words_count: 10,
  },
  {
    row_new_words: [
      "noodle",
      "pals",
      "left",
      "right",
      "stomp",
      "the",
      "hop",
      "wiggle",
      "clap",
      "ready",
    ],
    words: [
      "noodle",
      "and",
      "pals",
      "left",
      "right",
      "let's",
      "stomp",
      "to",
      "the",
      "hop",
      "wiggle",
      "clap",
      "ready",
    ],
    link: "wDKI9cqv474",
    type: "song",
    youtube_id: "wDKI9cqv474",
    storage_link: null,
    id: "Noodle and pals? Left and right, left and right, left and right, left and right? Let's stomp, stomp to the left? Stomp to the right? Stomp to the left? Stomp to the right? Let's hop, hop to the left? Hop to the right? Hop to the left, Hop to the right? Left and right, left and right? Left and right, left and right? Let's wiggle, wiggle to the left? Wiggle to the right? Wiggle to the left? Wiggle to the right? Let's clap, clap to the left? Clap to the right? Clap to the left, Clap to the right? Left and right, left and right, Left and right, left and right. Ready? Left and right, left and right, Left and right, Left and right.",
    story_id: 1863,
    word_count: 13,
    all_intersection_count: 3,
    new_words_count: 10,
  },
  {
    row_new_words: [
      "hungry",
      "yes",
      "I",
      "mmm",
      "yum",
      "an",
      "grapes",
      "watermelon",
      "I'm",
      "full",
    ],
    words: [
      "are",
      "you",
      "hungry",
      "yes",
      "I",
      "am",
      "mmm",
      "a",
      "banana",
      "yum",
      "an",
      "apple",
      "grapes",
      "watermelon",
      "I'm",
      "full",
    ],
    link: "azds40V5vZ0",
    type: "song",
    youtube_id: "azds40V5vZ0",
    storage_link: null,
    id: "Are you hungry? Yes, I am. Are you hungry? Yes, I am. Mmm. A banana. Yum, yum, yum, yum, yum, yum, yum, yum, yum. Are you hungry? Yes, I am. Are you hungry? Yes, I am. Mmm. An apple. Yum, yum, yum, yum, yum, yum, yum, yum, yum, yum. Are you hungry? Yes, I am. Are you hungry? Yes, I am. Mmm. Grapes. Yum, yum, yum, yum, yum, yum, yum, yum, yum. Are you hungry? Yes, I am. Are you hungry? Yes, I am. Mmm. Watermelon. Yum, yum, yum, yum, yum, yum, yum, yum, yum. I'm full.",
    story_id: 1857,
    word_count: 16,
    all_intersection_count: 6,
    new_words_count: 10,
  },
  {
    row_new_words: [
      "dear",
      "from",
      "good",
      "friends",
      "true",
      "old",
      "new",
      "may",
      "luck",
      "happiness",
    ],
    words: [
      "happy",
      "birthday",
      "to",
      "you",
      "dear",
      "one",
      "from",
      "good",
      "friends",
      "and",
      "true",
      "old",
      "new",
      "may",
      "luck",
      "go",
      "with",
      "happiness",
      "too",
    ],
    link: "_z-1fTlSDF0",
    type: "song",
    youtube_id: "_z-1fTlSDF0",
    storage_link: null,
    id: "Happy Birthday to You\nHappy Birthday to You\nHappy Birthday Dear one\nHappy Birthday to You.\nFrom good friends and true,\nFrom old friends and new,\nMay good luck go with you,\nAnd happiness too.",
    story_id: 1869,
    word_count: 19,
    all_intersection_count: 9,
    new_words_count: 10,
  },
  {
    row_new_words: [
      "rain",
      "away",
      "come",
      "again",
      "another",
      "brother",
      "sister",
      "all",
      "the",
      "family",
    ],
    words: [
      "rain",
      "go",
      "away",
      "come",
      "again",
      "another",
      "day",
      "daddy",
      "wants",
      "to",
      "play",
      "mommy",
      "brother",
      "sister",
      "baby",
      "all",
      "the",
      "family",
    ],
    link: "LFrKYjrIDs8",
    type: "song",
    youtube_id: "LFrKYjrIDs8",
    storage_link: null,
    id: "Rain, rain, go away\nCome again another day\nDaddy wants to play\n\nRain, rain, go away\nCome again another day\nMommy wants to play\n\nRain, rain, go away\nCome again another day\nBrother wants to play\n\nRain, rain, go away \nCome again another day\nSister wants to play\n\nRain, rain, go away\nCome again another day\nBaby wants to play\n\nRain, rain, go away\nCome again another day\nAll the family family wants to play\n\nRain, rain, go away",
    story_id: 1599,
    word_count: 18,
    all_intersection_count: 8,
    new_words_count: 10,
  },
  {
    row_new_words: [
      "the",
      "way",
      "face",
      "early",
      "morning",
      "comb",
      "hair",
      "brush",
      "teeth",
      "dressed",
      "school",
    ],
    words: [
      "this",
      "is",
      "the",
      "way",
      "we",
      "wash",
      "our",
      "face",
      "early",
      "in",
      "morning",
      "comb",
      "hair",
      "brush",
      "teeth",
      "get",
      "dressed",
      "go",
      "to",
      "school",
    ],
    link: "pEx5entLOFQ",
    type: "song",
    youtube_id: "pEx5entLOFQ",
    storage_link: null,
    id: "This is the way we wash our face, Wash our face, wash our face? This is the way we wash our face early in the morning? Wash, wash, wash, wash, wash, wash, wash? This is the way we comb our hair, comb our hair, comb our hair? This is the way we comb our hair early in the morning? Comb, comb, comb, comb, comb, comb, comb? This is the way we brush our teeth, brush our teeth, brush our teeth? This is the way we brush our teeth early in the morning? Brush, brush, brush, brush, brush, brush, brush? This is the way we get dressed, get dressed, get dressed? This is the way we get dressed early in the morning? This is the way we go to school, go to school, go to school? This is the way we go to school early in the morning.",
    story_id: 1837,
    word_count: 20,
    all_intersection_count: 9,
    new_words_count: 11,
  },
  {
    row_new_words: ["I", "hop", "lot", "mop", "rag", "pot", "pop", "brown", "wag", "stop", "away"],
    words: [
      "I",
      "like",
      "to",
      "hop",
      "a",
      "lot",
      "up",
      "down",
      "blue",
      "mop",
      "red",
      "top",
      "big",
      "bag",
      "little",
      "rag",
      "hot",
      "pot",
      "pop",
      "brown",
      "dog",
      "wag",
      "stop",
      "away",
    ],
    link: "(1)KVU8dFsBhtU",
    type: "video",
    youtube_id: "KVU8dFsBhtU",
    storage_link: null,
    id: "I like to hop. I hop a lot. I hop up. I hop down. I hop to a blue mop. I hop to a red top. I hop to a big bag. I hop to a little rag. I hop to a hot pot. Pop, pop, pop. I hop to a brown dog. Wag wag wag. Stop stop, stop. I hop away. Hop hop hop hop.",
    story_id: 1752,
    word_count: 24,
    all_intersection_count: 13,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "faith",
      "fine",
      "invention",
      "for",
      "gentlemen",
      "who",
      "but",
      "microscopes",
      "prudent",
      "an",
      "emergency",
    ],
    words: [
      "faith",
      "is",
      "a",
      "fine",
      "invention",
      "for",
      "gentlemen",
      "who",
      "see",
      "but",
      "microscopes",
      "are",
      "prudent",
      "in",
      "an",
      "emergency",
    ],
    link: "https://rainydaypoems.com/poems-for-kids/poems-for-kids-to-recite/faith-by-emily-dickinson/",
    type: "poem",
    youtube_id: null,
    storage_link: null,
    id: "Faith is a fine invention\nFor gentlemen who see;\nBut microscopes are prudent\nIn an emergency!",
    story_id: 1908,
    word_count: 16,
    all_intersection_count: 5,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "despite",
      "all",
      "I",
      "cannot",
      "bring",
      "myself",
      "regret",
      "single",
      "moment",
      "spent",
      "loving",
    ],
    words: [
      "and",
      "despite",
      "it",
      "all",
      "I",
      "cannot",
      "bring",
      "myself",
      "to",
      "regret",
      "a",
      "single",
      "moment",
      "spent",
      "loving",
      "you",
    ],
    link: "https://www.familyfriendpoems.com/poem/despite-it-all",
    type: "poem",
    youtube_id: null,
    storage_link: null,
    id: "And despite\nit all,\nI cannot\nbring myself\nto regret\na single\nmoment\nI spent\nloving you.",
    story_id: 930,
    word_count: 16,
    all_intersection_count: 5,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "noodle",
      "pals",
      "clean",
      "everybody",
      "put",
      "things",
      "away",
      "pick",
      "toys",
      "books",
      "shoes",
    ],
    words: [
      "noodle",
      "and",
      "pals",
      "clean",
      "up",
      "everybody",
      "let's",
      "put",
      "your",
      "things",
      "away",
      "pick",
      "toys",
      "books",
      "shoes",
    ],
    link: "IMd3oj7W4FU",
    type: "song",
    youtube_id: "IMd3oj7W4FU",
    storage_link: null,
    id: "Noodle and pals Clean up, clean up Everybody, let's clean up Clean up, clean up Put your things away Clean up, clean up? Everybody, let's clean up Clean up, clean up Put your things away Clean up, clean up? Everybody, let's clean up Clean up, clean up Put your things away Clean up, clean up Everybody, let's clean up Clean up, clean up Put your things away Clean up, clean up, clean up Put your things away Pick up your toys Pick up your books Pick up your shoes Put your things away Clean up, clean up Everybody, let's clean up Clean up, clean up Put your things away Clean up, clean up Everybody, let's clean up Clean up, clean up Put your things away.",
    story_id: 1864,
    word_count: 15,
    all_intersection_count: 4,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "the",
      "world",
      "so",
      "full",
      "of",
      "number",
      "things",
      "I'm",
      "should",
      "all",
      "kings",
    ],
    words: [
      "the",
      "world",
      "is",
      "so",
      "full",
      "of",
      "a",
      "number",
      "things",
      "I'm",
      "sure",
      "we",
      "should",
      "all",
      "be",
      "as",
      "happy",
      "kings",
    ],
    link: "(10)https://www.splashlearn.com/blog/best-kids-poems-to-entice-a-love-for-reading-poetry/",
    type: "poem",
    youtube_id: null,
    storage_link: null,
    id: "The world is so full\nof a number of things,\nI’m sure we should all\nbe as happy as kings.",
    story_id: 1897,
    word_count: 18,
    all_intersection_count: 7,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "I",
      "never",
      "saw",
      "purple",
      "cow",
      "hope",
      "but",
      "tell",
      "anyhow",
      "I'd",
      "rather",
      "than",
    ],
    words: [
      "I",
      "never",
      "saw",
      "a",
      "purple",
      "cow",
      "hope",
      "to",
      "see",
      "one",
      "but",
      "can",
      "tell",
      "you",
      "anyhow",
      "I'd",
      "rather",
      "than",
      "be",
    ],
    link: "https://www.splashlearn.com/blog/best-kids-poems-to-entice-a-love-for-reading-poetry/",
    type: "poem",
    youtube_id: null,
    storage_link: null,
    id: "I never saw a purple cow,\nI never hope to see one,\nBut I can tell you, anyhow,\nI’d rather see than be one!",
    story_id: 1888,
    word_count: 19,
    all_intersection_count: 7,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "the",
      "jog",
      "dan",
      "fog",
      "by",
      "log",
      "but",
      "jumps",
      "over",
      "good",
      "said",
      "lot",
    ],
    words: [
      "this",
      "is",
      "a",
      "dog",
      "the",
      "can",
      "jog",
      "with",
      "dan",
      "they",
      "in",
      "fog",
      "by",
      "van",
      "man",
      "to",
      "log",
      "but",
      "too",
      "big",
      "walk",
      "jumps",
      "over",
      "good",
      "said",
      "you",
      "do",
      "lot",
    ],
    link: "(1)XofVFIIDLdk",
    type: "song",
    youtube_id: "XofVFIIDLdk",
    storage_link: null,
    id: "This is a dog. The dog can jog. The dog can jog with Dan. They jog in the fog. They jog by a dog. They jog by a van. They jog by a man. They jog to a log. But the log is too big. Walk the dog jumps over the log. Good dog, said Dan. You can do a lot.",
    story_id: 1749,
    word_count: 28,
    all_intersection_count: 16,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "tap",
      "point",
      "put",
      "head",
      "nose",
      "chin",
      "arm",
      "leg",
      "foot",
      "now",
      "wave",
      "goodbye",
    ],
    words: [
      "one",
      "little",
      "finger",
      "tap",
      "point",
      "your",
      "up",
      "down",
      "put",
      "it",
      "on",
      "head",
      "nose",
      "chin",
      "arm",
      "leg",
      "foot",
      "now",
      "let's",
      "wave",
      "goodbye",
    ],
    link: "EqVHR2vIwIE",
    type: "song",
    youtube_id: "EqVHR2vIwIE",
    storage_link: null,
    id: "One little finger. One little finger. One little finger. Tap, tap, tap. Point your finger up. Point your finger down. Put it on your head. Head. One little finger. One little finger. Finger. One little finger. Tap, tap, tap. Point your finger up. Point your finger down. Put it on your nose. Nose. One little finger. One little finger. One little finger. Tap, tap, tap. Point your finger up. Point your finger down. Put it on your chin. Chin. One little finger. One little finger. One little finger. Tap, tap, tap. Point your finger up. Point your finger down. Put it on your arm. Arm. One little finger. One little finger. One little finger. Tap, tap, tap. Point your finger up. Point your finger down. Put it on your leg. Leg. One little finger. One little finger. One little finger. Tap, tap, tap. Point your finger up. Point your finger down. Put it on your foot. Foot. Put it on your leg. Leg. Put it on your arm. Arm. Put it on your chin. Chin. Put it on your nose. Nose. Put it on your head. Head. Now let's wave goodbye. Goodbye.",
    story_id: 1841,
    word_count: 21,
    all_intersection_count: 9,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "repeat",
      "after",
      "wrists",
      "together",
      "the",
      "jellyfish",
      "elbows",
      "knees",
      "feet",
      "jelly",
      "head",
      "back",
    ],
    words: [
      "repeat",
      "after",
      "me",
      "arms",
      "up",
      "wrists",
      "together",
      "the",
      "jellyfish",
      "elbows",
      "knees",
      "feet",
      "jelly",
      "fish",
      "head",
      "back",
    ],
    link: "rqhYoUhjook",
    type: "song",
    youtube_id: "rqhYoUhjook",
    storage_link: null,
    id: "Repeat after me. Arms up, wrists together. The jellyfish. The jellyfish. The jellyfish. The jellyfish. Arms up, wrists together, elbows together. The jellyfish. The jellyfish. The jellyfish. The jellyfish. Arms up, wrists together, elbows together, knees together. The jellyfish. The jellyfish. The jellyfish. The jellyfish. Arms up, wrists together. Elbows together, knees together, feet together. The jellyfish. The jellyfish. The jellyfish. The jelly fish. Arms up, wrists together, elbows together, knees together, feet together, head back. The jellyfish. The jellyfish. The jellyfish. The jellyfish.",
    story_id: 1856,
    word_count: 16,
    all_intersection_count: 4,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "pray",
      "rick",
      "may",
      "the",
      "kids",
      "god",
      "ann",
      "ray",
      "stay",
      "of",
      "jill",
      "will",
    ],
    words: [
      "pray",
      "it",
      "is",
      "day",
      "rick",
      "may",
      "look",
      "she",
      "can",
      "play",
      "and",
      "the",
      "kids",
      "to",
      "god",
      "ann",
      "ray",
      "stay",
      "end",
      "of",
      "jill",
      "will",
    ],
    link: null,
    type: "pdf",
    youtube_id: null,
    storage_link: null,
    id: "Pray\nIt is day\nRick may pray\nLook! She can play, and she can pray.\nThe kids pray to God.\nAnn and Ray\nstay and pray.\nIt is the end of the day.\nJill will pray.\nThe end.",
    story_id: 1768,
    word_count: 22,
    all_intersection_count: 10,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "I",
      "never",
      "saw",
      "purple",
      "cow",
      "hope",
      "but",
      "tell",
      "anyhow",
      "I'd",
      "rather",
      "than",
    ],
    words: [
      "I",
      "never",
      "saw",
      "a",
      "purple",
      "cow",
      "hope",
      "to",
      "see",
      "one",
      "but",
      "can",
      "tell",
      "you",
      "anyhow",
      "I'd",
      "rather",
      "than",
      "be",
    ],
    link: "https://rainydaypoems.com/poems-for-kids/funny-poems-for-kids/the-purple-cow-gelett-burgess/",
    type: "poem",
    youtube_id: null,
    storage_link: null,
    id: "I never saw a Purple Cow,\nI never hope to see one;\nBut I can tell you, anyhow,\nI’d rather see than be one.",
    story_id: 1900,
    word_count: 19,
    all_intersection_count: 7,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "I",
      "never",
      "saw",
      "purple",
      "cow",
      "hope",
      "but",
      "tell",
      "anyhow",
      "I'd",
      "rather",
      "than",
    ],
    words: [
      "I",
      "never",
      "saw",
      "a",
      "purple",
      "cow",
      "hope",
      "to",
      "see",
      "one",
      "but",
      "can",
      "tell",
      "you",
      "anyhow",
      "I'd",
      "rather",
      "than",
      "be",
    ],
    link: "(6)https://www.planetspark.in/blogs/english-poems-for-kids",
    type: "poem",
    youtube_id: null,
    storage_link: null,
    id: "I never saw a purple cow,\nI never hope to see one,\nBut I can tell you, anyhow,\nI’d rather see than be one!",
    story_id: 1881,
    word_count: 19,
    all_intersection_count: 7,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "the",
      "log",
      "fog",
      "so",
      "nap",
      "or",
      "of",
      "love",
      "web",
      "hop",
      "fit",
      "yes",
    ],
    words: [
      "the",
      "log",
      "is",
      "in",
      "hot",
      "sun",
      "fog",
      "look",
      "it",
      "so",
      "wet",
      "a",
      "cat",
      "has",
      "nap",
      "she",
      "or",
      "on",
      "top",
      "of",
      "do",
      "you",
      "love",
      "web",
      "can",
      "hop",
      "he",
      "fit",
      "yes",
      "end",
    ],
    link: null,
    type: "pdf",
    youtube_id: null,
    storage_link: null,
    id: "The log\nThe log is in the hot sun.\nThe log is in the fog.\nLook, it is so wet.\nA cat has a nap.\nIs she in the log or on top of the log?\nDo you love the web on the log?\nLook! It can hop on top of the log!\nCan he fit on the log?\nYes, he can!\nThe end.",
    story_id: 1763,
    word_count: 30,
    all_intersection_count: 18,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "the",
      "dad",
      "or",
      "love",
      "his",
      "mom",
      "log",
      "no",
      "of",
      "kit",
      "loves",
      "fun",
    ],
    words: [
      "the",
      "fox",
      "look",
      "at",
      "dad",
      "he",
      "can",
      "run",
      "and",
      "is",
      "in",
      "it",
      "or",
      "on",
      "do",
      "you",
      "love",
      "his",
      "bed",
      "mom",
      "she",
      "log",
      "no",
      "top",
      "of",
      "has",
      "a",
      "kit",
      "loves",
      "fun",
      "end",
    ],
    link: null,
    type: "pdf",
    youtube_id: null,
    storage_link: null,
    id: "The fox\nLook at the dad fox.\nHe can run and run.\nIs he in it or on it?\nDo you love his bed?\nLook at the mom fox.\nIs she in the log?\nNo, she is on top of the log.\nLook! She has a kit!\nShe loves the kit.\nThe kit has fun.\nThe end.",
    story_id: 1762,
    word_count: 31,
    all_intersection_count: 19,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "beth",
      "loves",
      "math",
      "the",
      "by",
      "thin",
      "path",
      "seth",
      "fun",
      "sits",
      "also",
      "spot",
      "way",
    ],
    words: [
      "beth",
      "loves",
      "math",
      "to",
      "do",
      "on",
      "the",
      "bed",
      "she",
      "does",
      "it",
      "at",
      "this",
      "desk",
      "by",
      "thin",
      "path",
      "with",
      "seth",
      "is",
      "fun",
      "cat",
      "sits",
      "them",
      "also",
      "a",
      "spot",
      "best",
      "way",
      "end",
    ],
    link: null,
    type: "pdf",
    youtube_id: null,
    storage_link: null,
    id: "Beth loves math\nBeth loves to do math on the bed.\nShe does it at this desk.\nShe does math by the thin path.\nMath with Seth is fun.\nThe cat sits with them.\nThis is also a fun spot to do math.\nThis is the best way to do math!\nThe end.",
    story_id: 1771,
    word_count: 30,
    all_intersection_count: 17,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "short",
      "legs",
      "pointy",
      "ears",
      "the",
      "throws",
      "toy",
      "across",
      "tells",
      "watches",
      "back",
      "dog's",
      "mouth",
    ],
    words: [
      "a",
      "white",
      "dog",
      "she",
      "has",
      "it",
      "is",
      "short",
      "legs",
      "pointy",
      "ears",
      "runs",
      "very",
      "fast",
      "plays",
      "with",
      "the",
      "throws",
      "toy",
      "across",
      "room",
      "tells",
      "to",
      "go",
      "get",
      "watches",
      "back",
      "her",
      "in",
      "dog's",
      "mouth",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2043.htm",
    type: null,
    youtube_id: null,
    storage_link: null,
    id: "A white dog\n\nShe has a dog. It is white. It has short legs. It has pointy ears. It runs very fast. She plays with the dog. She throws a toy across the room. She tells the dog to go get it. The dog runs. She watches it. The dog runs back to her. The toy is in the dog's mouth.",
    story_id: 455,
    word_count: 31,
    all_intersection_count: 18,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "enough",
      "always",
      "been",
      "will",
      "forever",
      "no",
      "matter",
      "feel",
      "or",
      "the",
      "lies",
      "may",
      "believe",
    ],
    words: [
      "you",
      "are",
      "enough",
      "have",
      "always",
      "been",
      "and",
      "will",
      "forever",
      "be",
      "no",
      "matter",
      "what",
      "see",
      "how",
      "feel",
      "or",
      "the",
      "lies",
      "may",
      "believe",
    ],
    link: "https://www.familyfriendpoems.com/poem/you-are-enough",
    type: "poem",
    youtube_id: null,
    storage_link: null,
    id: "You are enough\nYou have always been enough, and\nYou will forever be\nEnough\n\nno matter what you see\nhow you feel, or the lies you may\nbelieve",
    story_id: 927,
    word_count: 21,
    all_intersection_count: 8,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "evening",
      "when",
      "I",
      "the",
      "starshine",
      "overhead",
      "daysies",
      "dart",
      "midadle",
      "of",
      "night",
      "dark",
      "middle",
    ],
    words: [
      "that",
      "evening",
      "when",
      "I",
      "go",
      "to",
      "bed",
      "see",
      "the",
      "starshine",
      "overhead",
      "they",
      "are",
      "little",
      "daysies",
      "white",
      "dart",
      "midadle",
      "of",
      "night",
      "at",
      "dark",
      "middle",
    ],
    link: "MSo_1WRkNf0",
    type: "song",
    youtube_id: "MSo_1WRkNf0",
    storage_link: null,
    id: "That evening when I go to bed I see the starshine overhead. They are the little daysies white that dart the midadle of the night of the night. At evening when I go to bed I see the starshine overhead. They are the little daysies white that dark the middle of the night of the night.",
    story_id: 1673,
    word_count: 23,
    all_intersection_count: 10,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "twinkle",
      "la",
      "star",
      "I",
      "wonder",
      "above",
      "the",
      "world",
      "so",
      "diamond",
      "sky",
      "littleow",
      "leo",
    ],
    words: [
      "twinkle",
      "la",
      "star",
      "how",
      "I",
      "wonder",
      "what",
      "you",
      "are",
      "up",
      "above",
      "the",
      "world",
      "so",
      "high",
      "like",
      "a",
      "diamond",
      "in",
      "sky",
      "littleow",
      "leo",
      "little",
    ],
    link: "yCjJyiqpAuU",
    type: "song",
    youtube_id: "yCjJyiqpAuU",
    storage_link: null,
    id: "Twinkle, twinkle la star How I wonder what you are up above the world so high Like a diamond in the sky Twinkle, twinkle littleow star How I wonder what you are Twinkle, twinkle Leo star How I wonder what you are up above the world so high Like a diamond in the sky Twinkle, twinkle little little star How I wonder what you are",
    story_id: 1595,
    word_count: 23,
    all_intersection_count: 10,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "I",
      "you're",
      "washing",
      "car",
      "yes",
      "often",
      "washed",
      "yesterday",
      "I'm",
      "now",
      "gonna",
      "tomorrow",
      "really",
    ],
    words: [
      "I",
      "see",
      "you're",
      "washing",
      "your",
      "car",
      "yes",
      "am",
      "do",
      "you",
      "wash",
      "it",
      "very",
      "often",
      "washed",
      "yesterday",
      "I'm",
      "now",
      "and",
      "gonna",
      "tomorrow",
      "really",
      "like",
      "to",
      "sure",
      "every",
      "day",
    ],
    link: "https://www.youtube.com/watch?v=w24IRWsCL48&list=PLD7AA7B1BC72ACC28",
    type: "video",
    youtube_id: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/washing-1.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=jXQWqVbylqSFLbOBYZrQ0rXJyWtYW9K%2BD5hAGr%2FHJ9%2BgseT2aejDb7s6FLx1RG4n590mrXRZw7eYPF1U2VW%2BNgDOzMGUjjEkqkX%2BA%2FunxoNJLgAwSC7k%2BlAR1wymOuQQ4XfN22Dr6YrmxUqwsoFAF0rhscXF6XGFZkf2x5hdZAMMBLZr31Bf9ivqNGAZZ6g4QiYfPd%2Fs2K2atW83%2B7sjdj5H9ud47%2ByjheHW06casNKirM5WBDYDh3PvMfH0SsviFbaMmO6Q6rY4CwXxuH8id5oHMWSnQWc23iFGzEGigXIadb6FFlGb1hEnmiqdc%2Fpj6pJbRexvAPU9tr1UC3UMaQ%3D%3D",
    id: "I see you're washing your car.\nYes. I am.\nDo you wash it very often?\nYes. I washed it yesterday. I'm washing it now, and I'm gonna wash it tomorrow.\nYou really like to wash your car.\nI sure do. I wash it every day.",
    story_id: 1011,
    word_count: 27,
    all_intersection_count: 14,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "starlight",
      "star",
      "bright",
      "first",
      "I",
      "tonight",
      "wish",
      "may",
      "might",
      "the",
      "say",
      "mighth",
      "night",
    ],
    words: [
      "starlight",
      "star",
      "bright",
      "first",
      "I",
      "see",
      "tonight",
      "wish",
      "may",
      "might",
      "have",
      "the",
      "say",
      "mighth",
      "to",
      "night",
    ],
    link: "OWip7yvXukI",
    type: "song",
    youtube_id: "OWip7yvXukI",
    storage_link: null,
    id: "Starlight star bright first star I see tonight Wish I may Wish I might have the wish I wish tonight Starlight, star bright first star I say tonight Wish I may wish I might have the wish I wish tonight Starlight, star bright first star I see tonight Wish I may, Wish I might have the wish I wish tonight Starlight, star bright first star I see tonight Wish I may wish I mighth have the wish I wish to night",
    story_id: 1597,
    word_count: 16,
    all_intersection_count: 3,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "did",
      "yesterday",
      "I",
      "the",
      "beach",
      "oh",
      "who",
      "nobody",
      "by",
      "myself",
      "yourself",
      "yes",
      "great",
    ],
    words: [
      "what",
      "did",
      "you",
      "do",
      "yesterday",
      "I",
      "went",
      "to",
      "the",
      "beach",
      "oh",
      "who",
      "go",
      "with",
      "nobody",
      "by",
      "myself",
      "yourself",
      "yes",
      "had",
      "a",
      "great",
      "time",
    ],
    link: "https://www.youtube.com/watch?v=Yf5RXW3zAAk&list=PLD7AA7B1BC72ACC28&index=18",
    type: "story",
    youtube_id: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/yourself-1.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=H45s39J3xVFjoUYQmJOu%2BpxCyj%2FMCEG1pn%2Frk4JXslscMDB4T1xo7R%2B9wLa2xrs9Wd9qxzUDjbmQYIcBBZYOSxfgUIDrP%2B%2Bi4qUZoL%2BVk7LKLgwaWCmslhvRWqxbVd5q%2BtiQLMx55N9dDdwyYZl8jX8d%2FJWzsOG8y4ALt24%2Fl8iiJx75U4pb7eLYaDoBHF8BVl3lNOLfvhEPZ4svTXKsJ4oCg0%2FwJzluWx3v1P4hYBK6tvpg2Wm%2FJwdXQNTfAgx0BMdXqSD2hLHhSzblsFULE224fIy1cQLnlxfbNduoslK3OGOC2qIMNOke7ptmXC4%2BbXog16DR%2BiwUnGVwnXX19Q%3D%3D",
    id: "What did you do yesterday?\nI went to the beach.\nOh, who did you go to the beach with?\nNobody.\nI went to the beach by myself.\nYou went by yourself?\nYes. I had a great time.",
    story_id: 1051,
    word_count: 23,
    all_intersection_count: 10,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "walking",
      "hop",
      "running",
      "now",
      "stop",
      "tip",
      "toe",
      "swimming",
      "wake",
      "it's",
      "ready",
      "okay",
      "runningning",
      "u",
    ],
    words: [
      "walking",
      "hop",
      "running",
      "now",
      "let's",
      "stop",
      "tip",
      "toe",
      "jump",
      "swimming",
      "sleep",
      "wake",
      "up",
      "it's",
      "time",
      "to",
      "go",
      "are",
      "you",
      "ready",
      "fast",
      "okay",
      "run",
      "and",
      "let",
      "runningning",
      "u",
    ],
    link: "fPMjnlTEZwU",
    type: "song",
    youtube_id: "fPMjnlTEZwU",
    storage_link: null,
    id: "Walking, walking, walking, walking. Hop, hop, hop hop hop hop. Running, running, running, running, running, running. Now let's stop. Now let's stop. Walking, walking, walking, walking. Hop, hop, hop, hop hop hop. Running, running, running, running, running, running. Now let's stop. Now let's stop. Tip toe tip, toe tip toe tip toe. Jump, jump, jump, jump, jump, jump. Swimming, swimming, swimming, swimming, swimming, swimming. Now let's sleep. Now let's sleep. Wake up, it's time to go Are you ready to go fast? Okay. Walking, walking, walking, walking. Hop, hop, hop hop hop hop. Running, running, running run and running. Now let stop. Now let's stop. Walking, walking, walking, walking. Hop, hop, hop hop hop hop. Runningning, running, running, running, running. Now let stop. Now let's stop. U.",
    story_id: 1713,
    word_count: 27,
    all_intersection_count: 13,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "any",
      "hound",
      "porcupine",
      "nudges",
      "blamed",
      "for",
      "harboring",
      "grudges",
      "I",
      "know",
      "laughed",
      "all",
      "winter",
      "splinter",
    ],
    words: [
      "any",
      "hound",
      "a",
      "porcupine",
      "nudges",
      "can't",
      "be",
      "blamed",
      "for",
      "harboring",
      "grudges",
      "I",
      "know",
      "one",
      "that",
      "laughed",
      "all",
      "winter",
      "at",
      "sat",
      "on",
      "splinter",
    ],
    link: "(7)https://www.splashlearn.com/blog/best-kids-poems-to-entice-a-love-for-reading-poetry/",
    type: "poem",
    youtube_id: null,
    storage_link: null,
    id: "Any hound a porcupine nudges\nCan’t be blamed for harboring grudges,\nI know one hound that laughed all winter\nAt a porcupine that sat on a splinter.",
    story_id: 1894,
    word_count: 22,
    all_intersection_count: 8,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "the",
      "forest",
      "town",
      "of",
      "trees",
      "live",
      "quite",
      "their",
      "ease",
      "neighbors",
      "side",
      "just",
      "cities",
      "wide",
    ],
    words: [
      "the",
      "forest",
      "is",
      "town",
      "of",
      "trees",
      "where",
      "they",
      "live",
      "quite",
      "at",
      "their",
      "ease",
      "with",
      "neighbors",
      "side",
      "just",
      "as",
      "we",
      "in",
      "cities",
      "wide",
    ],
    link: "(9)https://www.planetspark.in/blogs/english-poems-for-kids",
    type: "poem",
    youtube_id: null,
    storage_link: null,
    id: "The forest is the town of trees\nWhere they live quite at their ease,\nWith their neighbors at their side\nJust as we in cities wide.",
    story_id: 1884,
    word_count: 22,
    all_intersection_count: 8,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "any",
      "hound",
      "porcupine",
      "nudges",
      "blamed",
      "for",
      "harboring",
      "grudges",
      "I",
      "know",
      "laughed",
      "all",
      "winter",
      "splinter",
    ],
    words: [
      "any",
      "hound",
      "a",
      "porcupine",
      "nudges",
      "can't",
      "be",
      "blamed",
      "for",
      "harboring",
      "grudges",
      "I",
      "know",
      "one",
      "that",
      "laughed",
      "all",
      "winter",
      "at",
      "sat",
      "on",
      "splinter",
    ],
    link: "(6)https://www.splashlearn.com/blog/best-kids-poems-to-entice-a-love-for-reading-poetry/",
    type: "poem",
    youtube_id: null,
    storage_link: null,
    id: "Any hound a porcupine nudges\nCan’t be blamed for harboring grudges,\nI know one hound that laughed all winter\nAt a porcupine that sat on a splinter.",
    story_id: 1893,
    word_count: 22,
    all_intersection_count: 8,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "if",
      "you're",
      "clap",
      "hands",
      "angry",
      "stomp",
      "feet",
      "scared",
      "say",
      "oh",
      "no",
      "sleepy",
      "take",
      "nap",
    ],
    words: [
      "if",
      "you're",
      "happy",
      "clap",
      "your",
      "hands",
      "angry",
      "stomp",
      "feet",
      "scared",
      "say",
      "oh",
      "no",
      "sleepy",
      "take",
      "a",
      "nap",
    ],
    link: "wqvQAcloTRQ",
    type: "song",
    youtube_id: "wqvQAcloTRQ",
    storage_link: null,
    id: "If you're happy, happy, happy Clap your hands if you're happy, happy, happy Clap your hands if you're happy, happy, happy Clap your hands clap your hands if you're happy, happy, happy Clap your hands if you're angry, angry, angry Stomp your feet if you're angry, angry, angry Stomp your feet if you're angry, angry, angry Stomp your feet, stomp your feet if you're angry, angry, angry Stomp your feet if you're scared, scared, scared say oh no, oh no if you're scared, scared, scared say say oh no oh no if you're scared, scared, scared say oh no say oh no if you're scared, scared, scared say oh no, oh no if you're sleepy, sleepy, sleepy Take a nap if you're sleepy, sleepy, sleepy Take a nap if you're sleepy, sleepy, sleepy Take a nap, take a nap if you're sleepy, sleepy, sleepy Take a nap if you're happy, happy, happy Clap your hands if you're happy, happy, happy Clap your hands if you're happy, happy, happy Clap your hands, clap your hands if you're happy, happy, happy Clap your hands.",
    story_id: 1832,
    word_count: 17,
    all_intersection_count: 3,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "of",
      "the",
      "near",
      "edge",
      "looks",
      "drink",
      "both",
      "towards",
      "faster",
      "jumps",
      "tips",
      "over",
      "all",
      "floor",
    ],
    words: [
      "glass",
      "of",
      "milk",
      "on",
      "the",
      "desk",
      "a",
      "is",
      "it",
      "near",
      "edge",
      "child",
      "looks",
      "at",
      "wants",
      "to",
      "drink",
      "cat",
      "too",
      "they",
      "both",
      "walk",
      "towards",
      "faster",
      "jumps",
      "tips",
      "over",
      "all",
      "floor",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke047.htm",
    type: null,
    youtube_id: null,
    storage_link: null,
    id: "Glass of milk on the desk\n\nA glass of milk is on the desk. It is near the edge. The child looks at the milk. The child wants to drink it. The cat looks at the milk. The cat wants to drink it too. They both walk towards the desk. The cat is faster. It jumps on the desk. The glass of milk tips over. The milk is all over the floor.",
    story_id: 370,
    word_count: 29,
    all_intersection_count: 15,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "three",
      "times",
      "monkeys",
      "vine",
      "9",
      "3",
      "4",
      "12",
      "books",
      "shelf",
      "I",
      "will",
      "all",
      "four",
    ],
    words: [
      "three",
      "times",
      "is",
      "nine",
      "monkeys",
      "on",
      "a",
      "vine",
      "9",
      "3",
      "4",
      "12",
      "books",
      "shelf",
      "I",
      "will",
      "read",
      "them",
      "all",
      "four",
    ],
    link: "8vL-tlcPhC4",
    type: "song",
    youtube_id: "8vL-tlcPhC4",
    storage_link: null,
    id: "Three times three. Three times three is nine. Nine monkeys on a vine. Three times three is nine. 9 monkeys on a vine. Three times 3 is monkeys on a vine. Three times 4 is 12. 12 books on a shelf, I will read them all. Three times four is 12. 12 books on a shelf, I will read them all.",
    story_id: 1664,
    word_count: 20,
    all_intersection_count: 6,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "did",
      "John",
      "yesterday",
      "the",
      "movies",
      "oh",
      "who",
      "nobody",
      "by",
      "himself",
      "tina",
      "played",
      "cards",
      "herself",
    ],
    words: [
      "what",
      "did",
      "John",
      "do",
      "yesterday",
      "he",
      "went",
      "to",
      "the",
      "movies",
      "oh",
      "who",
      "go",
      "with",
      "nobody",
      "by",
      "himself",
      "tina",
      "she",
      "played",
      "cards",
      "play",
      "herself",
    ],
    link: "(2)https://www.youtube.com/watch?v=Yf5RXW3zAAk&list=PLD7AA7B1BC72ACC28&index=18",
    type: "story",
    youtube_id: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/did.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=fgVT8XGndaJwI%2BhmMfhxvUlzzFU4FkKE1hBfYUCjZEeJiIgJ%2BuBtfPVNzXCmBOclsvMpedPjS8Ptaza%2FST679RV%2F5QDh5Lyxga21ojHyfkRVjTI8bcilp0XLe3Gr5yLlynbQ3R01srVoB8I05rx2P0U5Zy8FsIsSW5WNjcs%2FXteLKhdYvcqycXMHM39xWzrsf1YC0EVxoB2AujQSoVnxuJ%2BVNzgOjYxY%2BtVvkEshJEAS8bgXiOQBzMo9tfDutr8o3vK6aPlNVRWoad1K1Ddg9FEnnm9nccxheCNPKye%2BewqUNYlv5f7cbUTodUq5Al6XRFSxnDjgNiLKq9RupJuovQ%3D%3D",
    id: "What did John do yesterday?\nHe went to the movies.\nOh, who did he go to the movies with?\nNobody. He went to the movies by himself.\n\nWhat did Tina do yesterday?\nShe played cards.\nOh, who did she play cards with?\nNobody. She played cards by herself.",
    story_id: 1052,
    word_count: 23,
    all_intersection_count: 9,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "changes",
      "sometimes",
      "lot",
      "barely",
      "but",
      "never",
      "stops",
      "evolves",
      "growing",
      "even",
      "when",
      "you're",
      "doing",
      "the",
    ],
    words: [
      "every",
      "day",
      "changes",
      "you",
      "sometimes",
      "a",
      "lot",
      "barely",
      "but",
      "life",
      "never",
      "stops",
      "it",
      "evolves",
      "and",
      "are",
      "growing",
      "even",
      "when",
      "can't",
      "see",
      "you're",
      "doing",
      "the",
      "best",
      "can",
    ],
    link: "https://www.familyfriendpoems.com/poem/every-day-changes-you",
    type: "poem",
    youtube_id: null,
    storage_link: null,
    id: "every day changes you\nsometimes a lot\nsometimes barely\nbut life never stops\nit evolves\nand you are growing\neven when you can’t see it\nyou’re doing the best you can",
    story_id: 918,
    word_count: 26,
    all_intersection_count: 12,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "were",
      "the",
      "said",
      "roll",
      "over",
      "so",
      "all",
      "rolled",
      "fell",
      "out",
      "four",
      "three",
      "I'm",
      "lonely",
    ],
    words: [
      "there",
      "were",
      "six",
      "in",
      "the",
      "bed",
      "and",
      "little",
      "one",
      "said",
      "roll",
      "over",
      "so",
      "they",
      "all",
      "rolled",
      "fell",
      "out",
      "five",
      "four",
      "three",
      "two",
      "was",
      "I'm",
      "lonely",
    ],
    link: "N9mN6ROBJMw",
    type: "song",
    youtube_id: "N9mN6ROBJMw",
    storage_link: null,
    id: "There were six in the bed and the little one said, roll over, roll over? So they all rolled over and one fell out? Five? There were five in the bed and the little one said, roll over, roll over? So all rolled over and one fell out? Four? There were four in the bed and the little one said, roll over, roll over? So they all rolled over and one fell out? Three? There were three in the bed and the little one said, roll over, roll over? So they all rolled over and one fell out? Two? There were two in the bed and the little one said roll over, roll over? So they all rolled over and one fell out? One? There was one in the bed and the little one said, I'm lonely.",
    story_id: 1849,
    word_count: 25,
    all_intersection_count: 11,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "hey",
      "diddle",
      "the",
      "fiddle",
      "cow",
      "jumped",
      "over",
      "moon",
      "laughed",
      "such",
      "sport",
      "dish",
      "away",
      "spoon",
    ],
    words: [
      "hey",
      "diddle",
      "the",
      "cat",
      "and",
      "fiddle",
      "cow",
      "jumped",
      "over",
      "moon",
      "little",
      "dog",
      "laughed",
      "to",
      "see",
      "such",
      "sport",
      "dish",
      "ran",
      "away",
      "with",
      "spoon",
    ],
    link: "(3)https://www.splashlearn.com/blog/best-kids-poems-to-entice-a-love-for-reading-poetry/",
    type: "poem",
    youtube_id: null,
    storage_link: null,
    id: "Hey diddle diddle,\nThe Cat and the fiddle,\nThe Cow jumped over the moon,\nThe little Dog laughed to see such sport,\nAnd the Dish ran away with the Spoon.",
    story_id: 1890,
    word_count: 22,
    all_intersection_count: 8,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "pass",
      "the",
      "bean",
      "bigag",
      "st",
      "faster",
      "stop",
      "slower",
      "other",
      "way",
      "past",
      "super",
      "put",
      "ground",
    ],
    words: [
      "pass",
      "the",
      "bean",
      "bag",
      "round",
      "and",
      "bigag",
      "big",
      "st",
      "let's",
      "go",
      "faster",
      "bake",
      "stop",
      "slower",
      "it",
      "other",
      "way",
      "past",
      "super",
      "fast",
      "put",
      "on",
      "ground",
    ],
    link: "_7NbNZ28srg",
    type: "song",
    youtube_id: "_7NbNZ28srg",
    storage_link: null,
    id: "Pass the bean bag round and round Pass the bean bigag Round and round Pass the bean bigag Round and round Pass the bean big St. Let's go faster Pass the bean bake round and round Pass the bean bake round Round and round Pass the bean big Round and round Pass the bean big stop. Let's go slower Pass the bean bake round and round Pass the bean bag Round and round Pass the bean bag Round and round Pass the bean bag Stop. Let's pass it the other way Pass the bean bag round and round Pass the bean bigag Round and round Pass the bean bag Round and round Pass the bean big St. Let's go faster Pass the bean bag round and round Pass the bean bag round and round Pass the bean bag round and round Past the bean bake St. Let's go slower Pass the bean bag round and round Pass the bean bag round and round Pass the bean bag round and round Pass the bean bake Stop. Pass it the other way Pass the bean bag round and round Pass the bean bag round and round Pass the bean bag Round and round Pass the bean Bigag Stop. Faster. Pass the bean bake Round and round Pass the bean big Round and round Pass the bean big Round and round Pass the bean Stop. Faster. Pass the bean big Round and round Pass the bean big Round and round Pass the bean bag Round and round Pass the bean big stop. Super fast. Pass the bean bag round and round Pass the bean bag round and round Pass the bean bag Round and round Pass the bean big stop. Put the bean bag on the ground.",
    story_id: 1840,
    word_count: 24,
    all_intersection_count: 10,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "the",
      "forest",
      "town",
      "of",
      "trees",
      "live",
      "quite",
      "their",
      "ease",
      "neighbors",
      "side",
      "just",
      "cities",
      "wide",
    ],
    words: [
      "the",
      "forest",
      "is",
      "town",
      "of",
      "trees",
      "where",
      "they",
      "live",
      "quite",
      "at",
      "their",
      "ease",
      "with",
      "neighbors",
      "side",
      "just",
      "as",
      "we",
      "in",
      "cities",
      "wide",
    ],
    link: "(2)https://www.splashlearn.com/blog/best-kids-poems-to-entice-a-love-for-reading-poetry/",
    type: "poem",
    youtube_id: null,
    storage_link: null,
    id: "The forest is the town of trees\nWhere they live quite at their ease,\nWith their neighbors at their side\nJust as we in cities wide.",
    story_id: 1889,
    word_count: 22,
    all_intersection_count: 8,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "did",
      "I",
      "washed",
      "my",
      "floors",
      "yes",
      "all",
      "cleaned",
      "house",
      "painted",
      "porch",
      "fixed",
      "sink",
      "worked",
    ],
    words: [
      "what",
      "did",
      "you",
      "do",
      "today",
      "I",
      "washed",
      "my",
      "floors",
      "your",
      "yes",
      "all",
      "day",
      "cleaned",
      "house",
      "painted",
      "porch",
      "fixed",
      "sink",
      "we",
      "worked",
      "at",
      "home",
    ],
    link: "(2)https://www.youtube.com/watch?v=xyvNu254A-Y&list=PL31D7CB49ABAAB4DB&index=24",
    type: "video",
    youtube_id: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/doing-1.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=drYWU0J2dhyK32ZJiCY9tB0evxB7VhLTNclDMTT4Bu63Xc5f79rO0KtGQEr3847QGA0GXUQG3bGi0Qt1%2FAoee9tg2a%2BFjyBdqgDfTTZVPW8d4F6xGjCxCilTE7a%2B7mNEJO9qeNoHZzAdLkhTTNBGzfYviqm0nPEhU2XeSU2Wpqa5O2Shnpw5gN%2Fr5leQGkQ%2BBYEvJJVpL4EseQS5qBpqSIZxzBCTvFuH0N%2BHnkMxAOu%2Baj5cu4%2F%2BRWlFnTXoMSYnBC0o7GekEiQ0Icu8%2FbE%2B8u4JBkvUV8pcJ3UrEM20y3E7qOu%2F4kS1ic38B4VBfHcz%2FSCKHLCFJyIIAT2qMM2BYQ%3D%3D",
    id: "What did you do today?\nI washed my floors.\nYour floors?\nYes. I washed my floors all day.\n\nWhat did you do today?\nI cleaned my house.\nYour house?\nYes. I cleaned my house all day.\n\nWhat did you do today?\nI painted my porch.\nYour porch?\nYes. I painted my porch all day.\n\nWhat did you do today?\nI fixed my sink.\nYour sink?\nYes. I fixed my sink all day.\n\nI washed my floors.\nI cleaned my house.\nI painted my porch.\nI fixed my sink.\nWe worked at home all day.",
    story_id: 1010,
    word_count: 23,
    all_intersection_count: 9,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "were",
      "beautiful",
      "before",
      "said",
      "smart",
      "admired",
      "smarts",
      "funny",
      "laughed",
      "jokes",
      "loved",
      "spoke",
      "those",
      "three",
      "words",
    ],
    words: [
      "you",
      "were",
      "beautiful",
      "before",
      "they",
      "said",
      "are",
      "smart",
      "admired",
      "your",
      "smarts",
      "funny",
      "laughed",
      "at",
      "jokes",
      "loved",
      "spoke",
      "those",
      "three",
      "words",
    ],
    link: "https://www.familyfriendpoems.com/poem/you-already-were",
    type: "poem",
    youtube_id: null,
    storage_link: null,
    id: "You were beautiful\nbefore they said, you are beautiful\n\nYou were smart\nbefore they admired your smarts\n\nYou were funny\nbefore they laughed at your jokes\n\nYou were loved\nbefore they spoke those three words",
    story_id: 931,
    word_count: 20,
    all_intersection_count: 5,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "when",
      "I",
      "put",
      "shirt",
      "because",
      "buttons",
      "button",
      "the",
      "of",
      "take",
      "off",
      "unbutton",
      "sweater",
      "zipper",
      "unzip",
    ],
    words: [
      "when",
      "I",
      "put",
      "on",
      "this",
      "shirt",
      "because",
      "it",
      "has",
      "buttons",
      "need",
      "to",
      "button",
      "up",
      "the",
      "at",
      "end",
      "of",
      "day",
      "want",
      "take",
      "off",
      "unbutton",
      "sweater",
      "a",
      "zipper",
      "zip",
      "unzip",
    ],
    link: "https://www.youtube.com/shorts/A9VjViMlyh4",
    type: "story",
    youtube_id: null,
    storage_link: "",
    id: "When I put on this shirt because it has buttons, I need to button up the shirt at the end of the day, when I want to take off the shirt, I need to unbutton the shirt when I put on this sweater because it has a zipper, I need to zip up the sweater at the end of the day, when I need to take off the sweater, I need to unzip the sweater.",
    story_id: 1092,
    word_count: 28,
    all_intersection_count: 13,
    new_words_count: 15,
  },
];

export default storiesData;

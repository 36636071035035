import { memo } from "react";
import HStack from "../../Unknown/HStack";
import { ChangePositionButton } from "../../Unknown/LearnWordsSection";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

interface RepetitionButtonsProps {
  isVisible: boolean;
  wordsCount: number;
  onlyCurrentDay?: boolean;
  goToNextSlide: (period: string) => void;
}

const RepetitionButtons = ({
  isVisible,
  wordsCount,
  onlyCurrentDay,
  goToNextSlide,
}: RepetitionButtonsProps) => {
  if (!isVisible) return null;

  return (
    <>
      <HStack pt={3}>
        <ChangePositionButton onClick={() => goToNextSlide("2")}>2</ChangePositionButton>
        <ChangePositionButton onClick={() => goToNextSlide("5")}>5</ChangePositionButton>
        {wordsCount > 13 && (
          <ChangePositionButton onClick={() => goToNextSlide("10")}>10</ChangePositionButton>
        )}
        {wordsCount > 22 && (
          <ChangePositionButton onClick={() => goToNextSlide("20")}>20</ChangePositionButton>
        )}
        {wordsCount > 32 && (
          <ChangePositionButton onClick={() => goToNextSlide("30")}>30</ChangePositionButton>
        )}
        {/* {wordsCount > 44 && <ChangePositionButton onClick={() => goToNextSlide("40")}>40</ChangePositionButton>} */}
        <ChangePositionButton onClick={() => goToNextSlide((wordsCount - 1).toString())}>
          <ArrowForwardIcon sx={{ fontSize: 16 }} />
        </ChangePositionButton>
      </HStack>
      {!onlyCurrentDay && (
        <HStack sx={{ justifyContent: "center" }}>
          <ChangePositionButton isPrimary onClick={() => goToNextSlide("1d")}>
            1d
          </ChangePositionButton>
          <ChangePositionButton isPrimary onClick={() => goToNextSlide("3d")}>
            3d
          </ChangePositionButton>
          <ChangePositionButton isPrimary onClick={() => goToNextSlide("7d")}>
            7d
          </ChangePositionButton>
          <ChangePositionButton isPrimary onClick={() => goToNextSlide("20d")}>
            20d
          </ChangePositionButton>
          <ChangePositionButton isPrimary onClick={() => goToNextSlide("100d")}>
            100d
          </ChangePositionButton>
          <ChangePositionButton isPrimary onClick={() => goToNextSlide("never")}>
            never
          </ChangePositionButton>
        </HStack>
      )}
    </>
  );
};

export default memo(RepetitionButtons);

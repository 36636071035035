import { Box, Button, Drawer, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import getRandomGreetings from "../../../../../common/getRandomGreetings";
import { Sentence } from "../../../../../types/supabase";
import VStack from "../../../../Unknown/VStack";
import LinearProgress from "../../../../User/LinearProgress";
import HomeworkSentence from "../../../HomeworkSentence";
import ModalCloseButton from "../../../../Unknown/ModalCloseButton";
import ControllingStudentPage from "../../../../Unknown/Root/ControllingStudentPage";

const drawerContentStyle = {
  height: "100vh",
  width: "100vw",
  overflowX: "hidden",
};

const GrammarHomework: React.FC<{ sentences: Sentence[]; userSentences: string[] }> = ({
  sentences,
  userSentences,
}) => {
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  // const [toRepeat, setToRepeat] = useState(sentences.length);

  const [modifiedSentences, setModifiedSentences] = useState(sentences);

  useEffect(() => {
    setModifiedSentences(sentences);
  }, [sentences]);

  const sentence = useMemo(() => {
    return modifiedSentences[currentSentenceIndex];
  }, [modifiedSentences, currentSentenceIndex]);

  const [repeatMap, setRepeatMap] = useState<Record<string, boolean>>(() => {
    return sentences.reduce((prev, s) => {
      return {
        ...prev,
        [s.en]: false,
      };
    }, {});
  });

  const repeatedCount = useMemo(() => {
    return Object.values(repeatMap).filter(Boolean).length;
  }, [repeatMap]);

  const sentenceGreeting = useMemo(() => {
    return getRandomGreetings("Всі речення складені.");
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const handleDrawerClose = () => {
    setIsOpen(false);
  };
  // const allSentencesAreBuilt = !!lessonSentences.length && currentSentence === null;
  // console.log("🚀 ~ allSentencesAreBuilt:", allSentencesAreBuilt);

  return (
    <>
      <Button variant="outlined" onClick={() => setIsOpen(true)}>
        Перекласти речення
      </Button>
      <Drawer
        open={isOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 9999999,
        }}
      >
        <LinearProgress height={7} elementsCount={sentences.length} currentValue={repeatedCount} />
        <Box sx={drawerContentStyle} py={15}>
          <ModalCloseButton onClose={handleDrawerClose} />
          <VStack>
            <ControllingStudentPage isNested />
          </VStack>
          {repeatedCount === sentences.length - 1 && (
            <>
              <VStack py={4}>
                <Typography color="primary" textAlign="center" fontSize={20}>
                  {sentenceGreeting}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => {
                    setRepeatMap(
                      sentences.reduce((prev, s) => {
                        return {
                          ...prev,
                          [s.en]: false,
                        };
                      }, {}),
                    );
                    setModifiedSentences(sentences);
                  }}
                >
                  Почати спочатку
                </Button>
              </VStack>
            </>
          )}
          {sentence && repeatedCount !== sentences.length - 1 && (
            <HomeworkSentence
              sentence={sentence}
              isRepeated={!!repeatMap[sentence.en]}
              belongsToUser={userSentences.includes(sentence.en)}
              wordsCount={sentences!.length}
              goToNextSlide={async (word, period) => {
                if (!period || +period === sentences.length - 1) {
                  // setToRepeat((prev) => prev - 1);
                  setRepeatMap({
                    ...repeatMap,
                    [sentence.en]: true,
                  });
                }

                const [currentSentence, ...restSentences] = modifiedSentences;

                setModifiedSentences(
                  restSentences.reduce<Sentence[]>((prev, cW, index) => {
                    const updated = [...prev];
                    if (index + 1 === +(period || sentences.length - 1))
                      updated.push(currentSentence);
                    updated.push(cW);
                    return updated;
                  }, []),
                );
                // setCurrentSentenceIndex((prev) => prev + 1);
              }}
            />
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default GrammarHomework;

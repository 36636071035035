import React, { useRef, useState } from "react";
// import * as PDFJS from "pdfjs-dist";
import List from "@mui/material/List";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

interface PageData {
  page_number: number;
  sentences: string[];
}

const AdminFindPhrasesInPdf: React.FC = () => {
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [pdfData, setPdfData] = useState<PageData[]>([]);
  console.log("🚀 ~ pdfData:", pdfData);
  const [currentPage, setCurrentPage] = useState<number>(1);
  // const [pdfDoc, setPdfDoc] = useState<pdfjsLib.PDFDocument | null>(null);
  const pdfContent = useRef<any>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setPdfFile(event.target.files[0]);
    }
  };

  return (
    <div>
      <TextField
        type="file"
        onChange={handleFileChange}
        variant="outlined"
        label="Виберіть PDF файл"
        fullWidth
      />

      {pdfData.length > 0 && (
        <div>
          <Typography variant="h6" gutterBottom>
            Текст з PDF:
          </Typography>
          <List>
            {/* {pdfData.map((page) => (
              <ListItem key={page.page_number}>
                <ListItemText
                  primary={`Сторінка ${page.page_number}`}
                  secondary={page.sentences.join(". ")}
                />
              </ListItem>
            ))} */}
          </List>
        </div>
      )}
    </div>
  );
};

export default AdminFindPhrasesInPdf;

import AudioPlayer from "react-h5-audio-player";
import styled from "styled-components";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { useEffect, useMemo, useRef, useState } from "react";
import MenuLayout from "../../Layout/MenuLayout";
import Box from "../../Unknown/Box";
import classNames from "classnames";
import { Button, CircularProgress, Typography } from "@mui/material";
import VStack from "../../Unknown/VStack";
import { useTimer } from "react-timer-hook";
import screenfull from "screenfull";
import { addMinutes } from "date-fns";
import Digit from "../Digit";
import HStack from "../../Unknown/HStack";
import Modal from "@mui/material/Modal";
import ReactPlayer from "react-player";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const VideoModal: React.FC<{ open: boolean; iframeUrl: string; onClose: () => void }> = ({
  open,
  iframeUrl,
  onClose,
}) => {
  const ref = useRef(null);

  const video = useMemo(() => {
    if (!iframeUrl) return null;

    return (
      <ReactPlayer
        playing
        className="react-player fixed-bottom"
        url={`/video/${iframeUrl}`}
        width="100%"
        height="100%"
        controls={true}
      />
    );
  }, [iframeUrl]);

  useEffect(() => {
    if (video) {
      const videoTag = document.querySelector("video");

      if (videoTag) {
        (videoTag as any).webkitRequestFullscreen();
      }
    }
  });

  return (
    <Modal
      ref={ref}
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* <iframe width="560" height="315" src={iframeUrl} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> */}
        {/* <iframe allow="autoplay" title="YouTube video player" src="https://www.youtube.com/embed/jHg0b7Nai6c?iv_load_policy=3&modestbranding=0&autoplay=1&controls=0&rel=0&showinfo=0&wmode=opaque&branding=0&autohide=0&loop=1&rel=0&enablejsapi=1&origin=https%3A%2F%2Fhub.youth.gov.ae&widgetid=1&mute=1" width="1519" height="855"></iframe> */}
        {/* <video controls autoPlay src={`/video/${iframeUrl}`} /> */}
        <Box id="modal-video">{video}</Box>
      </Box>
    </Modal>
  );
};

interface WordAudioProps {
  url: string;
  autoPlay?: boolean;
  boxHide?: boolean;
  small?: boolean;
}

const TimerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
`;

const SepartorContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  margin: 0 10px 25px;
`;

const Separtor = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 8px;
  line-height: 1.1;
  margin: 15px 0px;
`;
// background-color: #404549;

const CurrentTime: React.FC = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update every 1000 milliseconds (1 second)

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, []);

  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  const seconds = currentTime.getSeconds();

  return (
    <>
      <TimerContainer>
        <Digit value={hours} title="hours" />
        <SepartorContainer>
          <Separtor />
          <Separtor />
        </SepartorContainer>
        <Digit value={minutes} title="MINUTES" addSeparator />
        <SepartorContainer>
          <Separtor />
          <Separtor />
        </SepartorContainer>
        <Digit value={seconds} title="SECONDS" />
      </TimerContainer>
    </>
  );
};

const Timer: React.FC<{
  restart: boolean;
  // minutes: number;
  defaultMinutes: number;
  onRestart: () => void;
  inputOnExpire: () => void;
  start: boolean;
  onStart: () => void;
}> = ({
  restart: inputRestart,
  start: inputStart,
  inputOnExpire,
  defaultMinutes,
  onRestart,
  onStart,
}) => {
  const [startMinutes, setStartMinutes] = useState(defaultMinutes);

  const time = useMemo(() => {
    return addMinutes(new Date(), startMinutes);
  }, [startMinutes]);

  const { totalSeconds, seconds, minutes, hours, days, isRunning, start, pause, resume, restart } =
    useTimer({
      expiryTimestamp: time,
      autoStart: false,
      onExpire: () => {
        // setIsLongPlay(true);
        inputOnExpire();
      },
    });

  useEffect(() => {
    restart(addMinutes(new Date(), startMinutes), false);
    onRestart();
  }, [inputRestart, startMinutes, restart]);

  useEffect(() => {
    if (inputStart) {
      start();
      onStart();
    }
  }, [inputStart, start]);

  return (
    <>
      <TimerContainer>
        {/* {days !== undefined ? <Digit value={days} title="DAYS" addSeparator /> : null} */}
        {/* {days !== undefined ? (
    <SepartorContainer>
      <Separtor />
      <Separtor />
    </SepartorContainer>
  ) : null} */}
        {/* <Digit value={hours} title="HOURS" addSeparator />
  <SepartorContainer>
    <Separtor />
    <Separtor />
  </SepartorContainer> */}
        <Digit
          value={minutes}
          title="MINUTES"
          addSeparator
          onClick={() => {
            setStartMinutes((prev) => prev - 1);
          }}
        />
        <SepartorContainer>
          <Separtor />
          <Separtor />
        </SepartorContainer>
        <Digit
          value={seconds}
          title="SECONDS"
          onClick={() => {
            setStartMinutes((prev) => prev + 1);
          }}
        />
      </TimerContainer>
    </>
  );
};

const Audio = ({ url, autoPlay, boxHide, small }: WordAudioProps) => {
  const ref = useRef<any>();

  useEffect(() => {
    if (ref?.current) ref.current.audio.current.playbackRate = 0.85;
    // if (autoPlay) ref.current.audio.current.play();
  }, [ref]);

  return (
    <AudioPlayer
      src={url}
      ref={ref}
      className={classNames("audio-player", {
        "autoplay-not-visible-audio-player": boxHide,
        "small-audio-player": small,
      })}
      autoPlay={autoPlay}
      showJumpControls={false}
      showFilledProgress={false}
      hasDefaultKeyBindings={false}
      customProgressBarSection={[]}
      customVolumeControls={[]}
      customAdditionalControls={[]}
      autoPlayAfterSrcChange={false}
      customIcons={{
        play: <VolumeUpIcon />,
        pause: <PauseRoundedIcon />,
      }}
    />
  );
};

const TimerScreen: React.FC = () => {
  // const time = new Date();
  // time.setSeconds(time.getSeconds() + 600); // 10 minutes timer
  const [isLongPlay, setIsLongPlay] = useState(false);
  const [isLong2Play, setIsLong2Play] = useState(false);
  // const { totalSeconds, seconds, minutes, hours, days, isRunning, start, pause, resume, restart } = useTimer({ expiryTimestamp: addMinutes(new Date(), meditationTimer), onExpire: () => console.warn("onExpire called") });
  const [restart, setRestart] = useState(false);
  const [start, setStart] = useState({
    1: false,
    2: false,
  });
  const [iframeUrl, setIframeUrl] = useState("");

  useEffect(() => {
    let timer1: any;
    let timer2: any;
    if (isLongPlay) {
      timer1 = setTimeout(() => {
        setIsLongPlay(false);
      }, 15000);
    }
    if (isLong2Play) {
      timer2 = setTimeout(() => {
        setIsLong2Play(false);
      }, 60000);
    }

    return () => {
      if (timer1) clearTimeout(timer1);
      if (timer2) clearTimeout(timer2);
    };
  }, [isLongPlay, isLong2Play]);

  const [open, setOpen] = useState(false);
  // const ref = useRef<any>();
  // const ref = (player: any) => {
  //   if (player) {
  //     console.log("🚀 ~ player:", Object.keys(player));
  //     // console.log("🚀 ~ player:", player?.webkitRequestFullscreen);
  //     // player = player
  //     if (player?.webkitRequestFullscreen) player.webkitRequestFullscreen();
  //   }
  // };

  useEffect(() => {
    if (iframeUrl) {
      const elm = document.querySelector(".react-player");
      if (elm) screenfull.request(elm);
    }
  }, [iframeUrl]);

  useEffect(() => {
    // console.log(screenfull.isEnabled);
    const onChangeCallback = () => {
      if (!screenfull.isFullscreen) {
        setIframeUrl("");
      }
    };

    if (screenfull.isEnabled) {
      screenfull.on("change", onChangeCallback);
    }

    return () => {
      screenfull.off("change", onChangeCallback);
    };
  });

  return (
    <MenuLayout isFluid isDark>
      {/* <iframe src="https://infinite-happiness.github.io/Ananda-Marga-Fasting-Calendar" width="640" height="480"></iframe> */}
      {/* <VideoModal
        open={open}
        iframeUrl={iframeUrl}
        onClose={() => {
          setOpen(false);
        }}
      /> */}
      <Box
        sx={{
          position: "absolute",
          left: -99999,
          top: -99999,
        }}
      >
        {iframeUrl && (
          <ReactPlayer
            playing
            className="react-player fixed-bottom"
            url={`/video/${iframeUrl}`}
            width={100}
            height={100}
            controls={true}
          />
        )}
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "black",
          // zIndex: 1,
        }}
      ></Box>
      <HStack>
        <Button
          variant="outlined"
          sx={{
            border: "1px solid white",
            color: "white",
          }}
          onClick={() => {
            setStart((prev) => {
              return {
                ...prev,
                1: true,
              };
            });
            setIsLongPlay(false);
            setIsLong2Play(false);
          }}
        >
          Start
        </Button>
        <Button
          variant="outlined"
          sx={{
            border: "1px solid white",
            color: "white",
          }}
          onClick={() => {
            setRestart(true);
            setIsLongPlay(false);
            setIsLong2Play(false);
          }}
        >
          Restart
        </Button>
        <Button
          variant="outlined"
          sx={{
            border: "1px solid white",
            color: "white",
          }}
          onClick={() => {
            setOpen(true);
            // setIframeUrl("https://www.youtube.com/embed/upBk70sdJXk?iv_load_policy=3&modestbranding=0&autoplay=1&controls=1&rel=0&showinfo=0&wmode=opaque&branding=0&autohide=0&loop=1&rel=0&enablejsapi=1&origin=https%3A%2F%2Fhub.youth.gov.ae&widgetid=1");
            setIframeUrl("kaoshiki.mp4");
          }}
        >
          Kaoshiki
        </Button>
        <Button
          variant="outlined"
          sx={{
            border: "1px solid white",
            color: "white",
          }}
          onClick={() => {
            setOpen(true);
            setIframeUrl("tandava.mp4");
          }}
        >
          Tandava
        </Button>
        <Button
          variant="outlined"
          sx={{
            border: "1px solid white",
            color: "white",
          }}
          onClick={() => {
            setIsLongPlay(true);
            setTimeout(() => {
              setIsLongPlay(false);
            }, 4000);
          }}
        >
          A 1
        </Button>
        <Button
          variant="outlined"
          sx={{
            border: "1px solid white",
            color: "white",
          }}
          onClick={() => {
            setIsLong2Play(true);
            setTimeout(() => {
              setIsLong2Play(false);
            }, 4000);
          }}
        >
          A 2
        </Button>
      </HStack>
      <VStack pt={10}>
        {isLongPlay && <Audio autoPlay url="/timerAudio/short_2.mp3" boxHide />}

        <Timer
          restart={restart}
          defaultMinutes={30}
          onRestart={() => {
            setRestart(false);
            setIsLongPlay(false);
          }}
          start={start["1"]}
          inputOnExpire={() => {
            setStart((prev) => {
              return {
                ...prev,
                2: true,
              };
            });
            setIsLongPlay(true);
          }}
          onStart={() => {
            setStart((prev) => {
              return {
                ...prev,
                1: false,
              };
            });
          }}
        />
        {isLong2Play && <Audio autoPlay url="/timerAudio/short.mp3" boxHide />}
        <Timer
          restart={restart}
          defaultMinutes={20}
          onRestart={() => {
            setRestart(false);
            setIsLong2Play(false);
          }}
          start={start["2"]}
          inputOnExpire={() => {
            setIsLong2Play(true);
          }}
          onStart={() => {
            setStart((prev) => {
              return {
                ...prev,
                2: false,
              };
            });
          }}
        />
        <CurrentTime />
      </VStack>
    </MenuLayout>
  );
};

export default TimerScreen;

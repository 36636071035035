import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { PropsWithChildren, ReactElement, memo, useEffect, useMemo, useRef, useState } from "react";
import { Sentence, Word } from "../../types/supabase";
import { HighlightWord } from "../Sentence/UkrainianWord";
import firstLetterUppercase from "../../common/firstLetterUppercase";
import getSentenceWordMatch from "../Word/WordTranslationSection/getSentenceWordMatch";
import VStack from "../Unknown/VStack";
import PersonalEnPronounce from "../Word/WordTranslationSection/PersonalEnPronounce";
import { useLessonsStore } from "../../store/lessonsStore";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  createUserLesson,
  getUserLesson,
  updateUserLesson,
} from "../Unknown/Root/ControllingStudentPage";
import HStack from "../Unknown/HStack";
import supabaseClient from "../../common/supabaseClient";
import ItalicTitle from "../Title/ItalicTitle";

interface WordTranslationSectionProps {
  sentence: Sentence;
  ua: string;
  en: string;
}

const VideoQuestionSentence = ({
  sentence,
  en: inputEn,
  ua: inputUa,
  children,
}: WordTranslationSectionProps & PropsWithChildren) => {
  const ref = useRef<HTMLElement>();
  const editorState = useLessonsStore((state) => state.editorState);
  const studentUser = useLessonsStore((state) => state.studentUser);
  const setEditorState = useLessonsStore((state) => state.setEditorState);
  const setNewUserLesson = useLessonsStore((state) => state.setNewUserLesson);
  const newUserLesson = useLessonsStore((state) => state.newUserLesson);

  const { en, ua, answers } = useMemo(() => {
    return {
      en: inputEn || "",
      ua: firstLetterUppercase(inputUa || "")
        .split(" / ")
        .map((sentence, index) => (
          <Typography
            fontStyle="italic"
            key={index}
            variant="body2"
            // fontSize={isTranslation ? 30 : 20}
            fontWeight={300}
            fontSize={15}
            color="secondary.dark"
            position="relative"
          >
            {/* {!isTranslation && Boolean(audioDuration) && !audioDuration && (
              <HideBlock delay={2} />
            )}
            {!isTranslation && Boolean(audioDuration) && audioDuration && (
              <HideBlock delay={audioDuration} />
            )} */}
            {/* {meaning && <Typography fontStyle="italic">{meaning}</Typography>} */}
            <span>
              <HighlightWord fontSize={20} text={sentence} row isLast />
            </span>
          </Typography>
        )),

      answers: sentence.answers || [],
    };
    // eslint-disable-next-line
  }, [sentence, inputEn, inputUa]);

  const enSentence = useMemo(() => {
    return firstLetterUppercase(en);
  }, [en]);

  return (
    <Box
      py={1}
      display="flex"
      flexDirection="column"
      alignItems={"flex-start"}
      justifyContent={"flex-start"}
    >
      {/* center box to detect i in viewport */}

      <VStack position="relative" mb={4} alignItems={"flex-start"} textAlign="left">
        <HStack
          sx={{
            mb: -1,
          }}
        >
          <Button
            size="small"
            variant="outlined"
            onClick={async () => {
              console.log("sentence", sentence);
              setEditorState(`${editorState} ${sentence.en}`);
            }}
          >
            Add to the editor
          </Button>
          {studentUser && !newUserLesson?.sentence_ids?.includes(sentence.id) && (
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={async () => {
                console.log("sentence", sentence);

                const userLesson = await getUserLesson(studentUser.id);

                if (!userLesson) {
                  await createUserLesson({
                    user: studentUser.id,
                    sentence_ids: [sentence.id],
                  });
                } else {
                  await updateUserLesson({
                    user: studentUser.id,
                    sentence_ids: Array.from(
                      new Set([...(userLesson.sentence_ids || []), sentence.id]),
                    ),
                  });
                }

                const updatedOrCreatedUserLesson = await getUserLesson(studentUser.id);
                setNewUserLesson(updatedOrCreatedUserLesson);
              }}
            >
              Add to the lesson
            </Button>
          )}
        </HStack>
        <Typography
          fontStyle="italic"
          // fontSize={fullScreen ? 30 : 20}
          color="secondary.dark"
          fontWeight={500}
          sx={{
            mb: -3,
          }}
        >
          {enSentence}
        </Typography>
        <Box>{ua}</Box>
        {Boolean(answers.length) && (
          <Box pl={4} sx={{ borderLeft: "3px solid" }}>
            {answers.map((a, index) => {
              return (
                <VStack key={index} alignItems="flex-start" gap={0} mb={2}>
                  <ItalicTitle text={a.en} />
                  <HighlightWord fontSize={15} text={a.ua} row isLast />
                </VStack>
              );
            })}
          </Box>
        )}
      </VStack>
      {children}
    </Box>
  );
};

export default memo(VideoQuestionSentence);

import { useState } from "react";
import { EnglishStory } from "../../../types/supabase";
import Box from "../Box";
import TextField from "../TextField";
import { Badge, Button } from "@mui/material";
import supabaseClient from "../../../common/supabaseClient";
import { useLessonsStore } from "../../../store/lessonsStore";
import HStack from "../HStack";

const AdminTimeCorrection: React.FC<{ story: EnglishStory }> = ({ story }) => {
  const [start, setStart] = useState(story.clip_start || 0);
  const [end, setEnd] = useState((story.clip_start || 0) + (story.duration || 0));
  const stories = useLessonsStore((state) => state.stories);
  const setStories = useLessonsStore((state) => state.setStories);

  return (
    <HStack>
      <TextField
        color="primary"
        value={start}
        sx={{ width: "70px" }}
        multiline={true}
        onChange={(event) => {
          setStart(+event.target.value);
        }}
        type="text"
        variant="outlined"
      />
      <TextField
        color="primary"
        value={end}
        sx={{ width: "70px" }}
        multiline={true}
        onChange={(event) => {
          setEnd(+event.target.value);
        }}
        type="text"
        variant="outlined"
      />
      <Button
        variant="contained"
        color="error"
        onClick={async () => {
          await supabaseClient
            .from("english_verses")
            .update({
              clip_start: start,
              duration: end - start,
            })
            .eq("id", (story as any).story_id);

          const { data: updatedStory } = await supabaseClient
            .from("english_verses")
            .select()
            .eq("id", (story as any).story_id)
            .single();
          if (updatedStory) {
            setStories(
              stories.map((s) => {
                if (s.id === updatedStory) return updatedStory;
                return s;
              }),
            );
          }
        }}
      >
        update
      </Button>
    </HStack>
  );
};

export default AdminTimeCorrection;

import { Link, Tooltip } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import Button from "../Button";
import { useLessonsStore } from "../../../store/lessonsStore";
import isAdmin from "../../../common/isAdmin";

const ExternalLinkVideo: React.FC<{ link?: string; admin?: boolean }> = ({ link, admin }) => {
  const authUser = useLessonsStore((state) => state.authUser);

  if (!link) return <></>;

  if (admin && !isAdmin(authUser?.id)) return null;

  return (
    <Button
      color="info"
      variant="contained"
      component={Link}
      target="_blank"
      sx={{ textDecoration: "underline" }}
      href={
        link.includes("/")
          ? link
          : `https://www.youtube.com/watch?v=${link
              .replace("(1)", "")
              .replace("(2)", "")
              .replace("(3)", "")
              .replace("(4)", "")
              .replace("(5)", "")}`
      }
    >
      <Tooltip title="open external story in a new tab">
        <LinkIcon />
      </Tooltip>
    </Button>
  );
};

export default ExternalLinkVideo;

import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import supabaseClient from "../../../common/supabaseClient";
import wait from "../../../common/wait";
import { BookChapterJsonParagraph, Word } from "../../../types/supabase";
import UpdateWordTranslationItem from "../UpdateWordTranslationItem";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import Loader from "../../Unknown/Loader";
import CopyNewWordsForGemini from "../CopyNewWordsForGemini";
import removePunctuationFromString from "../../../common/removePunctuationFromString";

const replaceWordMap: Record<string, string> = {
  THE: "the",
  BOY: "boy",
  WHO: "who",
  Stone: "stone",
  Sorcerer: "sorcerer",
  "Sorcerer's": "sorcerer's",
  They: "they",
  We: "we",
  He: "he",
  She: "she",
  You: "you",
  LIVED: "lived",
  CHAPTER: "chapter",
  ONE: "one",
  TWO: "two",
  THREE: "three",
  FOUR: "four",
  FIVE: "five",
  SIX: "six",
  SEVEN: "seven",
  EIGHT: "eight",
  NINE: "nine",
  TEN: "ten",
  ELEVEN: "eleven",
  TWELVE: "twelve",
  THIRTEEN: "thirteen",
  FOURTEEN: "fourteen",
  FIFTEEN: "fifteen",
  SIXTEEN: "sixteen",
  SEVENTEEN: "seventeen",
  Harry: "Harry Potter",
  Potter: "Harry Potter",
  Mr: "Mr.",
  Ms: "Ms.",
  Mrs: "Mrs.",
  Privet: "Privet Drive",
  Drive: "Privet Drive",
};

const BookSpliter = () => {
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [splitedText, setSplitedText] = useState<any>();
  const [allTextRowWords, setAllTextRowWords] = useState<string[]>([]);
  const [newWords, setNewWords] = useState<any>([]);

  useMountedWaitAsyncEffect(async () => {
    if (text) {
      const paragraphs = text.split("\n\n").filter(Boolean);
      console.log("🚀 ~ paragraphs:", paragraphs.length);

      const allWords: string[] = [];
      const jsonData: any = [];

      let jsonDataId = 0;
      // console.log("🚀 ~ jsonDataId:", jsonDataId);
      for (let i = 0; i < paragraphs.length; i++) {
        const words = paragraphs[i].split(/[\s\n]+/).filter(Boolean);

        // allWords.push(...words);

        for (let j = 0; j < words.length; j++) {
          jsonDataId += 1;
          let word = removePunctuationFromString(words[j]).trim();
          if (!word) continue;
          if (replaceWordMap[word]) {
            word = replaceWordMap[word];
          }
          allWords.push(word);

          // const isLengthMore = word.length > 1;
          // const isUppercase = word.toUpperCase() === word

          jsonData.push({
            word,
            noWord: false,
            id: jsonDataId.toString(),
            additional: [],
            label: words[j],
          });
        }

        jsonDataId += 1;

        jsonData.push({
          type: "_______",
          id: jsonDataId.toString(),
          additional: [],
          questions: [],
        });
      }

      // setSplitedText(JSON.stringify(jsonData));
      setSplitedText(jsonData.filter((jd: any) => !!jd.word));
      console.log("jsonData.length", jsonData.length);
      setAllTextRowWords(allWords);
    }
  }, [text]);

  const handleLoadWords = async () => {
    if (splitedText) {
      setIsLoading(true);
      const words = splitedText.map((word: any) => word.word);
      console.log("🚀 Array.from(new Set(words)):", Array.from(new Set(words)));

      const uniqueWords = Array.from(new Set(words)).filter(Boolean);
      const loverWords = uniqueWords.map((w: any) => w.toLowerCase());
      const upperWords = uniqueWords.map((w: any) => w?.toUpperCase());

      const { data } = await supabaseClient
        .from("words")
        .select()
        .in("name", Array.from(new Set([...(uniqueWords as any), ...loverWords, ...upperWords])));

      const existingWords = data?.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.name]: curr,
        };
      }, {});

      const updatedSplitedText = [];
      const acmNewWords: string[] = [];
      for (let i = 0; i < splitedText.length; i++) {
        const w = splitedText[i];
        const existingWord =
          existingWords[w.word] ||
          existingWords[w.word.toLowerCase()] ||
          existingWords[w.word.toUpperCase()];

        if (!existingWord) {
          acmNewWords.push(w.word);
          updatedSplitedText.push({
            ...w,
            noWord: true,
          });
        } else {
          updatedSplitedText.push({
            ...w,
            word: existingWord.name,
            transcription: existingWord.transcription,
            translation: existingWord.translation,
            audioUrl: existingWord.audio_url.replace(
              "https://storage.googleapis.com/leeearning.appspot.com/enAudio/",
              "",
            ),
          });
        }
      }

      setSplitedText(updatedSplitedText.filter((jd: any) => !!jd.word));
      setNewWords(acmNewWords);
    }
    setIsLoading(false);
  };

  return (
    <Container maxWidth="sm">
      <Grid container pb={200}>
        <Grid item>{isLoading && <Loader />}</Grid>
        <Grid item xs={12}>
          <Button
            disabled={!splitedText || isLoading}
            onClick={() => {
              navigator.clipboard.writeText(JSON.stringify(splitedText));
            }}
          >
            Copy
          </Button>
          <Button disabled={isLoading} onClick={() => handleLoadWords()}>
            Load words
          </Button>
          {!!newWords.length && (
            <Typography>New words count: {Array.from(new Set(newWords)).length}</Typography>
          )}
          {/* {!!newWords.length && newWords.length > 14 && <CopyNewWordsForGemini copy10 words={newWords} />} */}
          {!!newWords.length && <CopyNewWordsForGemini words={Array.from(new Set(newWords))} />}
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            color="primary"
            value={text}
            multiline={true}
            onChange={(event) => {
              setText(event.target.value);
            }}
            type="text"
            variant="outlined"
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default BookSpliter;

import { useState } from "react";
import VStack from "../VStack";
import { Sentence } from "../../../types/supabase";
import { Box, Button, TextField } from "@mui/material";
import supabaseClient from "../../../common/supabaseClient";
import VideoQuestionSentence from "../../AdminCheckUserStories/VideoQuestionSentence";
import useCreateAudio from "../../../common/useCreateAudio";
import { addSeconds } from "date-fns";
import LoadingButton from "@mui/lab/LoadingButton";

const CheckSentence = () => {
  const [en, setEn] = useState("");
  const [ua, setUa] = useState("");
  const [loading, setLoading] = useState(false);

  const [sentences, setSentences] = useState<Sentence[]>([]);

  const { createAudio } = useCreateAudio();

  return (
    <VStack alignItems="flex-start" py={5}>
      <TextField
        name="enText"
        value={en}
        fullWidth
        label="En sentence"
        onChange={(event) => setEn(event.target.value)}
      />
      <Button
        variant="contained"
        onClick={async () => {
          const { data } = await supabaseClient
            .from("sentences")
            .select()
            .ilike("en", `%${en}%`)
            .limit(30);
          if (data) setSentences(data.sort((a, b) => a.en.length - b.en.length));
        }}
      >
        Check
      </Button>
      <TextField
        name="uaText"
        value={ua}
        fullWidth
        label="Ua sentence"
        onChange={(event) => setUa(event.target.value)}
      />
      <LoadingButton
        loading={loading}
        variant="contained"
        color="error"
        onClick={async () => {
          if (!en || !ua) {
            alert("No text");
            return;
          }

          setLoading(true);

          try {
            const { data: lastId } = await supabaseClient
              .from("sentences")
              .select("id")
              .order("id", { ascending: false })
              .limit(1)
              .single();

            if (!lastId) throw new Error("Last is is not found");

            const audio = await createAudio(en);

            const newSentence = {
              en: en.trim(),
              ua: ua.trim(),
              id: lastId.id + 1,
              en_audio_url: audio,
              ua_audio_url: "",
              is_question: false,
            } as Sentence;

            await supabaseClient.from("sentences").insert(newSentence);
            await supabaseClient.from("user-sentences").insert({
              id: `19c7ac10-110d-43e3-ad86-5e425aef49a5-${en.trim()}`,
              user: "19c7ac10-110d-43e3-ad86-5e425aef49a5",
              en: en.trim(),
              repeat: addSeconds(new Date(), 2).toISOString(),
            });
            setEn("");
            setUa("");
          } catch (err: any) {
            console.log("🚀 ~ err:", err);
            alert(err.message);
          }
          setLoading(false);
        }}
      >
        Add new sentence to the database
      </LoadingButton>
      <Box>
        {sentences.map((sentence) => {
          return <VideoQuestionSentence en={sentence.en} ua={sentence.ua} sentence={sentence} />;
        })}
      </Box>
    </VStack>
  );
};

export default CheckSentence;

import { Box, Button, Drawer, Grid, Link, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { EnglishStory, Word } from "../../../types/supabase";
import VStack from "../../Unknown/VStack";
import HoverDetails from "../../Word/HoverDetails";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import useLessonWords from "../../../hooks/useLessonWords";
import { useLessonsStore } from "../../../store/lessonsStore";
import useGlobalWords from "../../hooks/getGlobalWords";
import WordAudio from "../../Audio/WordAudio";
import HStack from "../../Unknown/HStack";
import FullScreenLoader from "../../Unknown/FullScreenLoader";
import ModalCloseButton from "../../Unknown/ModalCloseButton";
import { useParams } from "react-router-dom";
import isAdmin from "../../../common/isAdmin";
import ReactPlayer from "react-player";
import { useWindowSize } from "react-use";
import useIsHomeWork from "../../../hooks/useIsHomeWork";
import ShowCurrentHomeWorkNumbersInDrawer from "../../Unknown/ShowCurrentHomeWorkNumbersInDrawer";
import { userHomeWorkCurrentDay } from "../../../common/constants";
import parsed from "../../../common/parsed";
import { format } from "date-fns";
import YouTubePlayer from "../../Unknown/YouTubePlayer";
import ControllingStudentPage from "../../Unknown/Root/ControllingStudentPage";
import ExternalLinkVideo from "../../Unknown/ExternalLinkVideo";
import timeToSeconds from "../../../common/timeToSeconds";
import { TextPartVideoByStartAndEndButton } from "../../Unknown/TextPartVideoByStartAndEnd";
import CopyStoryIdButton from "../../Unknown/CopyStoryIdButton";
import CopySupabaseStoryLink from "../../Unknown/CopySupabaseStoryLink";

export const Story: React.FC<{ story: EnglishStory; withoutLink?: boolean }> = ({
  story,
  withoutLink,
}) => {
  const [isTranslated, setIsTranslated] = useState(false);
  const authUser = useLessonsStore((state) => state.authUser);
  const globalWords = useLessonsStore((state) => state.globalWords);
  const [curerntModuleWordsMap, setCurrentModuleWordsMap] = useState<Record<string, Word>>({});
  const { width: windowWidth } = useWindowSize();
  const videosToRepeat = useLessonsStore((state) => state.homeWork.videosToRepeat);
  const repeatedVideos = useLessonsStore((state) => state.homeWork.repeatedVideos);
  const updateHomeWork = useLessonsStore((state) => state.updateHomeWork);
  const options = useMemo(() => {
    return { words: story.words };
  }, [story]);
  // console.log("🚀 [Story] lesson words to fetch:", options);
  useGlobalWords(options);

  // const phrases = ["United States"]

  useEffect(() => {
    const words = story.words.map((w) => w.replace("–", "-"));

    const map = globalWords
      .filter(
        (w) =>
          words.includes(w.name) ||
          words.includes(w.name.toLowerCase()) ||
          words.includes(w.name.toUpperCase()),
      )
      .reduce((acm, w) => {
        return {
          ...acm,
          [w.name]: w,
          [w.name.toLowerCase()]: w,
          [w.name.toUpperCase()]: w,
        };
      }, {});

    setCurrentModuleWordsMap(map);
  }, [globalWords, story]);

  // const isHomeWork = useIsHomeWork({ lessonId: +(lessonId || 0), lessonType: lessonType || "" });
  const isHomeWork = videosToRepeat.includes(story.id);

  const handleOnEnded = () => {
    if (isHomeWork && !repeatedVideos.includes(story.id)) {
      updateHomeWork({ repeatedVideos: [...repeatedVideos, story.id] });

      const localStorageHomeWorkTracking = localStorage.getItem(userHomeWorkCurrentDay);
      let newHomeWorkTracking: Record<string, any> = {
        ...parsed(localStorageHomeWorkTracking, {}),
      };
      const currentDate = format(new Date(), "yyyy-MM-dd");

      if (!newHomeWorkTracking[currentDate]) return;

      if (!newHomeWorkTracking[currentDate].repeatedVideos) {
        newHomeWorkTracking[currentDate].repeatedVideos = [];
      }

      if (newHomeWorkTracking[currentDate].repeatedVideos.includes(story.id)) return;

      const updateRepeatedVideos = [...newHomeWorkTracking[currentDate].repeatedVideos, story.id];
      newHomeWorkTracking[currentDate].repeatedVideos = updateRepeatedVideos;

      localStorage.setItem(userHomeWorkCurrentDay, JSON.stringify(newHomeWorkTracking));
      updateHomeWork({
        repeatedVideos: updateRepeatedVideos,
      });
    }
  };

  const videoWidth = useMemo(() => {
    if (windowWidth < 400) {
      return 300;
    } else if (windowWidth < 500) {
      return 380;
    } else if (windowWidth < 600) {
      return 480;
    }
    return 580;
  }, [windowWidth]);

  return (
    <VStack alignItems="flex-start" width="100%" px={4}>
      <HStack>
        <ExternalLinkVideo link={story.link} admin />
        <CopyStoryIdButton id={story.id.toString()} admin />
        <CopySupabaseStoryLink id={story.id.toString()} admin />
      </HStack>
      {/* <Box
          component="video"
          src={
            "https://storage.googleapis.com/leeearning.appspot.com/videos/treeparts.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=qGlvmiORCwgV5Epb5nBO3bBmB87PKu%2BOyqhtyuoC93qq4Exz7VGGSsQ1ILGDfcOtxVx9uMZc6w0oYLOPBZHEt12RvrbIHNflYgjWoJCqWmP0VRBFbZGkOtUfOj8pJuu17M8y4MKxfmQouLkekmMM5LNeEnYYkBBitJSNV1TyYGoZPE4cwdBFmdageo%2BLfwVGs2Glsy4Hc5ZsZO%2BaPMBmVPMzS8eD3gWDXxxsYEayUjsIkAXCIThQmqjWtGWKmY%2BpyK15IXgCvF6viED9RCsz7Tv46S7SEkva42gaUSz%2BltONjXtHCLfsz6iy2PNugIHXfbd%2BVskaegSAtw923bBi1g%3D%3D"
          }
          controls
          width="100%"
          maxWidth={500}
          maxHeight={200}
        /> */}
      {/* <iframe
          width="300"
          height="600"
          src="https://storage.googleapis.com/leeearning.appspot.com/videos/treeparts.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=qGlvmiORCwgV5Epb5nBO3bBmB87PKu%2BOyqhtyuoC93qq4Exz7VGGSsQ1ILGDfcOtxVx9uMZc6w0oYLOPBZHEt12RvrbIHNflYgjWoJCqWmP0VRBFbZGkOtUfOj8pJuu17M8y4MKxfmQouLkekmMM5LNeEnYYkBBitJSNV1TyYGoZPE4cwdBFmdageo%2BLfwVGs2Glsy4Hc5ZsZO%2BaPMBmVPMzS8eD3gWDXxxsYEayUjsIkAXCIThQmqjWtGWKmY%2BpyK15IXgCvF6viED9RCsz7Tv46S7SEkva42gaUSz%2BltONjXtHCLfsz6iy2PNugIHXfbd%2BVskaegSAtw923bBi1g%3D%3D"
        /> */}
      {story.youtube_id && story.link?.includes("shorts") && (
        <Box
          margin="0 auto"
          sx={{
            position: "relative",
            width: "280px",
            height: "500px",
          }}
        >
          <ReactPlayer
            controls
            width="100%"
            height="500px"
            onEnded={() => {
              handleOnEnded();
            }}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
            }}
            url={`${story.link}`}
          />
        </Box>
      )}

      {story.link && !story.link.includes("shorts") && story.youtube_id && (
        <Box
          sx={{
            position: "relative",
            // pt: 10
            py: 1,
            width: "100%",
            // width: videoWidth,
            // pb: "52.5%",
            // height: "500px",
          }}
        >
          <TextPartVideoByStartAndEndButton
            start={story.clip_start || 0}
            end={(story.clip_start || 0) + (story.duration || 10)}
            story={story}
            withButton
          />
        </Box>
      )}
      {story.storage_link && (
        <VStack
          sx={{
            position: "relative",
            minWidth: "280px",
            maxWidth: 600,
            width: "100%",
            overflow: "hidden",
          }}
        >
          <ReactPlayer
            controls
            width="100%"
            height="100%"
            onEnded={() => {
              handleOnEnded();
            }}
            style={{
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
            }}
            url={`${story.storage_link}`}
          />
        </VStack>
      )}
      <Box>
        {/* <Box mt={-10}>
          <ControllingStudentPage isNested />
        </Box> */}
        {story.audio && <WordAudio url={story.audio} />}
        {story.title && (
          <Box>
            <Typography color="secondary.dark" fontSize={22} fontWeight={700}>
              {story.title}
            </Typography>
          </Box>
        )}

        {story.verse.split("\n").map((line, index) => {
          // console.log("line", line, story);
          let audio = "";
          if (story.id === 933) {
            if (line.startsWith("A ")) audio = curerntModuleWordsMap.A?.audio_url;
            if (line.startsWith("B ")) audio = curerntModuleWordsMap.B?.audio_url;
            if (line.startsWith("C ")) audio = curerntModuleWordsMap.C?.audio_url;
            if (line.startsWith("D ")) audio = curerntModuleWordsMap.D?.audio_url;
            if (line.startsWith("E ")) audio = curerntModuleWordsMap.E?.audio_url;
            if (line.startsWith("F ")) audio = curerntModuleWordsMap.F?.audio_url;
            if (line.startsWith("G ")) audio = curerntModuleWordsMap.G?.audio_url;
            if (line.startsWith("H ")) audio = curerntModuleWordsMap.H?.audio_url;
            if (line.startsWith("I ")) audio = curerntModuleWordsMap.I?.audio_url;
            if (line.startsWith("J ")) audio = curerntModuleWordsMap.J?.audio_url;
            if (line.startsWith("K ")) audio = curerntModuleWordsMap.K?.audio_url;
            if (line.startsWith("L ")) audio = curerntModuleWordsMap.L?.audio_url;
            if (line.startsWith("M ")) audio = curerntModuleWordsMap.M?.audio_url;
            if (line.startsWith("N ")) audio = curerntModuleWordsMap.N?.audio_url;
            if (line.startsWith("O ")) audio = curerntModuleWordsMap.O?.audio_url;
            if (line.startsWith("P ")) audio = curerntModuleWordsMap.P?.audio_url;
            if (line.startsWith("Q ")) audio = curerntModuleWordsMap.Q?.audio_url;
            if (line.startsWith("R ")) audio = curerntModuleWordsMap.R?.audio_url;
            if (line.startsWith("S ")) audio = curerntModuleWordsMap.S?.audio_url;
            if (line.startsWith("T ")) audio = curerntModuleWordsMap.T?.audio_url;
            if (line.startsWith("U ")) audio = curerntModuleWordsMap.U?.audio_url;
            if (line.startsWith("V ")) audio = curerntModuleWordsMap.V?.audio_url;
            if (line.startsWith("W ")) audio = curerntModuleWordsMap.W?.audio_url;
            if (line.startsWith("X ")) audio = curerntModuleWordsMap.X?.audio_url;
            if (line.startsWith("Y ")) audio = curerntModuleWordsMap.Y?.audio_url;
            if (line.startsWith("Z ")) audio = curerntModuleWordsMap.Z?.audio_url;
          }

          return (
            <HStack key={index} sx={{ flexWrap: "nowrap", width: "100%" }} pl={6}>
              {audio && (
                <Typography component="span">
                  <WordAudio url={audio} small />
                </Typography>
              )}
              <HoverDetails
                // isCenter
                words={
                  line.split(" ").map((w: any, index: number) => {
                    if (w.trim().startsWith("_time")) {
                      const [, start, end] = w.trim().split(".");
                      return {
                        type: "children",
                        children: (
                          <TextPartVideoByStartAndEndButton
                            story={story}
                            start={timeToSeconds(start)}
                            end={timeToSeconds(end)}
                            endless
                          />
                        ),
                      };
                    }
                    const cleanWord = removePunctuationFromString(w).replace("__", " ");

                    const word =
                      curerntModuleWordsMap[cleanWord] ||
                      curerntModuleWordsMap[cleanWord.toLowerCase()];
                    // console.log("🚀 ~ cleanWord:", w, cleanWord, word);

                    return {
                      ...(word ? word : {}),
                      word: word?.name || cleanWord,
                      label: w.replace("__", " "),
                    };
                  }) as any
                }
              />
            </HStack>
          );
        })}
        {!withoutLink && (
          <Box mb={10}>
            {!story.is_external && (
              <Link href={story.link} target="_blank">
                {story.link.startsWith("https://www.eslfast.com")
                  ? "прослухати кращий запис"
                  : "повне відео"}
              </Link>
            )}
            {story.is_external && (
              <Link href={story.link} target="_blank">
                подивитися відео
              </Link>
            )}
          </Box>
        )}
      </Box>
    </VStack>
  );
};

const Stories: React.FC<{
  stories: EnglishStory[];
  buttonSize?: "small" | "medium" | "large";
  buttonLabel?: string;
  isReadingVideo?: boolean;
  withoutLink?: boolean;
  withoutDrawer?: boolean;
}> = ({ stories, withoutLink, isReadingVideo, buttonSize, buttonLabel, withoutDrawer }) => {
  // const [isOpen, setIsOpen] = useState(false);
  const lessonModalOpen = useLessonsStore((state) => state.lessonModalOpen === "videos");
  const setLessonModalOpen = useLessonsStore((state) => state.setLessonModalOpen);
  const activeVideoId = useLessonsStore((state) => state.activeVideoId);
  const { lessonId, lessonType } = useParams();
  const lessonData = useLessonsStore((state) => state.lessonData);

  const handleDrawerClose = useCallback(() => {
    // setIsOpen(false)
    setLessonModalOpen("");
    // if (onDrawerClose) onDrawerClose();
  }, []);

  const content = useMemo(() => {
    if (withoutDrawer) {
      return (
        <DrawerContent
          withoutDrawer={withoutDrawer}
          withoutLink={withoutLink}
          stories={stories}
          onClose={handleDrawerClose}
        />
      );
    }

    return (
      <Drawer
        open={lessonModalOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 999,
          // opacity: 0,
          // backgroundColor: "rgba(0, 0, 0, 1) !important",
        }}
      >
        <DrawerContent
          withoutDrawer={withoutDrawer}
          withoutLink={withoutLink}
          stories={stories}
          onClose={handleDrawerClose}
        />
      </Drawer>
    );
  }, [withoutDrawer, withoutLink, lessonModalOpen, stories, handleDrawerClose, activeVideoId]);

  return (
    <>
      {!withoutDrawer && (
        <Button
          variant="outlined"
          size={buttonSize || "medium"}
          onClick={() => setLessonModalOpen("videos")}
        >
          {(lessonId && lessonData?.[lessonId]?.module.is_video) || isReadingVideo
            ? buttonLabel || "Відео"
            : buttonLabel || "Історія"}
        </Button>
      )}
      {content}
      {/* <Drawer
        open={lessonModalOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 999,
          // opacity: 0,
          // backgroundColor: "rgba(0, 0, 0, 1) !important",
        }}
      >
        <DrawerContent withoutLink={withoutLink} stories={stories} onClose={handleDrawerClose} />
      </Drawer> */}
    </>
  );
};

const drawerContentStyle = {
  height: "100vh",
  width: "100vw",
  overflowX: "hidden",
  // backgroundColor: "rgba(0, 0, 0, 0) !important",
};

const DrawerContent: React.FC<{
  stories: EnglishStory[];
  withoutLink?: boolean;
  withoutDrawer?: boolean;
  onClose: () => void;
}> = ({ stories, withoutLink, withoutDrawer, onClose }) => {
  const videosToRepeat = useLessonsStore((state) => state.homeWork.videosToRepeat);
  const [isHomeWork] = stories.map((s) => videosToRepeat.includes(s.id)).filter(Boolean);

  const drawer = useMemo(() => {
    return (
      <Box sx={withoutDrawer ? {} : drawerContentStyle} pt={isHomeWork ? 5 : 0}>
        {isHomeWork && (
          <Box pt={14}>
            <ShowCurrentHomeWorkNumbersInDrawer type="videos" />
          </Box>
        )}
        {!withoutDrawer && <ModalCloseButton onClose={onClose} />}
        <VStack py={10} gap={10}>
          {stories.map((story, index) => (
            <Box mb={3} key={story.id} maxWidth={600} width="100%">
              <Story key={story.id} withoutLink={withoutLink} story={story} />
            </Box>
          ))}
        </VStack>
      </Box>
    );
  }, [stories, onClose]);

  return drawer;
};

export default Stories;

import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Box from "../../Box";
import filterRules from "./utils/filterRules";
import AccentWord from "../../../Difference/components/AccentWord";
import { RuleType } from "../../Root/grammarMap";
import { useLessonsStore } from "../../../../store/lessonsStore";
import AccordionRule from "./AccordionRule";
import HStack from "../../HStack";
import H3Title from "../../H3Title";
import WordTranscription from "../../../Word/WordTranscription";
import { useEffect, useMemo, useState } from "react";
import useGlobalWords from "../../../hooks/getGlobalWords";
import { Word } from "../../../Admin/SPTranslationItem";
import WordAudio from "../../../Audio/WordAudio";

type CreateDataProp = {
  us: any;
  uk: any;
  lesson: number;
  ua: string;
  write?: string;
  pronounce?: string;
};

function createData(item1: CreateDataProp, item2?: CreateDataProp, item3?: CreateDataProp) {
  return {
    item1,
    item2,
    item3,
  };
}

const rows = (lessonNumber: number) => [
  createData({
    us: "gray",
    uk: "grey",
    lesson: 19,
    ua: "сірий",
    write: "us: gray uk: grey",
  }),
  createData({
    us: "meter",
    uk: "metre",
    lesson: 19,
    ua: "лічильник, метр",
    write: "us: meter uk: metre",
    pronounce: "us: |ˈmiːtər| uk: |ˈmiːtə|",
  }),
  createData({
    us: "meters",
    uk: "metres",
    lesson: 19,
    ua: "лічильники, метри",
    write: "us: meters uk: metres",
    pronounce: "us: |ˈmiːtərz| uk: |ˈmiːtəz|",
  }),
  createData({
    us: "kilometer",
    uk: "kilometre",
    lesson: 19,
    ua: "кілометр",
    write: "us: kilometer uk: kilometre",
    pronounce: "us: |kəˈlɑːmətər| uk: |kɪˈlɒmɪtə|",
  }),
  createData({
    us: "kilometers",
    uk: "kilometres",
    lesson: 19,
    ua: "кілометри",
    write: "us: kilometers uk: kilometres",
    pronounce: "us: |kəˈlɑːmətərz| uk: |kɪˈlɒmɪtəz|",
  }),
  createData({
    us: "z",
    uk: "z",
    lesson: 19,
    ua: "[літера алфавіту]",
    pronounce: "us: |ziː| uk: |zed|",
  }),
  // createData(
  //   {
  //     us: "tall",
  //     uk: "short",
  //     lesson: 11,
  //     ua: "високий / низький",
  //   },
  //   {
  //     us: "yes",
  //     uk: "no",
  //     lesson: 11,
  //     ua: "так / ні",
  //   },
  //   {
  //     us: "do",
  //     uk: "doesn't",
  //     lesson: 11,
  //     ua: "робити / не робити",
  //   },
  // ),
];

const WriteWordsDifference = () => {
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);

  return (
    <Box>
      <H3Title>Різний правопис та вимова слів</H3Title>
      <Typography gutterBottom>
        Слова, які по-різному пишуться в різних варіантах англійської. Таких як биританська,
        американська, канадська...
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
        }}
      >
        <Table sx={{ minWidth: 600, width: 600 }} aria-label="simple table">
          <TableBody>
            {rows(lessonNumber)
              .filter((r) => r.item1.lesson <= lessonNumber)
              .map((row, index) => (
                <TableRow key={index}>
                  <CustomTableCell
                    width={200}
                    {...row.item1}
                    us={row.item1.us}
                    uk={row.item1.uk}
                    lesson={row.item1?.lesson || 0}
                    ua={row.item1?.ua}
                  />
                  {row.item2 && (
                    <CustomTableCell
                      width={200}
                      {...row.item2}
                      us={row.item2?.us}
                      uk={row.item2?.uk}
                      lesson={row.item2?.lesson || 0}
                      ua={row.item2?.ua}
                    ></CustomTableCell>
                  )}
                  {row.item3 && (
                    <CustomTableCell
                      width={200}
                      {...row.item3}
                      us={row.item3?.us}
                      uk={row.item3?.uk}
                      lesson={row.item3?.lesson || 0}
                      ua={row.item3?.ua}
                    ></CustomTableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const CustomTableCell: React.FC<{
  ua?: string;
  us: string;
  uk: string;
  width: number;
  lesson: number;
  write?: string;
  pronounce?: string;
}> = ({ us, uk, lesson, width, ua, write, pronounce }) => {
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);
  const isReady = lesson <= lessonNumber;
  const [curerntModuleWordsMap, setCurrentModuleWordsMap] = useState<Record<string, Word>>({});
  const globalWords = useLessonsStore((state) => state.globalWords);

  const options = useMemo(() => {
    return { words: [us, uk] };
  }, [us, uk]);

  useGlobalWords(options);

  useEffect(() => {
    const map = globalWords
      .filter((w) => [us, uk].includes(w.name) || [us, uk].includes(w.name.toLowerCase()))
      .reduce((acm, w) => {
        return {
          ...acm,
          [w.name]: w,
          [w.name.toLowerCase()]: w,
        };
      }, {});

    setCurrentModuleWordsMap(map);
  }, [globalWords, us, uk]);

  return (
    <TableCell
      component="th"
      scope="row"
      sx={{
        m: 0,
        width,
        pointerEvents: isReady ? "auto" : "none",
        backgroundColor:
          lesson === lessonNumber ? "#ddffc5" : lesson <= lessonNumber ? "#fcffdb" : "#d0d0d0",
        height: "auto",
        cursor: "pointer",
        minHeight: 0,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderLeft: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
        py: 1,
      }}
    >
      <Typography variant="body1" fontWeight={500}>
        <HStack>
          {curerntModuleWordsMap[us] && (
            <WordAudio small url={curerntModuleWordsMap[us].audio_url} />
          )}
          {us !== uk ? `${us} / ${uk}` : us}
        </HStack>
      </Typography>
      <Typography variant="caption">{ua}</Typography>
      {/* {us === uk && curerntModuleWordsMap[us] && (
        <WordAudio url={curerntModuleWordsMap[us].audio_url} />
        
      )} */}

      {pronounce && (
        <Box>Різниця в вимові: {pronounce.replace("uk:", "🇬🇧")?.replace("us:", "🇺🇸")}</Box>
      )}
      {!pronounce && <Box>Вимовляється одинаково</Box>}
      {write && <Box>Різниця у написанні: {write.replace("uk:", "🇬🇧")?.replace("us:", "🇺🇸")}</Box>}
      {/* {us === uk && pronounce && curerntModuleWordsMap[us] && (
        // <WordTranscription play autoplay={false} small word={curerntModuleWordsMap[us]} />
        <>
          <Typography>🇺🇸 вимова</Typography>
          <WordAudio small url={curerntModuleWordsMap[us].audio_url} />
        </>
      )} */}

      {/* {us !== uk && curerntModuleWordsMap[uk] && (
        <WordAudio url={curerntModuleWordsMap[uk].audio_url} />
      )} */}
    </TableCell>
  );
};

export default WriteWordsDifference;

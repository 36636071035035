import { Box, Typography } from "@mui/material";
import { memo, useMemo } from "react";
import firstLetterUppercase from "../../../common/firstLetterUppercase";
import VStack from "../../Unknown/VStack";
import getSentenceWordMatch from "./getSentenceWordMatch";
import HStack from "../../Unknown/HStack";
import AdminTranslateWithWoordhunter from "../../Unknown/AdminTranslateWithWoordhunter";
import AdminTranslateWithGoogle from "../../Unknown/AdminTranslateWithGoogle";

interface WordExampleSentenceProps {
  ua: string;
  en: string;
  highlight?: string;
}

const WordExampleSentence = ({ en: inputEn, ua: inputUa, highlight }: WordExampleSentenceProps) => {
  const enSentence = useMemo(() => {
    if (highlight) return getSentenceWordMatch(inputEn, highlight);

    return firstLetterUppercase(inputEn);
  }, [highlight, inputEn]);

  return (
    <Box display="flex" flexDirection="column">
      <VStack gap={0} position="relative" mb={3} alignItems={"flex-start"}>
        <Typography color="secondary.dark" fontWeight={500}>
          <HStack>
            <AdminTranslateWithWoordhunter title={inputEn} />
            <AdminTranslateWithGoogle title={inputEn} />
            {enSentence}
          </HStack>
        </Typography>
        <Typography color="#919096" fontWeight={500}>
          <HStack>
            <AdminTranslateWithWoordhunter title={inputUa} />
            <AdminTranslateWithGoogle fromUa title={inputUa} />
            {inputUa}
          </HStack>
        </Typography>
      </VStack>
    </Box>
  );
};

export default memo(WordExampleSentence);

import isAdmin from "../../../common/isAdmin";
import { useLessonsStore } from "../../../store/lessonsStore";
import { ExtendedUserSentence, Sentence } from "../../../types/supabase";
import EditIcon from "@mui/icons-material/Edit";
import Box from "../../Unknown/Box";
import IconButton from "../../Unknown/IconButton";
import { useEffect, useState } from "react";
import { Button, Modal, TextField, Typography } from "@mui/material";

import supabaseClient from "../../../common/supabaseClient";
import VStack from "../../Unknown/VStack";
import useCreateAudio from "../../../common/useCreateAudio";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AdminEditSentenceModal: React.FC<{ sentence: ExtendedUserSentence }> = ({ sentence }) => {
  const authUser = useLessonsStore((state) => state.authUser);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [ua, setUa] = useState(sentence.sentences.ua);
  const [en, setEn] = useState(sentence.en);

  const { createAudio } = useCreateAudio();

  useEffect(() => {
    setUa(sentence.sentences.ua);
    setEn(sentence.en);
  }, [sentence]);

  if (!isAdmin(authUser?.id)) return null;

  return (
    <Box>
      <IconButton
        onClick={() => {
          setOpen(true);
        }}
      >
        <EditIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <VStack>
            <TextField
              color="primary"
              fullWidth
              value={ua}
              onChange={(event) => {
                const newText = event.target.value;

                setUa(newText);
              }}
              type="text"
              variant="outlined"
            />
            <Button
              variant="contained"
              onClick={async () => {
                if (sentence.en !== en) {
                  const audio = await createAudio(en);

                  const { data: userSentences } = await supabaseClient
                    .from("user-sentences")
                    .select()
                    .eq("en", sentence.en);

                  if (userSentences?.length) {
                    await Promise.all(
                      userSentences.map((s) =>
                        supabaseClient.from("user-sentences").delete().eq("id", s.id),
                      ),
                    );
                  }
                  console.log("🚀 ~ userSentences:", userSentences);
                  const result = await supabaseClient
                    .from("sentences")
                    .update({
                      ua,
                      en,
                      en_audio_url: audio,
                    })
                    .eq("id", sentence.sentences.id);
                  console.log("🚀 ~ result:", result);
                } else {
                  const result = await supabaseClient
                    .from("sentences")
                    .update({
                      ua,
                      // en,
                    })
                    .eq("id", sentence.sentences.id);
                  console.log("🚀 ~ result:", result);
                }

                handleClose();
              }}
            >
              Update
            </Button>
            <TextField
              color="primary"
              fullWidth
              value={en}
              onChange={(event) => {
                const newText = event.target.value;

                setEn(newText);
              }}
              type="text"
              variant="outlined"
            />
          </VStack>
        </Box>
      </Modal>
    </Box>
  );
};

export default AdminEditSentenceModal;
